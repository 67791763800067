import i18n from "@/core/plugins/i18n";
import commonVue from "@circuitid/common-vue";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import c from "@circuitid/common";

// required for feathers
import { feathersClient, pinia } from "./store/feathers";

//imports for app initialization
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import plugins from "@/core/plugins/plugins.js";

import "@/core/plugins/prismjs";
import "bootstrap";

// config
import {
  // apiUrl,
  dev,
  awsRumConfig,
  syncfusionLicense,
} from "@/core/helpers/config";
import {
  startPresence,
  isElectronMinimalRoute,
  setTheme,
  setCommon,
} from "@/core/helpers/functions.js";

import { AwsRum, AwsRumConfig } from "aws-rum-web";

// sync fusion
import { registerLicense } from "@syncfusion/ej2-base";

/* import the fontawesome core */
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fas, far, fab);
dom.watch();

if (
  !dev &&
  !c.arrayEmpty(awsRumConfig) &&
  c.isPositiveNum(awsRumConfig.sessionSampleRate) &&
  !c.dependencyMissing(
    (awsRumConfig.guestRoleArn = c.trimString(awsRumConfig.guestRoleArn))
  ) &&
  !c.dependencyMissing(
    (awsRumConfig.identityPoolId = c.trimString(awsRumConfig.identityPoolId))
  ) &&
  !c.dependencyMissing(
    (awsRumConfig.endpoint = c.trimString(awsRumConfig.endpoint))
  ) &&
  !c.arrayEmpty(awsRumConfig.telemetries) &&
  !c.dependencyMissing(
    (awsRumConfig.appId = c.trimString(awsRumConfig.appId))
  ) &&
  !c.dependencyMissing(
    (awsRumConfig.appVersion = c.trimString(awsRumConfig.appVersion))
  ) &&
  !c.dependencyMissing(
    (awsRumConfig.appRegion = c.toLower(awsRumConfig.appRegion))
  )
)
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: awsRumConfig.sessionSampleRate,
      guestRoleArn: awsRumConfig.guestRoleArn,
      identityPoolId: awsRumConfig.identityPoolId,
      endpoint: awsRumConfig.endpoint,
      telemetries: awsRumConfig.telemetries,
      allowCookies: awsRumConfig.allowCookies ? true : false,
      enableXRay: awsRumConfig.enableXRay ? true : false,
    };

    new AwsRum(
      awsRumConfig.appId,
      awsRumConfig.appVersion,
      awsRumConfig.appRegion,
      config
    );
  } catch {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }

registerLicense(syncfusionLicense);

const app = createApp(App);

app.use(i18n);
app.use(commonVue, { router: router, store: store });
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(plugins, { router: router, store: store });

// globals
app.config.globalProperties.$imagesUrl = "/media/";

// components
app.component("font-awesome-icon", FontAwesomeIcon);

// common
setCommon(app.config.globalProperties.$common);

window.addEventListener("blur", () => {
  store.dispatch("setStateValue", {
    key: "isFocused",
    value: false,
  });
});
window.addEventListener("focus", () => {
  store.dispatch("setStateValue", {
    key: "isFocused",
    value: true,
  });
});

setTheme();

let appMounted = false;

const minimalRoute = isElectronMinimalRoute();

const getUrlParamters = () => {
  return c.parseQueryString(window.location.search);
};

const requireTokenResponse = () => {
  const urlParams = getUrlParamters();

  return !c.arrayEmpty(urlParams) &&
    !c.dependencyMissing(urlParams.response_type) &&
    !c.dependencyMissing(urlParams.nonce) &&
    !c.dependencyMissing(urlParams.client_id) &&
    !c.dependencyMissing(urlParams.redirect_uri)
    ? urlParams
    : {};
};

const redirectBase = () => {
  const urlParams = requireTokenResponse();

  const noRedirectionPages = ["join", "sign-up", "password-reset"];

  for (let i = 0; i < noRedirectionPages.length; i++)
    if (window.location.href.includes(c.toLower(noRedirectionPages[i]))) {
      if (!appMounted) app.mount("#app");
      return;
    }

  if (
    !window.location.href.includes("sign-in") &&
    c.dependencyMissing(urlParams.redirect_uri)
  )
    urlParams.redirect_uri = window.location.href;

  router.push({
    name: "sign-in",
    query: urlParams,
    hash: window.location.hash || undefined,
  });
  if (!appMounted) app.mount("#app");
};

const setAuth = (user?: any, auth?: any) => {
  app.mount("#app");
  appMounted = true;

  let info = localStorage.getItem("info");
  let tenant = localStorage.getItem("tenant");

  if (!minimalRoute && (!user || !tenant)) return redirectBase();

  try {
    // eslint-disable-next-line
    // @ts-ignore
    tenant = JSON.parse(tenant);

    if (info) info = JSON.parse(info);
  } catch {
    return redirectBase();
  }

  if (!tenant) return redirectBase();

  store.dispatch("setObject", {
    key: "profile",
    object: !minimalRoute ? auth : tenant,
  });

  store.dispatch("setObject", {
    key: "tenant",
    object: tenant,
  });

  if (info)
    store.dispatch("setObject", {
      key: "info",
      object: info,
    });

  if (!minimalRoute)
    try {
      store.dispatch("setMenuConfig", {
        permissions: user["permissions"],
      });
    } catch {
      return redirectBase();
    }
};

store.dispatch("setValue", { key: "winLocHash", value: window.location.hash });
store.dispatch("setValue", {
  key: "winLocSearch",
  value: window.location.search,
});

if (!minimalRoute) {
  app.use(pinia);

  // make feathersjs available on vue
  app.provide("feathersClient", feathersClient);

  store.dispatch("setGlobal", {
    key: "feathersClient",
    value: feathersClient,
  });

  store.dispatch("setSiteInfo").then(() => {
    initInlineSvg(app);
    initVeeValidate();

    if (!c.arrayEmpty(requireTokenResponse())) redirectBase();
    else
      try {
        feathersClient
          .reAuthenticate()
          .then((auth) => {
            const { user } = auth;

            setAuth(user, auth);

            startPresence();

            store.dispatch("processPostlogin");
          })
          .catch(() => {
            redirectBase();
          });
      } catch (e: unknown) {
        if (typeof e === "string") throw new Error(e.toUpperCase());
        else if (e instanceof Error) throw new Error(e.message);
      }
  });
} else {
  setAuth();
}

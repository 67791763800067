import c from "@circuitid/common";
import store from "@/store";
import { getListItemDate } from "@/core/helpers/functions.js";

export const addUsersDrawerId = "patchChatRoomAddUsers";
export const roomInfoDrawerId = "RoomUsersSidebar";
export const userInvitesDrawerId = "userInvitesDrawerId";

export const leaveRoom = (_id, roomId, router, revokeInvite = false) => {
  const service = "chatrooms";
  const room = {
    ...store.getters.getObjectById({ _id: roomId, service: service }),
  };

  if (c.arrayEmpty(room) || c.arrayEmpty(room.users)) return;

  room.users = room.users.filter((a) => a._id != _id);

  if (c.trimString(_id) !== c.trimString(store.getters.userId))
    store.dispatch("patchArrayObject", {
      key: service,
      value: room,
    });
  else {
    room.show = false;

    store.dispatch("removeArrayObject", {
      service: service,
      _id: roomId,
    });

    loadFirstRoom(router);
  }

  store
    .dispatch("patchData", {
      service: service,
      data: {
        users: {
          [_id]: {
            status: !revokeInvite ? "left" : "revokeInvite",
          },
        },
      },
      id: roomId,
    })
    .catch((e) => {
      console.error(e);
    });
};

export const createRoom = (name, users, router, direct = false) => {
  if (c.dependencyMissing(name) || c.arrayEmpty(users)) return;

  const service = "chatrooms";

  store
    .dispatch("postObject", {
      service: service,
      object: {
        name: name,
        users: users,
        direct: direct,
      },
    })
    .then((r) => {
      if (c.arrayEmpty(r) || !c.isValidObjectId(r._id)) return;

      router.push({ path: "/chat/" + r._id });
    })
    .catch((e) => {
      console.error(e);
    });
};

export const addUser = ({ ...user }, roomId) => {
  const service = "chatrooms";
  const room = {
    ...store.getters.getObjectById({ _id: roomId, service: service }),
  };

  if (c.arrayEmpty(user) || c.arrayEmpty((user = formatUser(user)))) return;

  if (c.arrayEmpty(room) || c.arrayEmpty(room.users)) return;

  user.status = "joined";

  room.users.push(user);

  store.dispatch("patchArrayObject", {
    key: service,
    value: room,
  });

  store
    .dispatch("patchData", {
      service: service,
      data: {
        users: {
          [user._id]: {
            status: "joined",
          },
        },
      },
      id: roomId,
    })
    .catch((e) => {
      console.error(e);
    });
};

export const patchInvite = (_id, accept = true) => {
  return new Promise((resolve, reject) => {
    const service = "chatrooms";
    const room = {
      ...store.getters.getObjectById({ _id: _id, service: service }),
    };

    const i = c.inArray("_id", store.getters.userId, room.users);

    if (typeof i !== "number") reject("User not found");

    const status = accept ? "joined" : "rejectinvite";

    room.users[i].status = status;

    store.dispatch("closeDrawer", { key: userInvitesDrawerId });

    store.dispatch("patchArrayObject", {
      key: service,
      value: room,
    });

    store
      .dispatch("postObject", {
        service: "functions",
        object: {
          type: "invite",
          chatroom: _id,
          accept: accept,
        },
        id: _id,
      })
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const loadFirstRoom = (router) => {
  if (!c.arrayEmpty(store.getters.chatroomData)) {
    const id = router.currentRoute.value.params.id;
    const index = c.inArray("_id", id, store.getters.chatroomData);

    if (
      c.isValidObjectId(id) &&
      !c.arrayEmpty(store.getters.chatroomData[index]) &&
      store.getters.chatroomData[index].chatroomService === "chatrooms"
    )
      return;

    for (const i in store.getters.chatroomData)
      if (
        !c.arrayEmpty(store.getters.chatroomData[i]) &&
        c.isValidObjectId(store.getters.chatroomData[i]._id) &&
        c.toLower(
          store.getters.chatroomData[i].chatroomService === "chatrooms",
        ) &&
        !c.arrayEmpty(store.getters.chatroomData[i].users) &&
        userJoinedRoom(
          store.getters.userId,
          "_id",
          store.getters.chatroomData[i].users,
        )
      ) {
        router.push({ path: "/chat/" + store.getters.chatroomData[i]._id });
        return;
      }
  }

  router.push({ path: "/chat/welcome" });
};

export const formatUser = (d) => {
  if (c.arrayEmpty(d)) return {};

  const user = {
    _id: d._id,
    email: d.email,
    name: d.first + " " + d.last,
    avatar: d.avatar,
    customerId: d.customerId,
    status: d.status,
    extension: d.extension,
    isExternalUser: isExternalUser(d.customerId),
  };

  user.username = user.name;

  if (!c.arrayEmpty(d.invitedBy)) user.invitedBy = d.invitedBy;

  return user;
};

export const roomFormatter = (d) => {
  if (c.arrayEmpty(d)) return;

  const updatedAt =
    !c.arrayEmpty(d.lastChatMessage) &&
    !c.dependencyMissing(d.lastChatMessage.createdAt)
      ? d.lastChatMessage.createdAt
      : d.updatedAt;

  let unread =
    !c.arrayEmpty(d.unread) &&
    !c.arrayEmpty(d.unread[store.getters.userId]) &&
    c.isPositiveNum(d.unread[store.getters.userId].count)
      ? c.getInt(d.unread[store.getters.userId].count)
      : 0;

  const existingRoom = store.getters.getObjectById({
    _id: d._id,
    service: "chatrooms",
  });

  if (!c.arrayEmpty(existingRoom)) {
    unread = existingRoom.unread || 0;

    if (
      (c.arrayEmpty(store.getters.currentChatRoom) ||
        c.trimString(store.getters.currentChatRoom._id) !==
          c.trimString(d._id)) &&
      !c.arrayEmpty(d.lastChatMessage) &&
      c.isValidObjectId(d.lastChatMessage._id) &&
      !c.inArray("_id", d._id, store.getters.chatmessageData)
    )
      unread++;
  }

  const unreadHtml = c.isPositiveNum(unread)
    ? '<span class="badge badge-sm badge-circle badge-danger me-2">' +
      (c.getInt(unread) <= 99 ? c.getInt(unread) : "99+") +
      "</span>"
    : "";

  const room = {
    _id: d._id,
    roomId: d._id,
    name: d.name,
    link: "/chat/" + d._id,
    chatroomService: "chatrooms",
    customerId: d.customerId,
    unread: unread,
    createdByUserId: d.createdByUserId,
    users: [],
    otherUsers: [],
    lastChatMessage: {},
    show: false,
    direct: d.direct,
    updatedAt: updatedAt,
    subTitle:
      !c.arrayEmpty(d.lastChatMessage) &&
      !c.dependencyMissing(d.lastChatMessage.content)
        ? c.getMaxText(
            (!c.arrayEmpty(d.lastChatMessage.createdByUserId) &&
            !c.dependencyMissing(d.lastChatMessage.createdByUserId.first)
              ? (d.lastChatMessage.createdByUserId._id !==
                store.getters.currentUser._id
                  ? d.lastChatMessage.createdByUserId.first
                  : "You") + ": "
              : "") + d.lastChatMessage.content,
            20,
          )
        : "",
    rightContent: unreadHtml + getListItemDate(updatedAt),
  };

  room.roomName = room.name;

  if (!c.arrayEmpty(d.lastChatMessage))
    room.lastChatMessage = d.lastChatMessage;

  if (!c.arrayEmpty(d.typingUsers)) room.typingUsers = d.typingUsers;

  if (!c.dependencyMissing(d.avatar)) room.avatar = d.avatar;

  if (!c.arrayEmpty(d.users))
    for (let i = 0; i < d.users.length; i++)
      if (
        shouldShowUserByStatus(d.users[i].status) &&
        !c.inArray("_id", d.users[i]._id, room.users)
      ) {
        const user = formatUser(d.users[i]);

        if (
          c.trimString(d.users[i]._id) !== c.trimString(store.getters.userId)
        ) {
          room.otherUsers.push(user);
          room.users.unshift(user);
        } else {
          room.users.push(user);
          room.show = true;
        }
      }

  room.items = !c.arrayEmpty(room.otherUsers) ? room.otherUsers : room.users;

  return room;
};

export const shouldShowUserByStatus = (s) => {
  return !c.dependencyMissing(s) && ["joined", "invited"].includes(c.toLower(s))
    ? true
    : false;
};

export const userActiveInRoom = (value, field, users) => {
  if (
    c.dependencyMissing(value) ||
    !["_id", "email"].includes(field) ||
    c.arrayEmpty(users)
  )
    return false;

  const i = c.inArray(field, value, users);

  if (
    i !== false &&
    !c.arrayEmpty(users[i]) &&
    shouldShowUserByStatus(users[i].status)
  )
    return true;

  return false;
};

export const userJoinedRoom = (value, field, users) => {
  if (
    c.dependencyMissing(value) ||
    !["_id", "email"].includes(field) ||
    c.arrayEmpty(users)
  )
    return false;

  const i = c.inArray(field, value, users);

  if (
    i !== false &&
    !c.arrayEmpty(users[i]) &&
    c.trimString(users[i].status) === "joined"
  )
    return true;

  return false;
};

export const messageFormatter = (d) => {
  if (
    c.arrayEmpty(d) ||
    !c.isValidObjectId(d._id) ||
    c.arrayEmpty(d.createdByUserId)
  )
    return;

  d.senderId = d.createdByUserId._id;
  d.username = d.createdByUserId.first + " " + d.createdByUserId.last;
  d.date = c.moment()(d.createdAt).format("dddd, MMMM Do YYYY");
  d.timestamp = c.moment()(d.createdAt).format("LT");
  d.seen = !c.arrayEmpty(d.seen) ? true : false;
  d.distributed = d.saved ? true : false;
  d.files = [];
  d.reactions = !c.arrayEmpty(d.reactions) ? d.reactions : {};
  d.content = !c.dependencyMissing(d.content) ? d.content : "";

  if (!c.dependencyMissing(d.createdByUserId.avatar))
    d.avatarUrl = d.createdByUserId.avatar;

  if (!c.arrayEmpty(d.replyMessage))
    d.replyMessage = messageFormatter(d.replyMessage);

  if (!c.arrayEmpty(d.attachments))
    for (let i = 0; i < d.attachments.length; i++)
      d.files.push({
        name: d.attachments[i].name,
        size: d.attachments[i].size,
        type: d.attachments[i].contentType,
        url: d.attachments[i].url,
        preview: d.attachments[i].buffer || undefined,
      });

  return d;
};

export const onChatRoomPatched = (e, router) => {
  if (
    c.arrayEmpty(e) ||
    (!c.arrayEmpty(e.users) &&
      userActiveInRoom(store.getters.userId, "_id", e.users))
  )
    return;

  loadFirstRoom(router);
};

export const isExternalUser = (customerId) => {
  return c.trimString(customerId) !== c.trimString(store.getters.customerId)
    ? true
    : false;
};

export const invitedBy = (_id) => {
  const room = {
    ...store.getters.getObjectById({ _id: _id, service: "chatrooms" }),
  };

  if (c.arrayEmpty(room) || c.arrayEmpty(room.users)) return "";

  const i = c.inArray("_id", store.getters.userId, room.users);

  if (
    i === false ||
    c.arrayEmpty(room.users[i]) ||
    c.arrayEmpty(room.users[i].invitedBy) ||
    c.dependencyMissing(room.users[i].invitedBy.displayName)
  )
    return "";

  return (
    room.users[i].invitedBy.displayName +
    " " +
    c.filters.date({ date: room.users[i].invitedBy.createdAt, type: "fromnow" })
  );
};

export const getUserNumber = (params) => {
  if (
    c.arrayEmpty(params) ||
    c.dependencyMissing(params.service) ||
    c.arrayEmpty(params.user)
  )
    return;

  return params.service === "chatrooms" || params.user.isUser
    ? !params.user.isExternalUser && !c.dependencyMissing(params.user.extension)
      ? params.user.extension
      : params.user.businessPhone
    : params.user.mobilePhone;
};

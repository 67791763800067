<template>
  <template v-if="$store.getters.isElectron">
    <AppTitleBar v-if="!minimalRoute"></AppTitleBar>
    <CallManagerTitleBar v-else></CallManagerTitleBar>
  </template>
  <router-view />
  <KTToolbarButtons v-if="!minimalRoute" />
</template>

<script>
import { defineComponent, nextTick, onMounted, inject } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { plugins, enabledPlugins } from "@/core/helpers/config";
import KTToolbarButtons from "@/layout/extras/ToolbarButtons.vue";
import {
  isElectronMinimalRoute,
  setRoute,
  setRouter,
  setSounds,
  setTranslate,
} from "@/core/helpers/functions.js";
import AppTitleBar from "@/components/common/AppTitleBar.vue";
import CallManagerTitleBar from "@/components/common/CallManagerTitleBar.vue";

export default defineComponent({
  name: "app",
  components: { KTToolbarButtons, AppTitleBar, CallManagerTitleBar },
  setup() {
    const c = inject("common");
    const store = useStore();
    const minimalRoute = isElectronMinimalRoute();
    const route = useRoute();
    const router = useRouter();

    c.useI18n();

    setRoute(route);
    setRouter(router);
    setTranslate(c.translate);

    const monitorWindowSize = () => {
      const reportWindowSize = () => {
        store.dispatch("setStateValue", {
          key: "height",
          value: window.innerHeight,
        });
        store.dispatch("setStateValue", {
          key: "width",
          value: window.innerWidth,
        });
      };

      reportWindowSize();

      window.onresize = reportWindowSize;
    };

    if (minimalRoute) {
      onMounted(() => {
        monitorWindowSize();

        if (
          store.getters.isElectron &&
          c.isFunction(window.electronAPI.onVuexSync)
        )
          try {
            window.electronAPI.onVuexSync((event, args) => {
              if (
                c.arrayEmpty(args) ||
                c.arrayEmpty(args.mutation) ||
                c.arrayEmpty(args.mutation.payload) ||
                c.dependencyMissing(args.mutation.type)
              )
                return;

              store.commit(args.mutation.type, args.mutation.payload);
            });
          } catch (e) {
            console.error(e);
          }
      });

      return { minimalRoute };
    } else {
      setSounds();
    }

    if (!c.arrayEmpty(plugins))
      for (const i in plugins)
        if (
          plugins[i].enabled === true &&
          c.toLower(plugins[i].lifecycle) === "startup"
        )
          enabledPlugins[plugins[i].name] = inject(plugins[i].name);

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        monitorWindowSize();

        initializeComponents();

        if (!c.arrayEmpty(plugins))
          for (const i in plugins)
            if (plugins[i].enabled) {
              if (
                c.arrayEmpty(enabledPlugins[plugins[i].name]) ||
                !c.isFunction(enabledPlugins[plugins[i].name].initialize)
              )
                continue;

              const config = {};

              if (
                c.isFunction(enabledPlugins[plugins[i].name].getConfigByUser) &&
                !c.arrayEmpty(store.getters.userProfile)
              )
                config.user = enabledPlugins[plugins[i].name].getConfigByUser(
                  c.mergeObjects(store.getters.userProfile, {
                    customer: store.getters.customerProfile,
                  })
                );

              setTimeout(() => {
                enabledPlugins[plugins[i].name].initialize(config);
              }, 1000);
            }

        /* Used for push notifications and any background tasks

																																   // used to delete registration, not necessary needed on every load
																																   if ('serviceWorker' in navigator)
																																	 navigator.serviceWorker.getRegistrations().then(function (registrations) {
																																	   for (const registration of registrations) {
																																		 registration.unregister()
																																	   }
																																	 })

																																   if ('serviceWorker' in navigator)
																																	 navigator.serviceWorker.register('/serviceWorker.js', {
																																	   scope: "/"
																																	 }).then(async (reg) => {

																																	   store.dispatch("setStateValue", { key: "serviceWorker", value: reg });

																																	 }).catch((e) => { console.error("service registration error", e); });

																															  */
      });

      if (store.getters.isElectron) {
        window.electronAPI.onExecFunction(async (e, args) => {
          if (
            c.arrayEmpty(args) ||
            c.dependencyMissing(args.function) ||
            c.dependencyMissing(args.winId) ||
            c.dependencyMissing(args.class)
          )
            return;

          let response;

          switch (args.class) {
            case "phone":
              if (!args.args) {
                if (!args.await)
                  response = store.getters.phone.client[args.function](args.id);
                else
                  response = await store.getters.phone.client[args.function](
                    args.id
                  );
              } else {
                if (!args.await)
                  response = store.getters.phone.client[args.function](
                    args.id,
                    args.args
                  );
                else
                  response = await store.getters.phone.client[args.function](
                    args.id,
                    args.args
                  );
              }
              break;
          }

          if (args.returnResponse && !c.dependencyMissing(args.referenceId))
            window.electronAPI.execFunctionReply({
              winId: args.winId,
              referenceId: args.referenceId,
              response: response,
            });
        });

        window.electronAPI.mainLoaded();
      }
    });

    return {
      minimalRoute,
    };
  },
});
</script>

<style lang="scss">
@import "quill/dist/quill.snow.css";
@import "animate.css";
@import "sweetalert2/dist/sweetalert2.css";
@import "line-awesome/dist/line-awesome/css/line-awesome.css";
@import "prism-themes/themes/prism-shades-of-purple.css";
@import "element-plus/dist/index.css";

/* Main style scss  */
@import "assets/sass/style";

/* Base style css  */
@import "assets/css/base.css";

/* syncfusion ej2 css */
@import "@syncfusion/ej2-base/styles/material.css";

// Grid specific
@import "@syncfusion/ej2-buttons/styles/material.css";
@import "@syncfusion/ej2-calendars/styles/material.css";
@import "@syncfusion/ej2-dropdowns/styles/material.css";
@import "@syncfusion/ej2-inputs/styles/material.css";

// Grid specific
@import "@syncfusion/ej2-navigations/styles/material.css";
@import "@syncfusion/ej2-notifications/styles/material.css";
@import "@syncfusion/ej2-popups/styles/material.css";
@import "@syncfusion/ej2-splitbuttons/styles/material.css";
@import "@syncfusion/ej2-vue-grids/styles/material.css";
</style>

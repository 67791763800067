<template>
  <AppTitleBarBase :title="$store.getters.windowTitle"></AppTitleBarBase>
</template>

<script>
import { defineComponent } from "vue";
import AppTitleBarBase from "@/components/common/AppTitleBarBase.vue";

export default defineComponent({
  name: "CallManagerTitleBar",
  components: {
    AppTitleBarBase,
  },
});
</script>

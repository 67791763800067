<template>
  <div
    class="engage-toolbar d-flex position-fixed px-5 fw-bolder zindex-2 top-50 end-0 transform-90 mt-20 gap-2"
  >
    <template v-if="!$common.arrayEmpty($store.getters.info.site)">
      <button
        class="engage-help-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm px-5 rounded-top-0"
        title="App Marketplace"
        @click="openApps()"
        v-if="
          $store.getters.permitted(['appmarketplace:find']) &&
          $store.getters.info.site.isAppMarketplaceEnabled
        "
      >
        <font-awesome-icon icon="fa-rocket" />
      </button>
      <button
        class="engage-help-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm px-5 rounded-top-0"
        title="Favorites"
        @click="openRecent()"
        v-if="
          $store.getters.permitted(['recent:find']) &&
          $store.getters.info.site.isFavoritesEnabled
        "
      >
        <font-awesome-icon icon="fa-star" />
      </button>
    </template>
    <button
      class="engage-help-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm px-5 rounded-top-0"
      title="Get Support!"
      @click="openSupport()"
      v-if="$common.isFunction(openSupport)"
    >
      Help
    </button>
    <button
      id="impersonate_organization_toggle"
      class="engage-help-toggle btn btn-flex h-35px btn-danger shadow-sm px-5 rounded-top-0"
      title="Manage Organization Account"
      data-bs-toggle="tooltip"
      data-bs-placement="left"
      data-bs-dismiss="click"
      data-bs-trigger="hover"
      v-if="
        !$common.arrayEmpty($store.getters.tenant) &&
        !$common.arrayEmpty($store.getters.profile) &&
        !$common.arrayEmpty($store.getters.tenant.user) &&
        !$common.arrayEmpty($store.getters.profile.user) &&
        $store.getters.tenant.user.customerId !=
          $store.getters.profile.user.customerId
      "
    >
      <span class="svg-icon svg-icon-2">
        <font-awesome-icon
          icon="fa-regular fa-address-card"
          class="text-white"
        />
      </span>
      <span class="pulse-ring" style="margin-left: -8px"></span>
    </button>
  </div>
</template>

<script>
import { defineComponent, inject } from "vue";
import {
  recentDrawerId,
  addDeveloperAppSubscriptionDrawerId,
} from "@/core/helpers/config";
import {
  getDeveloperAppService,
  toggleSupportChat as openSupport,
} from "@/core/helpers/functions.js";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "QuickFloatingToolBar",
  components: {},
  setup() {
    const c = inject("common");
    c.useI18n();
    const t = c.translate;
    const store = useStore();
    const route = useRoute();

    const openRecent = () => {
      store.dispatch("openDrawer", { key: recentDrawerId });
    };

    const openApps = () => {
      const args = {};

      if (route.params.id) args._id = route.params.id;

      switch (route.name) {
        case "chatroom":
        case "conversation-chat":
          args.ref = getDeveloperAppService(route.name);
          break;
      }

      store.dispatch("openDrawer", {
        key: addDeveloperAppSubscriptionDrawerId,
        value: args,
      });
    };

    return {
      openApps,
      openSupport,
      openRecent,
      addDeveloperAppSubscriptionDrawerId,
      t,
    };
  },
});
</script>

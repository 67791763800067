import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import c from "@circuitid/common";

import APIModule from "@/store/modules/APIModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import { isElectronMinimalRoute } from "@/core/helpers/functions.js";

config.rawError = true;

const storeParams = {
  modules: {
    APIModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ThemeModeModule,
  },
  plugins: [],
};
try {
  // eslint-disable-next-line
  // @ts-ignore
  //if (isElectronMinimalRoute()) {
  const iscallManagerEvent = (mutation: any) => {
    return !["setObjectAttribute"].includes(mutation.type) ||
      c.arrayEmpty(mutation.payload) ||
      c.dependencyMissing(mutation.payload.key) ||
      !["phone"].includes(mutation.payload.key)
      ? false
      : true;
  };
  const plugin = (store: any) => {
    store.subscribe((mutation: any) => {
      // console.log("m", mutation);
      if (
        c.arrayEmpty(mutation) ||
        c.dependencyMissing(mutation.type) ||
        !iscallManagerEvent(mutation) ||
        c.arrayEmpty(window) ||
        // eslint-disable-next-line
        // @ts-ignore
        c.arrayEmpty(window.electronAPI) ||
        // eslint-disable-next-line
        // @ts-ignore
        !c.isFunction(window.electronAPI.vuexSync) ||
        isElectronMinimalRoute()
      )
        return;

      //console.log("syncing", mutation);

      try {
        // eslint-disable-next-line
        // @ts-ignore
        window.electronAPI
          .vuexSync({
            mutation: c.cloneObject(mutation),
          })
          .catch((e: any) => {
            console.error("store/index vuexSync", e);
          });
      } catch (e) {
        console.error("store/index", e);
      }
    });
  };

  // eslint-disable-next-line
  // @ts-ignore
  storeParams.plugins.push(plugin);
  //  }
} catch (e) {
  console.error("store/index", e);
}

const store = createStore(storeParams);

export default store;

<template>
  <AppTitleBarBase>
    <template v-slot:slot1>
      <div id="window-controls-left" class="ps-20">
        <div class="button" id="back-button" @click="back">
          <i class="fa-solid fa-chevron-left text-muted fa-lg"></i>
        </div>
        <div class="button" id="foward-button" @click="forward">
          <i class="fa-solid fa-chevron-right text-muted fa-lg"></i>
        </div>
      </div>
    </template>
    <template v-slot:slot3 v-if="updateAvailable">
      <div id="window-controls-update">
        <button class="btn btn-success py-1 px-4 mx-2" @click="onUpdate">
          <font-awesome-icon icon="cloud-arrow-down" class="" />
          Update
        </button>
      </div>
    </template>
    <template v-slot:slot4>
      <div id="window-controls-right">
        <div class="my-auto">
          <ProfileMenu></ProfileMenu>
        </div>
      </div>
    </template>
  </AppTitleBarBase>
</template>

<script>
import { defineComponent, ref } from "vue";
import AppTitleBarBase from "@/components/common/AppTitleBarBase.vue";
import ProfileMenu from "@/components/common/ProfileMenu.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "AppTitleBar",
  components: {
    AppTitleBarBase,
    ProfileMenu,
  },
  setup() {
    const router = useRouter();
    const back = () => router.go(-1);
    const forward = () => router.go(1);
    const updateAvailable = ref(false);

    window.electronAPI.updateAvailable(async () => {
      updateAvailable.value = true;
    });

    const onUpdate = () => {
      updateAvailable.value = false;
      window.electronAPI.installUpdate();
    };

    return {
      back,
      forward,
      updateAvailable,
      onUpdate,
    };
  },
});
</script>

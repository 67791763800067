import { createVNode, render, onMounted, onUnmounted, computed } from "vue";
import { MenuComponent, defaultMenuOptions } from "@/assets/ts/components";
import parallel from "async/parallel";
import reflectAll from "async/reflectAll";
import { Actions } from "@/store/enums/StoreEnums";
import IdleJs from "idle-js";
import store from "@/store";
import {
  phoneDailpadDrawerId,
  lastVisitedPageVar,
  config,
  mediaPath,
  portalUrl,
  electronMinimalRoutes,
  typingDebounceTime,
  maxSidebarSubTitleLength,
  typingDebounceShowTime,
  defaultFindLimit,
  plugins,
  enabledPlugins,
  minPresenceIdleSeconds,
} from "@/core/helpers/config";

let route, router;

const sounds = {
  sendMessageAudio: {},
};

export let t;
export let c;

const typingUsersDebounce = {};

export const initMenuButtons = function (id) {
  if (c.dependencyMissing(id)) return;

  setTimeout(function () {
    try {
      new MenuComponent(document.getElementById(id), defaultMenuOptions);
    } catch (e) {
      console.error(e);
    }
  }, 500);
};

export const toggleAsideSecondary = (param) => {
  try {
    if (!param) {
      store.dispatch("setStateValue", {
        key: "showSubMenuToggleButton",
        value: false,
      });
      document.body.setAttribute("data-kt-aside-minimize", "on");
    } else {
      store.dispatch("setStateValue", {
        key: "showSubMenuToggleButton",
        value: true,
      });
      document.body.removeAttribute("data-kt-aside-minimize");
    }
  } catch (e) {
    console.error(e);
  }
};

export const checkName = function (data) {
  data.store
    .dispatch("findOne", {
      service: data.service,
      filter: {
        name: data.name,
      },
    })
    .then((r) => {
      if (c.arrayEmpty(r))
        return data.reject({ message: c.errorMessages.internalServer });

      return !r.total
        ? data.resolve(true)
        : data.resolve({ message: c.translate("nameExists") });
    })
    .catch(() => {
      data.reject({ message: c.translate("nameExists") });
    });
};

export const openDrawer = function (params) {
  if (!params.event.selected) return;

  //if (c.isFunction(params.event.deselectAll))
  params.event.deselectAll();

  const open = () => {
    params.store.dispatch("openDrawer", {
      key: params.objectViewDrawerId,
      value: params.event.data._id,
    });
  };

  if (params.store.getters.drawers[params.objectViewDrawerId]) {
    params.store.dispatch("closeDrawer", {
      key: params.objectViewDrawerId,
    });
    setTimeout(function () {
      open();
    }, 50);
  } else open();
};

export const getDrawerVars = (params) => {
  if (
    c.arrayEmpty(params) ||
    c.dependencyMissing((params.service = c.toLower(params.service))) ||
    c.dependencyMissing(
      (params.objectName = c.trimString(params.objectName))
    ) ||
    c.dependencyMissing(
      (params.objectComponent = c.trimString(params.objectComponent))
    )
  )
    return {};

  return {
    component: "common/DrawerObject",
    params: {
      objectViewDrawerId: params.service + "ID",
      drawerPostId: params.service + "Post",
      service: params.service,
      objectName: params.objectName,
      objectComponent: params.objectComponent,
    },
  };
};

export const getPostDrawerVars = (params) => {
  if (
    c.arrayEmpty(params) ||
    c.dependencyMissing((params.service = c.toLower(params.service))) ||
    c.dependencyMissing(
      (params.objectName = c.trimString(params.objectName))
    ) ||
    c.dependencyMissing(
      (params.objectComponent = c.trimString(params.objectComponent))
    )
  )
    return {};

  const vars = getDrawerVars(params);

  if (c.arrayEmpty(vars) || c.arrayEmpty(vars.params)) return {};

  vars.id = vars.params.drawerPostId;
  vars.method = "post";

  if (c.isFunction(params.formatter)) vars.params.formatter = params.formatter;

  if (c.isFunction(params.postCallback))
    vars.params.postCallback = params.postCallback;

  if (!c.arrayEmpty(params.validation))
    vars.params.validation = params.validation;

  vars.smSize = c.isPositiveNum(params.smSize) ? params.smSize : 400;
  vars.lgSize = c.isPositiveNum(params.lgSize) ? params.lgSize : 500;
  vars.xlSize = c.isPositiveNum(params.xlSize) ? params.xlSize : 600;

  return vars;
};

export const getPatchDrawerVars = (params) => {
  if (
    c.arrayEmpty(params) ||
    c.dependencyMissing((params.service = c.toLower(params.service))) ||
    c.dependencyMissing(
      (params.objectComponent = c.trimString(params.objectComponent))
    )
  )
    return {};

  const vars = {
    params: {
      objectViewDrawerId: params.service + "ID",
      drawerId: !c.dependencyMissing(params.id)
        ? params.id
        : params.service + "Patch",
      service: params.service,
      objectName: params.objectName,
      objectComponent: params.objectComponent,
    },
  };

  if (!c.dependencyMissing(params.objectName))
    params.objectName = c.trimString(params.objectName);

  if (!c.arrayEmpty(params.custom)) vars.params.custom = params.custom;

  vars.id = vars.params.drawerId;
  vars.method = "patch";
  vars.component = "objects/AboutObject";

  if (!c.dependencyMissing(params.title)) vars.title = params.title;

  vars.lgSize = c.isPositiveNum(params.lgSize) ? params.lgSize : 500;
  vars.xlSize = c.isPositiveNum(params.xlSize) ? params.xlSize : 600;

  return vars;
};

export const getTableConfig = function (params) {
  const postId = params.service + "Post";
  const objectViewDrawerId = params.service + "ID";

  params.service = c.toLower(params.service);

  const config = {
    id: params.service,
    serviceRef: params.service + "_service",
    breadcrumbs: params.breadcrumbs,
    requiredFeatures: params.requiredFeatures,
    uniqueId: c.randomChar(20),
    alwaysShowGrid:
      params.alwaysShowGrid === undefined || params.alwaysShowGrid === true
        ? true
        : false,
    enableTableRef:
      typeof params.enableTableRef !== "undefined"
        ? params.enableTableRef
        : true,
    allowDelete:
      params.allowDelete === undefined || params.allowDelete === true
        ? true
        : false,
    cacheBlockSize:
      typeof params.cacheBlockSize !== "undefined"
        ? params.cacheBlockSize
        : defaultFindLimit,
    dataSource:
      typeof params.dataSource !== "undefined" ? params.dataSource : "server",
    objectComponent: params.objectComponent,
    paginationAutoPageSize:
      typeof params.paginationAutoPageSize !== "undefined"
        ? params.paginationAutoPageSize
        : false,
    drawerPostId: postId,
    header: true,
    loadType:
      typeof params.loadType !== "undefined" ? params.loadType : "partial",
    objectName: params.objectName,
    dataType:
      typeof params.dataType !== "undefined"
        ? c.toLower(params.dataType)
        : "server",
    pagination:
      typeof params.pagination === "undefined" ? true : params.pagination,
    rowsPerPage: !c.isPositiveNum(params.rowsPerPage)
      ? params.store.getters.info.defaultPagingLength
      : params.rowsPerPage,
    rowSelection:
      typeof params.rowSelection === "undefined"
        ? "multiple"
        : params.rowSelection,
    service: params.service,
    sideBar: false,
    sort: typeof params.sort !== "undefined" ? params.sort : {},
    enableCheckboxSelection:
      typeof params.enableCheckboxSelection === "undefined"
        ? true
        : params.enableCheckboxSelection,
    enableActions: params.enableActions,
    columns: params.columns,
    objectViewDrawerId: objectViewDrawerId,
    onRowDoubleClicked: function (event) {
      if (
        params.disableDoubleClick ||
        event.data.viewable === false ||
        (!params.store.getters.permitted([params.service + ":patch"]) &&
          params.forceDoubleClick !== true)
      )
        return;

      if (params.viewByRoute && !c.dependencyMissing(params.objectRoute))
        goTo(getPath(params.objectRoute, event.data));
      else if (params.viewByDrawer && params.enableRowSelection !== false) {
        openDrawer({
          event: event,
          store: params.store,
          objectViewDrawerId: objectViewDrawerId,
        });
      } else if (c.isFunction(params.onDoubleClick))
        params.onDoubleClick(event);
    },
    postCallback: function () {
      params.app.refs[params.service + "_service"].$refs[
        params.service
      ].showGrid = true;
    },
    validation: params.validation || undefined,
  };

  if (params.autoHeight) config.autoHeight = true;

  if (typeof params.disableDoubleClick !== "undefined")
    config.disableDoubleClick = params.disableDoubleClick;

  if (params.noCache) config.noCache = true;

  if (params.noShowSaveSuccess) config.noShowSaveSuccess = true;

  config.postCallback = (r) => {
    params.app.refs[params.service + "_service"].$refs[
      params.service
    ].showGrid = true;

    params.app.refs[params.service + "_service"].$refs[
      params.service
    ].refresh();

    if (c.isFunction(params.postCallback)) params.postCallback(r);
  };

  if (c.isFunction(params.onRowSelected))
    config.onRowSelected = params.onRowSelected;

  if (params.addByDrawer) {
    config.addByDrawer = true;
    config.openDrawer = function () {
      params.store.dispatch("openDrawer", {
        key: postId,
        value: true,
      });
    };
  } else if (params.addByRoute) {
    config.addByRoute = true;
    config.addByRouteName = "add-" + params.service;
    config.openDrawer = function () {
      router.push({ name: "add-" + params.service });
    };
  } else if (params.addByModal && !c.dependencyMissing(params.addByModalName)) {
    config.addByModal = true;
    config.addByModalName = params.addByModalName;
  }

  if (!c.dependencyMissing(params.objectRoute))
    config.objectRoute = params.objectRoute;

  if (params.viewByRoute && !c.dependencyMissing(params.objectRoute))
    config.viewByRoute = params.viewByRoute;

  if (!c.dependencyMissing(params.idField)) config.idField = params.idField;

  if (params.linkAvatar === true) config.linkAvatar = true;

  if (params.localData === true) {
    config.dataType = "local";
    config.data = params.data;
    delete config.dataSource;
  } else if (params.realtime) {
    config.realtime = params.realtime;
    config.dataType = "realtime";
    delete config.dataSource;
  }

  if (!c.dependencyMissing(params.populate) || !c.arrayEmpty(params.populate))
    config.populate = params.populate;

  if (c.isFunction(params.onReady)) config.onReady = params.onReady;

  if (c.isFunction(params.formatter)) config.formatter = params.formatter;

  if (!c.arrayEmpty(params.queryParamsFilters))
    config.queryParamsFilters = params.queryParamsFilters;

  if (!c.arrayEmpty(params.filterFields))
    config.filterFields = params.filterFields;

  if (typeof params.rowDragManaged !== "undefined") {
    config.rowDragManaged = params.rowDragManaged;

    if (config.rowDragManaged) {
      if (typeof params.suppressMoveWhenRowDragging !== "undefined")
        config.suppressMoveWhenRowDragging = params.suppressMoveWhenRowDragging;
      else config.suppressMoveWhenRowDragging = true;

      if (typeof params.rowDragMultiRow !== "undefined")
        config.rowDragMultiRow = params.rowDragMultiRow;
      else config.rowDragMultiRow = false;

      if (typeof params.rowDragEntireRow !== "undefined")
        config.rowDragEntireRow = params.rowDragEntireRow;
      else config.rowDragEntireRow = true;

      // row drag doesn't support sort
      for (let i = 0; i < config.columns.length; i++) {
        delete config.columns[i].sortable;
        delete config.columns[i].sort;
      }

      // row drag doesn't support pagination or dataSource as server
      config.pagination = false;
      config.dataSource = "client";
    }
  }

  if (typeof params.showServiceViewDrawer !== "undefined")
    config.showServiceViewDrawer = params.showServiceViewDrawer;
  if (typeof params.showServicePostDrawer !== "undefined")
    config.showServicePostDrawer = params.showServicePostDrawer;

  if (Number.isInteger(params.realtimeDebounceTime))
    config.realtimeDebounceTime = params.realtimeDebounceTime;

  if (!c.dependencyMissing(params.objectViewDataArray))
    config.objectViewDataArray = params.objectViewDataArray;

  if (!c.arrayEmpty(params.objectParams))
    config.objectParams = params.objectParams;

  if (c.isPositiveNum(params.drawerXlSize))
    config.drawerXlSize = parseInt(params.drawerXlSize);

  if (c.isPositiveNum(params.drawerLgSize))
    config.drawerLgSize = parseInt(params.drawerLgSize);

  if (c.isPositiveNum(params.drawerMdSize))
    config.drawerMdSize = parseInt(params.drawerMdSize);

  if (c.isPositiveNum(params.drawerSmSize))
    config.drawerSmSize = parseInt(params.drawerSmSize);

  if (!c.arrayEmpty(params.tableFilter))
    config.tableFilter = params.tableFilter;

  if (!c.arrayEmpty(params.filter) || c.isFunction(params.filter))
    config.filter = params.filter;

  if (c.isPositiveNum(params.height))
    config.height = parseInt(c.getDigits(params.height));

  if (c.isFunction(params.selectable)) config.selectable = params.selectable;

  if (c.isFunction(params.realtimeFilter))
    config.realtimeFilter = params.realtimeFilter;

  if (c.isFunction(params.onRowDragEnter))
    config.onRowDragEnter = params.onRowDragEnter;

  if (c.isFunction(params.onRowDragStart))
    config.onRowDragStart = params.onRowDragStart;

  if (c.isFunction(params.onRowDragEnd))
    config.onRowDragEnd = params.onRowDragEnd;

  if (c.isFunction(params.onRowDragMove))
    config.onRowDragMove = params.onRowDragMove;

  if (c.isFunction(params.onRowDragLeave))
    config.onRowDragLeave = params.onRowDragLeave;

  return config;
};

export const getNumOptions = function (params) {
  const options = [];

  if (
    c.arrayEmpty(params) ||
    !c.isPositiveNum(params.end) ||
    !c.isPositiveNum(params.increment) ||
    parseInt(params.start) > parseInt(params.end)
  )
    return options;

  params.start = parseInt(params.start);
  params.end = parseInt(params.end);
  params.increment = parseInt(params.increment);
  params.divideBy = c.isPositiveNum(params.divideBy)
    ? parseInt(params.divideBy)
    : params.increment;
  params.initialIncrement = c.isPositiveNum(params.initialIncrement)
    ? parseInt(params.initialIncrement)
    : 0;
  params.name = c.dependencyMissing(params.name)
    ? ""
    : c.trimString(params.name);

  for (
    let i = params.start;
    i <= params.end;
    i +=
      !c.isPositiveNum(params.initialIncrement) || i !== params.start
        ? params.increment
        : params.initialIncrement
  )
    options.push({
      id: i,
      name:
        (!c.isFunction(params.nameformatter)
          ? i / params.divideBy
          : params.nameformatter(i / params.divideBy)) +
        " " +
        (i > params.increment
          ? params.name + (!c.dependencyMissing(params.name) ? "s" : "")
          : params.name),
    });

  return options;
};

export const getTimezone = function () {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getDateTimeByEpoch = function (epoch, timezone) {
  if (isNaN(epoch)) return;

  if (c.dependencyMissing(timezone)) timezone = getTimezone();

  return new Date(parseInt(parseInt(epoch) * 1000)).toLocaleString("en-US", {
    timeZone: timezone,
  });
};

export const goTo = function (path) {
  if (c.arrayEmpty(router) || c.dependencyMissing(path)) return;

  router.push({ path: path });
};

export const getPath = function (path, object) {
  if (c.dependencyMissing((path = c.trimString(path))) || c.arrayEmpty(object))
    return "";

  if (!c.dependencyMissing(object._id)) {
    if (path.includes(":id")) path = path.replace(":id", object._id);
    else if (path.includes(":_id")) path = path.replace(":_id", object._id);
    else path += object._id;
  }

  return path;
};

export const timeOptions = [
  { id: "0000", name: "12:00 A.M" },
  { id: "0030", name: "12:30 A.M" },
  { id: "0100", name: "1:00 A.M" },
  { id: "0130", name: "1:30 A.M" },
  { id: "0200", name: "2:00 A.M" },
  { id: "0230", name: "2:30 A.M" },
  { id: "0300", name: "3:00 A.M" },
  { id: "0330", name: "3:30 A.M" },
  { id: "0400", name: "4:00 A.M" },
  { id: "0430", name: "4:30 A.M" },
  { id: "0500", name: "5:00 A.M" },
  { id: "0530", name: "5:30 A.M" },
  { id: "0600", name: "6:00 A.M" },
  { id: "0630", name: "6:30 A.M" },
  { id: "0700", name: "7:00 A.M" },
  { id: "0730", name: "7:30 A.M" },
  { id: "0800", name: "8:00 A.M" },
  { id: "0830", name: "8:30 A.M" },
  { id: "0900", name: "9:00 A.M" },
  { id: "0930", name: "9:30 A.M" },
  { id: "1000", name: "10:00 A.M" },
  { id: "1030", name: "10:30 A.M" },
  { id: "1100", name: "11:00 A.M" },
  { id: "1130", name: "11:30 A.M" },
  { id: "1200", name: "12:00 P.M" },
  { id: "1230", name: "12:30 P.M" },
  { id: "1300", name: "1:00 P.M" },
  { id: "1330", name: "1:30 P.M" },
  { id: "1400", name: "2:00 P.M" },
  { id: "1430", name: "2:30 P.M" },
  { id: "1500", name: "3:00 P.M" },
  { id: "1530", name: "3:30 P.M" },
  { id: "1600", name: "4:00 P.M" },
  { id: "1630", name: "4:30 P.M" },
  { id: "1700", name: "5:00 P.M" },
  { id: "1730", name: "5:30 P.M" },
  { id: "1800", name: "6:00 P.M" },
  { id: "1830", name: "6:30 P.M" },
  { id: "1900", name: "7:00 P.M" },
  { id: "1930", name: "7:30 P.M" },
  { id: "2000", name: "8:00 P.M" },
  { id: "2030", name: "8:30 P.M" },
  { id: "2100", name: "9:00 P.M" },
  { id: "2139", name: "9:30 P.M" },
  { id: "2200", name: "10:00 P.M" },
  { id: "2230", name: "10:30 P.M" },
  { id: "2300", name: "11:00 P.M" },
  { id: "2330", name: "11:30 P.M" },
];

export const signalChange = (fieldId) => {
  if (c.dependencyMissing(fieldId)) return;

  const field = document.getElementById(fieldId);
  const event = new Event("change");
  field.dispatchEvent(event);

  return true;
};

export const permitted = (permissions = [], required, oneOf = true) => {
  if (c.arrayEmpty(permissions)) return false;

  if (c.arrayEmpty(required) && c.dependencyMissing(required)) return true;

  if (
    permissions.includes("superadmin") ||
    permissions.includes("superadmin:*")
  )
    return true;

  if (typeof required === "string") required = [required];

  let permitted = false;

  const finalRequired = [];

  for (let i = 0; i < required.length; i++) {
    finalRequired.push(c.toLower(required[i]));

    if (!required[i].includes(":")) continue;

    const permission = required[i].split(":")[0];

    if (!finalRequired.includes(c.toLower(permission) + ":*"))
      finalRequired.push(c.toLower(permission) + ":*");
  }

  for (let i = 0; i < finalRequired.length; i++)
    if (!c.dependencyMissing(finalRequired[i])) {
      if (!permissions.includes(finalRequired[i])) {
        if (!oneOf) return false;
      } else {
        permitted = true;

        if (oneOf) return true;
      }
    }

  return permitted;
};

export const hasFeature = (licenses = [], oneOf = true) => {
  if (
    !c.arrayEmpty(store.getters.permissions) &&
    (store.getters.permissions.includes("superadmin") ||
      store.getters.permissions.includes("superadmin:*"))
  )
    return true;

  let permitted = false;

  if (c.arrayEmpty(licenses) || c.arrayEmpty(store.getters.userFeatures))
    return permitted;

  for (let i = 0; i < licenses.length; i++)
    if (!c.dependencyMissing(licenses[i])) {
      if (!store.getters.userFeatures.includes(licenses[i])) {
        if (!oneOf) return false;
      } else {
        permitted = true;

        if (oneOf) return true;
      }
    }

  return permitted;
};

export const showMenus = (config) => {
  if (c.arrayEmpty(config)) return {};

  const show = {};

  for (const item in config) {
    show[c.toLower(item)] = false;

    for (const subItem in config[item])
      if (config[item][subItem].permitted) show[c.toLower(item)] = true;
  }

  return show;
};

export const getAvatarName = (s) => {
  if (c.dependencyMissing(s)) return "";

  s = c.getAlphaNumeric(s);

  if (c.isPositiveNum(s.replaceAll(" ", ""))) {
    s = s.replaceAll(" ", "");
    s = s[1] + " " + s[2] + " " + s[3];
  }

  if (s.substr(0, 1) == 1) s = s.substr(1);

  return s;
};
export const patchPriorities = async (e) => {
  if (c.arrayEmpty(e) || c.arrayEmpty(e.data) || isNaN(e.priority)) return;

  for (const i in e.data)
    await patchPriority({
      data: e.data[i],
      priority: e.priority,
      app: e.app,
      service: e.service,
    });
};

export const patchPriority = (params) => {
  return new Promise((resolve, reject) => {
    if (
      c.arrayEmpty(params) ||
      c.arrayEmpty(params.data) ||
      !c.isValidObjectId(params.data._id) ||
      isNaN(params.priority) ||
      c.arrayEmpty(params.app) ||
      c.dependencyMissing((params.service = c.toLower(params.service)))
    )
      return reject(c.errorMessages.dependencyMissing);

    store
      .dispatch("patchData", {
        service: params.service,
        id: params.data._id,
        data: {
          priority: parseInt(parseInt(params.priority) + 1),
        },
      })
      .then(() => {
        params.app.refs[params.service + "_service"].$refs[
          params.service
        ].refresh();
        resolve();
      })
      .catch((e) => {
        c.showError(e);
        reject(e.message);
      });
  });
};

export const getFieldById = (f) => {
  if (c.dependencyMissing(f)) return;

  const field = document.getElementById(f);

  return field;
};
export const getFieldValueById = (f) => {
  if (c.dependencyMissing(f)) return;

  const field = getFieldById(f);

  if (typeof field !== "object") return;

  return field.value;
};
export const setFieldValueById = (f, v = "") => {
  if (c.dependencyMissing(f)) return;

  const field = getFieldById(f);

  if (typeof field !== "object") return;

  return (field.value = v);
};
export const focusFieldValueById = (f) => {
  if (c.dependencyMissing(f)) return;

  const field = getFieldById(f);

  if (typeof field !== "object") return;

  return field.focus();
};
export const getSound = (f, loop = false) => {
  if (c.dependencyMissing((f = c.trimString(f)))) return {};

  try {
    const sound = new Audio(mediaPath + "sounds/" + f);

    sound.preload = "auto";
    sound.type = "audio/mpeg";
    sound.loop = loop;

    return sound;
  } catch (e) {
    console.error(e);
  }
};

export const notify = async (d) => {
  if (
    c.arrayEmpty(d) ||
    c.dependencyMissing(d.body) ||
    c.dependencyMissing(d.title)
  )
    return;

  const show = async () => {
    const config = {
      body: d.body,
      icon: store.getters.info.logoSymbol,
      tag: c.randomChar(),
      badge: store.getters.info.logoSymbol,
    };

    if (d.vibrate) config.vibrate = d.vibrate;

    if (d.renotify) config.renotify = true;

    if (d.silent) config.silent = true;

    if (d.requireInteraction) config.requireInteraction = true;

    if (!c.arrayEmpty(d.data)) config.data = d.data;

    if (
      !store.getters.isElectron &&
      !c.arrayEmpty(store.getters.serviceWorker) &&
      !c.arrayEmpty(d.actions)
    )
      config.actions = d.actions;

    try {
      let noty;

      if (
        !store.getters.isElectron &&
        !c.arrayEmpty(store.getters.serviceWorker)
      )
        noty = await store.getters.serviceWorker.showNotification(
          d.title,
          config
        );
      else noty = new Notification(d.title, config);

      if (c.isFunction(d.click)) noty.addEventListener("click", d.click);

      if (c.isFunction(d.close)) noty.addEventListener("close", d.close);

      if (c.isFunction(d.error)) noty.addEventListener("error", d.error);

      if (c.isFunction(d.show)) noty.addEventListener("show", d.show);

      return noty;
    } catch (e) {
      console.error(e);
    }
  };

  if (Notification.permission === "granted") return await show();
  else if (Notification.permission !== "denied") {
    const permission = await Notification.requestPermission();

    if (permission !== "granted") return;

    show();
  }
};

export function mount(component, { props, children, element, app } = {}) {
  let el = element;
  let vNode = createVNode(component, props, children);

  if (app && app._context) vNode.appContext = app._context;

  if (el) render(vNode, el);
  else if (typeof document !== "undefined")
    render(vNode, (el = document.createElement("div")));

  const destroy = () => {
    if (el) render(null, el);

    el = null;

    vNode = null;
  };

  return { vNode, destroy, el };
}

export const searchExists = (s, i) => {
  if (c.dependencyMissing(s) && s != "0") return true;

  if (
    !c.dependencyMissing(s) &&
    c.dependencyMissing((s = c.getAlphaNumeric(s)))
  )
    return false;

  try {
    return (
      c.dependencyMissing(s) ||
      !c.arrayEmpty(c.trimString(i).match(new RegExp(s, "i")))
    );
  } catch (e) {
    console.error("functions->searchExists", e);
    return false;
  }
};

export const changeListGroup = (_id, gId, gService) => {
  if (!c.isValidObjectId(_id)) return;

  const service = "listgroups";
  const functions = [];

  if (!c.arrayEmpty(store.getters.listgroupData))
    for (let i = 0; i < store.getters.listgroupData.length; i++) {
      if (
        c.toLower(store.getters.listgroupData[i].service) !==
          c.toLower(gService) ||
        c.trimString(store.getters.listgroupData[i]._id) === c.trimString(gId)
      )
        continue;

      if (store.getters.listgroupData[i].items.includes(_id))
        (function (listGroup) {
          functions.push(function (callback) {
            listGroup.items = listGroup.items.filter(
              (i) => c.trimString(i) !== c.trimString(_id)
            );

            store.dispatch("patchArrayObject", {
              key: service,
              value: listGroup,
            });

            store
              .dispatch("patchData", {
                id: listGroup._id,
                service: service,
                data: {
                  $pull: {
                    items: _id,
                  },
                },
              })
              .then(() => {
                callback(null);
              })
              .catch((e) => {
                console.error(e);
                callback(e.message);
              });
          });
        })(store.getters.listgroupData[i]);
    }

  if (c.isValidObjectId(gId)) {
    const object = {
      ...store.getters.getObjectById({ _id: gId, service: service }),
    };

    if (c.arrayEmpty(object)) return;

    if (!object.items.includes(_id)) {
      object.items.push(_id);

      (function (o) {
        functions.push(function (callback) {
          store.dispatch("patchArrayObject", {
            key: service,
            value: o,
          });

          callback(null);
        });
      })(object);

      (function () {
        functions.push(function (callback) {
          store
            .dispatch("patchData", {
              service: service,
              id: gId,
              data: {
                $addToSet: {
                  items: _id,
                },
              },
            })
            .then(() => {
              callback(null);
            })
            .catch((e) => {
              console.error(e);
              callback(e.message);
            });
        });
      })();
    }
  }

  parallel(reflectAll(functions));
};

export const allowDrop = (e) => {
  e.preventDefault();
  return false;
};

export const drop = (e, parent, callback) => {
  e.preventDefault();

  const d = e.dataTransfer.getData("text");

  if (!c.isValidObjectId(d)) return;

  callback(d);

  parent.classList.remove("drag-over-group");
};

export const dragEnter = (e, parent) => {
  parent.classList.add("drag-over-group");
};

export const dragLeave = (e, parent) => {
  if (parent.contains(e.relatedTarget)) return;

  parent.classList.remove("drag-over-group");
};

export const openPhoneDialpad = () => {
  store.dispatch("openDrawer", { key: phoneDailpadDrawerId, value: 1 });
};

export const closePhoneDialpad = () => {
  store.dispatch("closeDrawer", { key: phoneDailpadDrawerId });
};

export const togglePhoneDialpad = () => {
  if (store.getters.drawers[phoneDailpadDrawerId]) closePhoneDialpad();
  else openPhoneDialpad();
};

export const defaultPagePage = () => {
  const lastVisitedPage = localStorage.getItem(lastVisitedPageVar);

  if (lastVisitedPage && !["/404", "/500"].includes(lastVisitedPage))
    return lastVisitedPage;

  if (
    store.getters.info &&
    store.getters.info.site &&
    store.getters.info.site.dashboardRoutePath
  )
    return store.getters.info.site.dashboardRoutePath;

  return "/dashboard";
};

export const patchPresence = async (status) => {
  if (
    !c.isValidObjectId(store.getters.userId) ||
    (await store.dispatch("connected")) !== true
  )
    return;

  let id = localStorage.getItem("browserId");

  if (c.dependencyMissing(id)) {
    id = new c.newObjectId().toString();

    localStorage.setItem("browserId", id);
  }

  store
    .dispatch("patchData", {
      service: "presence",
      data: {
        status: status,
        browserId: id,
      },
      id: store.getters.userId,
    })
    .catch((e) => {
      console.error(e);
    });
};

export let idle;

export const startPresence = () => {
  patchPresence("online");

  stopPresence();

  store.dispatch("stopPresenceIdleWatcher");

  const idleSeconds =
    c.isPositiveNum(store.getters.info.presenceIdleMinutes) &&
    c.getInt(store.getters.info.presenceIdleMinutes) > minPresenceIdleSeconds
      ? c.getInt(c.getInt(store.getters.info.presenceIdleMinutes) * 60000)
      : minPresenceIdleSeconds;

  idle = new IdleJs({
    idle: idleSeconds,
    //idle: .2 * 60000,
    events: ["mousemove", "keydown", "mousedown", "touchstart"],
    onIdle: function () {
      patchPresence("idle");

      store.dispatch("setValue", {
        key: "presenceIdleWatcher",
        value: setInterval(
          () => {
            patchPresence("idle");
          },
          c.getInt(idleSeconds - 5000)
        ),
      });
    },
    onActive: function () {
      store.dispatch("stopPresenceIdleWatcher");
      patchPresence("online");
    },
    keepTracking: true,
    startAtIdle: false,
  });

  idle.start();

  store.dispatch("startPresenceWatcher");
};

export const stopPresence = () => {
  store.dispatch("stopPresenceWatcher");

  store.dispatch("stopPresenceIdleWatcher");

  if (!c.arrayEmpty(idle) && c.isFunction(idle.stop)) idle.stop();
};

export const getMeetUrl = (fullUrl = false) => {
  return (!fullUrl ? "" : portalUrl) + "/meet.html";
};

export const getDrawerNameByService = (s, patch = false) => {
  if (c.dependencyMissing((s = c.trimString(s)))) return "";

  return s + (patch ? "Patch" : "Post");
};

export const getName = ({ ...d }) => {
  if (
    c.arrayEmpty(d) ||
    (c.dependencyMissing(d.first) &&
      c.dependencyMissing(d.last) &&
      c.arrayEmpty(d.contactIdentity) &&
      c.dependencyMissing(c.getDigits(d.mobilePhone)))
  )
    return;

  const f = isNaN(d.first)
    ? c.trimString(d.first)
    : c.getFormattedPhoneNumber(d.first);
  const l = c.trimString(d.last);
  const m = c.trimString(d.mobilePhone);

  let name;

  if (!c.dependencyMissing(f)) name = f;

  if (!c.dependencyMissing(l))
    name += (!c.dependencyMissing(name) ? " " : "") + l;

  if (c.dependencyMissing(name) && !c.dependencyMissing(m))
    name = c.getFormattedPhoneNumber(m);

  return name;
};

export const meetNow = (meet = {}, options = {}) => {
  if (
    c.arrayEmpty(meet) ||
    !c.isValidObjectId(meet._id) ||
    c.dependencyMissing(meet.domain)
  ) {
    if (c.arrayEmpty((meet = store.getters.currentUser.meet))) return;
  }
  try {
    meet = c.cloneObject(meet);

    // meet.domain = "meetdev.circuitid.com";

    if (store.getters.isElectron) window.electronAPI.meet(meet);
    else if (!c.dependencyMissing(getMeetUrl(false))) {
      window.localStorage.setItem("meetInfo", JSON.stringify(meet));

      if (!options.redirect) window.open(getMeetUrl(false), "meet");
      else window.location = getMeetUrl(false);
    }
  } catch (e) {
    console.log("meetNow", e);
  }
};

export const openScheduler = () => {
  //const url = "http://localhost:3000";
  const url = store.getters.currentUser.calendar.url;
  return openWindow(
    url + "/auth/login?jwt=" + store.getters.currentUser.calendar.jwt,
    "calendar"
  );
};

export const openWindow = (url, name = "defaultWindow") => {
  if (c.dependencyMissing(url)) return;

  if (store.getters.isElectron)
    window.electronAPI.openWindow({
      url: url,
      name: name,
    });
  else window.open(url, name);
};

export const fullContent = (params = {}) => {
  onMounted(() => {
    config.value.content.width = "fluid";

    store.dispatch("setStateValue", { key: "hideHeader", value: true });
    store.dispatch("setStateValue", { key: "hideFooter", value: true });
    store.dispatch("setStateValue", {
      key: "containerStyle",
      value: c.dependencyMissing(params.containerStyle)
        ? "background-color: white; padding: 0 !important"
        : params.containerStyle,
    });
    store.dispatch("setStateValue", {
      key: "contentStyle",
      value: "padding: 0 !important;",
    });
    store.dispatch("setStateValue", {
      key: "pageTitleStyle",
      value: "display: none !important;",
    });
  });

  onUnmounted(() => {
    store.dispatch("setStateValue", { key: "hideHeader", value: false });
    store.dispatch("setStateValue", { key: "hideFooter", value: false });
    store.dispatch("setStateValue", { key: "containerStyle", value: "" });
    store.dispatch("setStateValue", { key: "contentStyle", value: "" });
    store.dispatch("setStateValue", { key: "pageTitleStyle", value: "" });
  });
};

export const contentHeight = computed(() => {
  const phoneMiniHeight = 70;
  const electronAdjustment =
    store.getters.isElectron && !isElectronMinimalRoute()
      ? store.getters.style.appHeaderHeight
      : 0;
  const adjustment = store.getters.showMiniPhone
    ? phoneMiniHeight + 50 + electronAdjustment
    : 50 + electronAdjustment;

  return store.getters.height - adjustment + "px";
});

export const isPresenceStatusValid = (createdAt) => {
  const presenceAwayMinutes = c.isPositiveNum(
    store.getters.info.presenceAwayMinutes
  )
    ? c.getInt(store.getters.info.presenceAwayMinutes)
    : 10;

  return c.dependencyMissing(createdAt)
    ? false
    : c
          .moment()(c.moment()())
          .isBefore(c.moment()(createdAt).add(presenceAwayMinutes, "minutes"))
      ? true
      : false;
};

export const getUserStatusByStatus = (params) => {
  let status = "offline";
  if (c.arrayEmpty(params)) return "offline";

  for (const p in params)
    if (
      !c.arrayEmpty(params[p]) &&
      !c.dependencyMissing(params[p].createdAt) &&
      isPresenceStatusValid(params[p].createdAt)
    ) {
      if (params[p].status === "busy") return "busy";

      if (c.dependencyMissing(params[p].status)) status = params[p].status;
      else if (params[p].status === "online") status = "online";
      else if (status !== "online") status = params[p].status;
    }

  return status;
};

export const getBrowserCountry = () => {
  const timezones = {
    "Africa/Abidjan": {
      u: 0,
      c: ["CI", "BF", "GH", "GM", "GN", "ML", "MR", "SH", "SL", "SN", "TG"],
    },
    "Africa/Accra": {
      a: "Africa/Abidjan",
      c: ["GH"],
      r: 1,
    },
    "Africa/Addis_Ababa": {
      a: "Africa/Nairobi",
      c: ["ET"],
      r: 1,
    },
    "Africa/Algiers": {
      u: 60,
      c: ["DZ"],
    },
    "Africa/Asmara": {
      a: "Africa/Nairobi",
      c: ["ER"],
      r: 1,
    },
    "Africa/Asmera": {
      a: "Africa/Nairobi",
      c: ["ER"],
      r: 1,
    },
    "Africa/Bamako": {
      a: "Africa/Abidjan",
      c: ["ML"],
      r: 1,
    },
    "Africa/Bangui": {
      a: "Africa/Lagos",
      c: ["CF"],
      r: 1,
    },
    "Africa/Banjul": {
      a: "Africa/Abidjan",
      c: ["GM"],
      r: 1,
    },
    "Africa/Bissau": {
      u: 0,
      c: ["GW"],
    },
    "Africa/Blantyre": {
      a: "Africa/Maputo",
      c: ["MW"],
      r: 1,
    },
    "Africa/Brazzaville": {
      a: "Africa/Lagos",
      c: ["CG"],
      r: 1,
    },
    "Africa/Bujumbura": {
      a: "Africa/Maputo",
      c: ["BI"],
      r: 1,
    },
    "Africa/Cairo": {
      u: 120,
      c: ["EG"],
    },
    "Africa/Casablanca": {
      u: 60,
      d: 0,
      c: ["MA"],
    },
    "Africa/Ceuta": {
      u: 60,
      d: 120,
      c: ["ES"],
    },
    "Africa/Conakry": {
      a: "Africa/Abidjan",
      c: ["GN"],
      r: 1,
    },
    "Africa/Dakar": {
      a: "Africa/Abidjan",
      c: ["SN"],
      r: 1,
    },
    "Africa/Dar_es_Salaam": {
      a: "Africa/Nairobi",
      c: ["TZ"],
      r: 1,
    },
    "Africa/Djibouti": {
      a: "Africa/Nairobi",
      c: ["DJ"],
      r: 1,
    },
    "Africa/Douala": {
      a: "Africa/Lagos",
      c: ["CM"],
      r: 1,
    },
    "Africa/El_Aaiun": {
      u: 60,
      d: 0,
      c: ["EH"],
    },
    "Africa/Freetown": {
      a: "Africa/Abidjan",
      c: ["SL"],
      r: 1,
    },
    "Africa/Gaborone": {
      a: "Africa/Maputo",
      c: ["BW"],
      r: 1,
    },
    "Africa/Harare": {
      a: "Africa/Maputo",
      c: ["ZW"],
      r: 1,
    },
    "Africa/Johannesburg": {
      u: 120,
      c: ["ZA", "LS", "SZ"],
    },
    "Africa/Juba": {
      u: 120,
      c: ["SS"],
    },
    "Africa/Kampala": {
      a: "Africa/Nairobi",
      c: ["UG"],
      r: 1,
    },
    "Africa/Khartoum": {
      u: 120,
      c: ["SD"],
    },
    "Africa/Kigali": {
      a: "Africa/Maputo",
      c: ["RW"],
      r: 1,
    },
    "Africa/Kinshasa": {
      a: "Africa/Lagos",
      c: ["CD"],
      r: 1,
    },
    "Africa/Lagos": {
      u: 60,
      c: ["NG", "AO", "BJ", "CD", "CF", "CG", "CM", "GA", "GQ", "NE"],
    },
    "Africa/Libreville": {
      a: "Africa/Lagos",
      c: ["GA"],
      r: 1,
    },
    "Africa/Lome": {
      a: "Africa/Abidjan",
      c: ["TG"],
      r: 1,
    },
    "Africa/Luanda": {
      a: "Africa/Lagos",
      c: ["AO"],
      r: 1,
    },
    "Africa/Lubumbashi": {
      a: "Africa/Maputo",
      c: ["CD"],
      r: 1,
    },
    "Africa/Lusaka": {
      a: "Africa/Maputo",
      c: ["ZM"],
      r: 1,
    },
    "Africa/Malabo": {
      a: "Africa/Lagos",
      c: ["GQ"],
      r: 1,
    },
    "Africa/Maputo": {
      u: 120,
      c: ["MZ", "BI", "BW", "CD", "MW", "RW", "ZM", "ZW"],
    },
    "Africa/Maseru": {
      a: "Africa/Johannesburg",
      c: ["LS"],
      r: 1,
    },
    "Africa/Mbabane": {
      a: "Africa/Johannesburg",
      c: ["SZ"],
      r: 1,
    },
    "Africa/Mogadishu": {
      a: "Africa/Nairobi",
      c: ["SO"],
      r: 1,
    },
    "Africa/Monrovia": {
      u: 0,
      c: ["LR"],
    },
    "Africa/Nairobi": {
      u: 180,
      c: ["KE", "DJ", "ER", "ET", "KM", "MG", "SO", "TZ", "UG", "YT"],
    },
    "Africa/Ndjamena": {
      u: 60,
      c: ["TD"],
    },
    "Africa/Niamey": {
      a: "Africa/Lagos",
      c: ["NE"],
      r: 1,
    },
    "Africa/Nouakchott": {
      a: "Africa/Abidjan",
      c: ["MR"],
      r: 1,
    },
    "Africa/Ouagadougou": {
      a: "Africa/Abidjan",
      c: ["BF"],
      r: 1,
    },
    "Africa/Porto-Novo": {
      a: "Africa/Lagos",
      c: ["BJ"],
      r: 1,
    },
    "Africa/Sao_Tome": {
      u: 0,
      c: ["ST"],
    },
    "Africa/Timbuktu": {
      a: "Africa/Abidjan",
      c: ["ML"],
      r: 1,
    },
    "Africa/Tripoli": {
      u: 120,
      c: ["LY"],
    },
    "Africa/Tunis": {
      u: 60,
      c: ["TN"],
    },
    "Africa/Windhoek": {
      u: 120,
      c: ["NA"],
    },
    "America/Adak": {
      u: -600,
      d: -540,
      c: ["US"],
    },
    "America/Anchorage": {
      u: -540,
      d: -480,
      c: ["US"],
    },
    "America/Anguilla": {
      a: "America/Puerto_Rico",
      c: ["AI"],
      r: 1,
    },
    "America/Antigua": {
      a: "America/Puerto_Rico",
      c: ["AG"],
      r: 1,
    },
    "America/Araguaina": {
      u: -180,
      c: ["BR"],
    },
    "America/Argentina/Buenos_Aires": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/Catamarca": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/ComodRivadavia": {
      a: "America/Argentina/Catamarca",
      r: 1,
    },
    "America/Argentina/Cordoba": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/Jujuy": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/La_Rioja": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/Mendoza": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/Rio_Gallegos": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/Salta": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/San_Juan": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/San_Luis": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/Tucuman": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/Ushuaia": {
      u: -180,
      c: ["AR"],
    },
    "America/Aruba": {
      a: "America/Puerto_Rico",
      c: ["AW"],
      r: 1,
    },
    "America/Asuncion": {
      u: -240,
      d: -180,
      c: ["PY"],
    },
    "America/Atikokan": {
      a: "America/Panama",
      c: ["CA"],
      r: 1,
    },
    "America/Atka": {
      a: "America/Adak",
      r: 1,
    },
    "America/Bahia": {
      u: -180,
      c: ["BR"],
    },
    "America/Bahia_Banderas": {
      u: -360,
      d: -300,
      c: ["MX"],
    },
    "America/Barbados": {
      u: -240,
      c: ["BB"],
    },
    "America/Belem": {
      u: -180,
      c: ["BR"],
    },
    "America/Belize": {
      u: -360,
      c: ["BZ"],
    },
    "America/Blanc-Sablon": {
      a: "America/Puerto_Rico",
      c: ["CA"],
      r: 1,
    },
    "America/Boa_Vista": {
      u: -240,
      c: ["BR"],
    },
    "America/Bogota": {
      u: -300,
      c: ["CO"],
    },
    "America/Boise": {
      u: -420,
      d: -360,
      c: ["US"],
    },
    "America/Buenos_Aires": {
      a: "America/Argentina/Buenos_Aires",
      r: 1,
    },
    "America/Cambridge_Bay": {
      u: -420,
      d: -360,
      c: ["CA"],
    },
    "America/Campo_Grande": {
      u: -240,
      c: ["BR"],
    },
    "America/Cancun": {
      u: -300,
      c: ["MX"],
    },
    "America/Caracas": {
      u: -240,
      c: ["VE"],
    },
    "America/Catamarca": {
      a: "America/Argentina/Catamarca",
      r: 1,
    },
    "America/Cayenne": {
      u: -180,
      c: ["GF"],
    },
    "America/Cayman": {
      a: "America/Panama",
      c: ["KY"],
      r: 1,
    },
    "America/Chicago": {
      u: -360,
      d: -300,
      c: ["US"],
    },
    "America/Chihuahua": {
      u: -420,
      d: -360,
      c: ["MX"],
    },
    "America/Coral_Harbour": {
      a: "America/Panama",
      c: ["CA"],
      r: 1,
    },
    "America/Cordoba": {
      a: "America/Argentina/Cordoba",
      r: 1,
    },
    "America/Costa_Rica": {
      u: -360,
      c: ["CR"],
    },
    "America/Creston": {
      a: "America/Phoenix",
      c: ["CA"],
      r: 1,
    },
    "America/Cuiaba": {
      u: -240,
      c: ["BR"],
    },
    "America/Curacao": {
      a: "America/Puerto_Rico",
      c: ["CW"],
      r: 1,
    },
    "America/Danmarkshavn": {
      u: 0,
      c: ["GL"],
    },
    "America/Dawson": {
      u: -420,
      c: ["CA"],
    },
    "America/Dawson_Creek": {
      u: -420,
      c: ["CA"],
    },
    "America/Denver": {
      u: -420,
      d: -360,
      c: ["US"],
    },
    "America/Detroit": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Dominica": {
      a: "America/Puerto_Rico",
      c: ["DM"],
      r: 1,
    },
    "America/Edmonton": {
      u: -420,
      d: -360,
      c: ["CA"],
    },
    "America/Eirunepe": {
      u: -300,
      c: ["BR"],
    },
    "America/El_Salvador": {
      u: -360,
      c: ["SV"],
    },
    "America/Ensenada": {
      a: "America/Tijuana",
      r: 1,
    },
    "America/Fort_Nelson": {
      u: -420,
      c: ["CA"],
    },
    "America/Fort_Wayne": {
      a: "America/Indiana/Indianapolis",
      r: 1,
    },
    "America/Fortaleza": {
      u: -180,
      c: ["BR"],
    },
    "America/Glace_Bay": {
      u: -240,
      d: -180,
      c: ["CA"],
    },
    "America/Godthab": {
      a: "America/Nuuk",
      r: 1,
    },
    "America/Goose_Bay": {
      u: -240,
      d: -180,
      c: ["CA"],
    },
    "America/Grand_Turk": {
      u: -300,
      d: -240,
      c: ["TC"],
    },
    "America/Grenada": {
      a: "America/Puerto_Rico",
      c: ["GD"],
      r: 1,
    },
    "America/Guadeloupe": {
      a: "America/Puerto_Rico",
      c: ["GP"],
      r: 1,
    },
    "America/Guatemala": {
      u: -360,
      c: ["GT"],
    },
    "America/Guayaquil": {
      u: -300,
      c: ["EC"],
    },
    "America/Guyana": {
      u: -240,
      c: ["GY"],
    },
    "America/Halifax": {
      u: -240,
      d: -180,
      c: ["CA"],
    },
    "America/Havana": {
      u: -300,
      d: -240,
      c: ["CU"],
    },
    "America/Hermosillo": {
      u: -420,
      c: ["MX"],
    },
    "America/Indiana/Indianapolis": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Indiana/Knox": {
      u: -360,
      d: -300,
      c: ["US"],
    },
    "America/Indiana/Marengo": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Indiana/Petersburg": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Indiana/Tell_City": {
      u: -360,
      d: -300,
      c: ["US"],
    },
    "America/Indiana/Vevay": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Indiana/Vincennes": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Indiana/Winamac": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Indianapolis": {
      a: "America/Indiana/Indianapolis",
      r: 1,
    },
    "America/Inuvik": {
      u: -420,
      d: -360,
      c: ["CA"],
    },
    "America/Iqaluit": {
      u: -300,
      d: -240,
      c: ["CA"],
    },
    "America/Jamaica": {
      u: -300,
      c: ["JM"],
    },
    "America/Jujuy": {
      a: "America/Argentina/Jujuy",
      r: 1,
    },
    "America/Juneau": {
      u: -540,
      d: -480,
      c: ["US"],
    },
    "America/Kentucky/Louisville": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Kentucky/Monticello": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Knox_IN": {
      a: "America/Indiana/Knox",
      r: 1,
    },
    "America/Kralendijk": {
      a: "America/Puerto_Rico",
      c: ["BQ"],
      r: 1,
    },
    "America/La_Paz": {
      u: -240,
      c: ["BO"],
    },
    "America/Lima": {
      u: -300,
      c: ["PE"],
    },
    "America/Los_Angeles": {
      u: -480,
      d: -420,
      c: ["US"],
    },
    "America/Louisville": {
      a: "America/Kentucky/Louisville",
      r: 1,
    },
    "America/Lower_Princes": {
      a: "America/Puerto_Rico",
      c: ["SX"],
      r: 1,
    },
    "America/Maceio": {
      u: -180,
      c: ["BR"],
    },
    "America/Managua": {
      u: -360,
      c: ["NI"],
    },
    "America/Manaus": {
      u: -240,
      c: ["BR"],
    },
    "America/Marigot": {
      a: "America/Puerto_Rico",
      c: ["MF"],
      r: 1,
    },
    "America/Martinique": {
      u: -240,
      c: ["MQ"],
    },
    "America/Matamoros": {
      u: -360,
      d: -300,
      c: ["MX"],
    },
    "America/Mazatlan": {
      u: -420,
      d: -360,
      c: ["MX"],
    },
    "America/Mendoza": {
      a: "America/Argentina/Mendoza",
      r: 1,
    },
    "America/Menominee": {
      u: -360,
      d: -300,
      c: ["US"],
    },
    "America/Merida": {
      u: -360,
      d: -300,
      c: ["MX"],
    },
    "America/Metlakatla": {
      u: -540,
      d: -480,
      c: ["US"],
    },
    "America/Mexico_City": {
      u: -360,
      d: -300,
      c: ["MX"],
    },
    "America/Miquelon": {
      u: -180,
      d: -120,
      c: ["PM"],
    },
    "America/Moncton": {
      u: -240,
      d: -180,
      c: ["CA"],
    },
    "America/Monterrey": {
      u: -360,
      d: -300,
      c: ["MX"],
    },
    "America/Montevideo": {
      u: -180,
      c: ["UY"],
    },
    "America/Montreal": {
      a: "America/Toronto",
      c: ["CA"],
      r: 1,
    },
    "America/Montserrat": {
      a: "America/Puerto_Rico",
      c: ["MS"],
      r: 1,
    },
    "America/Nassau": {
      a: "America/Toronto",
      c: ["BS"],
      r: 1,
    },
    "America/New_York": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Nipigon": {
      u: -300,
      d: -240,
      c: ["CA"],
    },
    "America/Nome": {
      u: -540,
      d: -480,
      c: ["US"],
    },
    "America/Noronha": {
      u: -120,
      c: ["BR"],
    },
    "America/North_Dakota/Beulah": {
      u: -360,
      d: -300,
      c: ["US"],
    },
    "America/North_Dakota/Center": {
      u: -360,
      d: -300,
      c: ["US"],
    },
    "America/North_Dakota/New_Salem": {
      u: -360,
      d: -300,
      c: ["US"],
    },
    "America/Nuuk": {
      u: -180,
      d: -120,
      c: ["GL"],
    },
    "America/Ojinaga": {
      u: -420,
      d: -360,
      c: ["MX"],
    },
    "America/Panama": {
      u: -300,
      c: ["PA", "CA", "KY"],
    },
    "America/Pangnirtung": {
      u: -300,
      d: -240,
      c: ["CA"],
    },
    "America/Paramaribo": {
      u: -180,
      c: ["SR"],
    },
    "America/Phoenix": {
      u: -420,
      c: ["US", "CA"],
    },
    "America/Port-au-Prince": {
      u: -300,
      d: -240,
      c: ["HT"],
    },
    "America/Port_of_Spain": {
      a: "America/Puerto_Rico",
      c: ["TT"],
      r: 1,
    },
    "America/Porto_Acre": {
      a: "America/Rio_Branco",
      r: 1,
    },
    "America/Porto_Velho": {
      u: -240,
      c: ["BR"],
    },
    "America/Puerto_Rico": {
      u: -240,
      c: [
        "PR",
        "AG",
        "CA",
        "AI",
        "AW",
        "BL",
        "BQ",
        "CW",
        "DM",
        "GD",
        "GP",
        "KN",
        "LC",
        "MF",
        "MS",
        "SX",
        "TT",
        "VC",
        "VG",
        "VI",
      ],
    },
    "America/Punta_Arenas": {
      u: -180,
      c: ["CL"],
    },
    "America/Rainy_River": {
      u: -360,
      d: -300,
      c: ["CA"],
    },
    "America/Rankin_Inlet": {
      u: -360,
      d: -300,
      c: ["CA"],
    },
    "America/Recife": {
      u: -180,
      c: ["BR"],
    },
    "America/Regina": {
      u: -360,
      c: ["CA"],
    },
    "America/Resolute": {
      u: -360,
      d: -300,
      c: ["CA"],
    },
    "America/Rio_Branco": {
      u: -300,
      c: ["BR"],
    },
    "America/Rosario": {
      a: "America/Argentina/Cordoba",
      r: 1,
    },
    "America/Santa_Isabel": {
      a: "America/Tijuana",
      r: 1,
    },
    "America/Santarem": {
      u: -180,
      c: ["BR"],
    },
    "America/Santiago": {
      u: -240,
      d: -180,
      c: ["CL"],
    },
    "America/Santo_Domingo": {
      u: -240,
      c: ["DO"],
    },
    "America/Sao_Paulo": {
      u: -180,
      c: ["BR"],
    },
    "America/Scoresbysund": {
      u: -60,
      d: 0,
      c: ["GL"],
    },
    "America/Shiprock": {
      a: "America/Denver",
      r: 1,
    },
    "America/Sitka": {
      u: -540,
      d: -480,
      c: ["US"],
    },
    "America/St_Barthelemy": {
      a: "America/Puerto_Rico",
      c: ["BL"],
      r: 1,
    },
    "America/St_Johns": {
      u: -150,
      d: -90,
      c: ["CA"],
    },
    "America/St_Kitts": {
      a: "America/Puerto_Rico",
      c: ["KN"],
      r: 1,
    },
    "America/St_Lucia": {
      a: "America/Puerto_Rico",
      c: ["LC"],
      r: 1,
    },
    "America/St_Thomas": {
      a: "America/Puerto_Rico",
      c: ["VI"],
      r: 1,
    },
    "America/St_Vincent": {
      a: "America/Puerto_Rico",
      c: ["VC"],
      r: 1,
    },
    "America/Swift_Current": {
      u: -360,
      c: ["CA"],
    },
    "America/Tegucigalpa": {
      u: -360,
      c: ["HN"],
    },
    "America/Thule": {
      u: -240,
      d: -180,
      c: ["GL"],
    },
    "America/Thunder_Bay": {
      u: -300,
      d: -240,
      c: ["CA"],
    },
    "America/Tijuana": {
      u: -480,
      d: -420,
      c: ["MX"],
    },
    "America/Toronto": {
      u: -300,
      d: -240,
      c: ["CA", "BS"],
    },
    "America/Tortola": {
      a: "America/Puerto_Rico",
      c: ["VG"],
      r: 1,
    },
    "America/Vancouver": {
      u: -480,
      d: -420,
      c: ["CA"],
    },
    "America/Virgin": {
      a: "America/Puerto_Rico",
      c: ["VI"],
      r: 1,
    },
    "America/Whitehorse": {
      u: -420,
      c: ["CA"],
    },
    "America/Winnipeg": {
      u: -360,
      d: -300,
      c: ["CA"],
    },
    "America/Yakutat": {
      u: -540,
      d: -480,
      c: ["US"],
    },
    "America/Yellowknife": {
      u: -420,
      d: -360,
      c: ["CA"],
    },
    "Antarctica/Casey": {
      u: 660,
      c: ["AQ"],
    },
    "Antarctica/Davis": {
      u: 420,
      c: ["AQ"],
    },
    "Antarctica/DumontDUrville": {
      a: "Pacific/Port_Moresby",
      c: ["AQ"],
      r: 1,
    },
    "Antarctica/Macquarie": {
      u: 600,
      d: 660,
      c: ["AU"],
    },
    "Antarctica/Mawson": {
      u: 300,
      c: ["AQ"],
    },
    "Antarctica/McMurdo": {
      a: "Pacific/Auckland",
      c: ["AQ"],
      r: 1,
    },
    "Antarctica/Palmer": {
      u: -180,
      c: ["AQ"],
    },
    "Antarctica/Rothera": {
      u: -180,
      c: ["AQ"],
    },
    "Antarctica/South_Pole": {
      a: "Pacific/Auckland",
      c: ["AQ"],
      r: 1,
    },
    "Antarctica/Syowa": {
      a: "Asia/Riyadh",
      c: ["AQ"],
      r: 1,
    },
    "Antarctica/Troll": {
      u: 0,
      d: 120,
      c: ["AQ"],
    },
    "Antarctica/Vostok": {
      u: 360,
      c: ["AQ"],
    },
    "Arctic/Longyearbyen": {
      a: "Europe/Oslo",
      c: ["SJ"],
      r: 1,
    },
    "Asia/Aden": {
      a: "Asia/Riyadh",
      c: ["YE"],
      r: 1,
    },
    "Asia/Almaty": {
      u: 360,
      c: ["KZ"],
    },
    "Asia/Amman": {
      u: 120,
      d: 180,
      c: ["JO"],
    },
    "Asia/Anadyr": {
      u: 720,
      c: ["RU"],
    },
    "Asia/Aqtau": {
      u: 300,
      c: ["KZ"],
    },
    "Asia/Aqtobe": {
      u: 300,
      c: ["KZ"],
    },
    "Asia/Ashgabat": {
      u: 300,
      c: ["TM"],
    },
    "Asia/Ashkhabad": {
      a: "Asia/Ashgabat",
      r: 1,
    },
    "Asia/Atyrau": {
      u: 300,
      c: ["KZ"],
    },
    "Asia/Baghdad": {
      u: 180,
      c: ["IQ"],
    },
    "Asia/Bahrain": {
      a: "Asia/Qatar",
      c: ["BH"],
      r: 1,
    },
    "Asia/Baku": {
      u: 240,
      c: ["AZ"],
    },
    "Asia/Bangkok": {
      u: 420,
      c: ["TH", "KH", "LA", "VN"],
    },
    "Asia/Barnaul": {
      u: 420,
      c: ["RU"],
    },
    "Asia/Beirut": {
      u: 120,
      d: 180,
      c: ["LB"],
    },
    "Asia/Bishkek": {
      u: 360,
      c: ["KG"],
    },
    "Asia/Brunei": {
      u: 480,
      c: ["BN"],
    },
    "Asia/Calcutta": {
      a: "Asia/Kolkata",
      r: 1,
    },
    "Asia/Chita": {
      u: 540,
      c: ["RU"],
    },
    "Asia/Choibalsan": {
      u: 480,
      c: ["MN"],
    },
    "Asia/Chongqing": {
      a: "Asia/Shanghai",
      r: 1,
    },
    "Asia/Chungking": {
      a: "Asia/Shanghai",
      r: 1,
    },
    "Asia/Colombo": {
      u: 330,
      c: ["LK"],
    },
    "Asia/Dacca": {
      a: "Asia/Dhaka",
      r: 1,
    },
    "Asia/Damascus": {
      u: 120,
      d: 180,
      c: ["SY"],
    },
    "Asia/Dhaka": {
      u: 360,
      c: ["BD"],
    },
    "Asia/Dili": {
      u: 540,
      c: ["TL"],
    },
    "Asia/Dubai": {
      u: 240,
      c: ["AE", "OM"],
    },
    "Asia/Dushanbe": {
      u: 300,
      c: ["TJ"],
    },
    "Asia/Famagusta": {
      u: 120,
      d: 180,
      c: ["CY"],
    },
    "Asia/Gaza": {
      u: 120,
      d: 180,
      c: ["PS"],
    },
    "Asia/Harbin": {
      a: "Asia/Shanghai",
      r: 1,
    },
    "Asia/Hebron": {
      u: 120,
      d: 180,
      c: ["PS"],
    },
    "Asia/Ho_Chi_Minh": {
      u: 420,
      c: ["VN"],
    },
    "Asia/Hong_Kong": {
      u: 480,
      c: ["HK"],
    },
    "Asia/Hovd": {
      u: 420,
      c: ["MN"],
    },
    "Asia/Irkutsk": {
      u: 480,
      c: ["RU"],
    },
    "Asia/Istanbul": {
      a: "Europe/Istanbul",
      r: 1,
    },
    "Asia/Jakarta": {
      u: 420,
      c: ["ID"],
    },
    "Asia/Jayapura": {
      u: 540,
      c: ["ID"],
    },
    "Asia/Jerusalem": {
      u: 120,
      d: 180,
      c: ["IL"],
    },
    "Asia/Kabul": {
      u: 270,
      c: ["AF"],
    },
    "Asia/Kamchatka": {
      u: 720,
      c: ["RU"],
    },
    "Asia/Karachi": {
      u: 300,
      c: ["PK"],
    },
    "Asia/Kashgar": {
      a: "Asia/Urumqi",
      r: 1,
    },
    "Asia/Kathmandu": {
      u: 345,
      c: ["NP"],
    },
    "Asia/Katmandu": {
      a: "Asia/Kathmandu",
      r: 1,
    },
    "Asia/Khandyga": {
      u: 540,
      c: ["RU"],
    },
    "Asia/Kolkata": {
      u: 330,
      c: ["IN"],
    },
    "Asia/Krasnoyarsk": {
      u: 420,
      c: ["RU"],
    },
    "Asia/Kuala_Lumpur": {
      u: 480,
      c: ["MY"],
    },
    "Asia/Kuching": {
      u: 480,
      c: ["MY"],
    },
    "Asia/Kuwait": {
      a: "Asia/Riyadh",
      c: ["KW"],
      r: 1,
    },
    "Asia/Macao": {
      a: "Asia/Macau",
      r: 1,
    },
    "Asia/Macau": {
      u: 480,
      c: ["MO"],
    },
    "Asia/Magadan": {
      u: 660,
      c: ["RU"],
    },
    "Asia/Makassar": {
      u: 480,
      c: ["ID"],
    },
    "Asia/Manila": {
      u: 480,
      c: ["PH"],
    },
    "Asia/Muscat": {
      a: "Asia/Dubai",
      c: ["OM"],
      r: 1,
    },
    "Asia/Nicosia": {
      u: 120,
      d: 180,
      c: ["CY"],
    },
    "Asia/Novokuznetsk": {
      u: 420,
      c: ["RU"],
    },
    "Asia/Novosibirsk": {
      u: 420,
      c: ["RU"],
    },
    "Asia/Omsk": {
      u: 360,
      c: ["RU"],
    },
    "Asia/Oral": {
      u: 300,
      c: ["KZ"],
    },
    "Asia/Phnom_Penh": {
      a: "Asia/Bangkok",
      c: ["KH"],
      r: 1,
    },
    "Asia/Pontianak": {
      u: 420,
      c: ["ID"],
    },
    "Asia/Pyongyang": {
      u: 540,
      c: ["KP"],
    },
    "Asia/Qatar": {
      u: 180,
      c: ["QA", "BH"],
    },
    "Asia/Qostanay": {
      u: 360,
      c: ["KZ"],
    },
    "Asia/Qyzylorda": {
      u: 300,
      c: ["KZ"],
    },
    "Asia/Rangoon": {
      a: "Asia/Yangon",
      r: 1,
    },
    "Asia/Riyadh": {
      u: 180,
      c: ["SA", "AQ", "KW", "YE"],
    },
    "Asia/Saigon": {
      a: "Asia/Ho_Chi_Minh",
      r: 1,
    },
    "Asia/Sakhalin": {
      u: 660,
      c: ["RU"],
    },
    "Asia/Samarkand": {
      u: 300,
      c: ["UZ"],
    },
    "Asia/Seoul": {
      u: 540,
      c: ["KR"],
    },
    "Asia/Shanghai": {
      u: 480,
      c: ["CN"],
    },
    "Asia/Singapore": {
      u: 480,
      c: ["SG", "MY"],
    },
    "Asia/Srednekolymsk": {
      u: 660,
      c: ["RU"],
    },
    "Asia/Taipei": {
      u: 480,
      c: ["TW"],
    },
    "Asia/Tashkent": {
      u: 300,
      c: ["UZ"],
    },
    "Asia/Tbilisi": {
      u: 240,
      c: ["GE"],
    },
    "Asia/Tehran": {
      u: 210,
      d: 270,
      c: ["IR"],
    },
    "Asia/Tel_Aviv": {
      a: "Asia/Jerusalem",
      r: 1,
    },
    "Asia/Thimbu": {
      a: "Asia/Thimphu",
      r: 1,
    },
    "Asia/Thimphu": {
      u: 360,
      c: ["BT"],
    },
    "Asia/Tokyo": {
      u: 540,
      c: ["JP"],
    },
    "Asia/Tomsk": {
      u: 420,
      c: ["RU"],
    },
    "Asia/Ujung_Pandang": {
      a: "Asia/Makassar",
      r: 1,
    },
    "Asia/Ulaanbaatar": {
      u: 480,
      c: ["MN"],
    },
    "Asia/Ulan_Bator": {
      a: "Asia/Ulaanbaatar",
      r: 1,
    },
    "Asia/Urumqi": {
      u: 360,
      c: ["CN"],
    },
    "Asia/Ust-Nera": {
      u: 600,
      c: ["RU"],
    },
    "Asia/Vientiane": {
      a: "Asia/Bangkok",
      c: ["LA"],
      r: 1,
    },
    "Asia/Vladivostok": {
      u: 600,
      c: ["RU"],
    },
    "Asia/Yakutsk": {
      u: 540,
      c: ["RU"],
    },
    "Asia/Yangon": {
      u: 390,
      c: ["MM"],
    },
    "Asia/Yekaterinburg": {
      u: 300,
      c: ["RU"],
    },
    "Asia/Yerevan": {
      u: 240,
      c: ["AM"],
    },
    "Atlantic/Azores": {
      u: -60,
      d: 0,
      c: ["PT"],
    },
    "Atlantic/Bermuda": {
      u: -240,
      d: -180,
      c: ["BM"],
    },
    "Atlantic/Canary": {
      u: 0,
      d: 60,
      c: ["ES"],
    },
    "Atlantic/Cape_Verde": {
      u: -60,
      c: ["CV"],
    },
    "Atlantic/Faeroe": {
      a: "Atlantic/Faroe",
      r: 1,
    },
    "Atlantic/Faroe": {
      u: 0,
      d: 60,
      c: ["FO"],
    },
    "Atlantic/Jan_Mayen": {
      a: "Europe/Oslo",
      c: ["SJ"],
      r: 1,
    },
    "Atlantic/Madeira": {
      u: 0,
      d: 60,
      c: ["PT"],
    },
    "Atlantic/Reykjavik": {
      u: 0,
      c: ["IS"],
    },
    "Atlantic/South_Georgia": {
      u: -120,
      c: ["GS"],
    },
    "Atlantic/St_Helena": {
      a: "Africa/Abidjan",
      c: ["SH"],
      r: 1,
    },
    "Atlantic/Stanley": {
      u: -180,
      c: ["FK"],
    },
    "Australia/ACT": {
      a: "Australia/Sydney",
      r: 1,
    },
    "Australia/Adelaide": {
      u: 570,
      d: 630,
      c: ["AU"],
    },
    "Australia/Brisbane": {
      u: 600,
      c: ["AU"],
    },
    "Australia/Broken_Hill": {
      u: 570,
      d: 630,
      c: ["AU"],
    },
    "Australia/Canberra": {
      a: "Australia/Sydney",
      r: 1,
    },
    "Australia/Currie": {
      a: "Australia/Hobart",
      r: 1,
    },
    "Australia/Darwin": {
      u: 570,
      c: ["AU"],
    },
    "Australia/Eucla": {
      u: 525,
      c: ["AU"],
    },
    "Australia/Hobart": {
      u: 600,
      d: 660,
      c: ["AU"],
    },
    "Australia/LHI": {
      a: "Australia/Lord_Howe",
      r: 1,
    },
    "Australia/Lindeman": {
      u: 600,
      c: ["AU"],
    },
    "Australia/Lord_Howe": {
      u: 630,
      d: 660,
      c: ["AU"],
    },
    "Australia/Melbourne": {
      u: 600,
      d: 660,
      c: ["AU"],
    },
    "Australia/NSW": {
      a: "Australia/Sydney",
      r: 1,
    },
    "Australia/North": {
      a: "Australia/Darwin",
      r: 1,
    },
    "Australia/Perth": {
      u: 480,
      c: ["AU"],
    },
    "Australia/Queensland": {
      a: "Australia/Brisbane",
      r: 1,
    },
    "Australia/South": {
      a: "Australia/Adelaide",
      r: 1,
    },
    "Australia/Sydney": {
      u: 600,
      d: 660,
      c: ["AU"],
    },
    "Australia/Tasmania": {
      a: "Australia/Hobart",
      r: 1,
    },
    "Australia/Victoria": {
      a: "Australia/Melbourne",
      r: 1,
    },
    "Australia/West": {
      a: "Australia/Perth",
      r: 1,
    },
    "Australia/Yancowinna": {
      a: "Australia/Broken_Hill",
      r: 1,
    },
    "Brazil/Acre": {
      a: "America/Rio_Branco",
      r: 1,
    },
    "Brazil/DeNoronha": {
      a: "America/Noronha",
      r: 1,
    },
    "Brazil/East": {
      a: "America/Sao_Paulo",
      r: 1,
    },
    "Brazil/West": {
      a: "America/Manaus",
      r: 1,
    },
    CET: {
      u: 60,
      d: 120,
    },
    CST6CDT: {
      u: -360,
      d: -300,
    },
    "Canada/Atlantic": {
      a: "America/Halifax",
      r: 1,
    },
    "Canada/Central": {
      a: "America/Winnipeg",
      r: 1,
    },
    "Canada/Eastern": {
      a: "America/Toronto",
      c: ["CA"],
      r: 1,
    },
    "Canada/Mountain": {
      a: "America/Edmonton",
      r: 1,
    },
    "Canada/Newfoundland": {
      a: "America/St_Johns",
      r: 1,
    },
    "Canada/Pacific": {
      a: "America/Vancouver",
      r: 1,
    },
    "Canada/Saskatchewan": {
      a: "America/Regina",
      r: 1,
    },
    "Canada/Yukon": {
      a: "America/Whitehorse",
      r: 1,
    },
    "Chile/Continental": {
      a: "America/Santiago",
      r: 1,
    },
    "Chile/EasterIsland": {
      a: "Pacific/Easter",
      r: 1,
    },
    Cuba: {
      a: "America/Havana",
      r: 1,
    },
    EET: {
      u: 120,
      d: 180,
    },
    EST: {
      u: -300,
    },
    EST5EDT: {
      u: -300,
      d: -240,
    },
    Egypt: {
      a: "Africa/Cairo",
      r: 1,
    },
    Eire: {
      a: "Europe/Dublin",
      r: 1,
    },
    "Etc/GMT": {
      u: 0,
    },
    "Etc/GMT+0": {
      a: "Etc/GMT",
      r: 1,
    },
    "Etc/GMT+1": {
      u: -60,
    },
    "Etc/GMT+10": {
      u: -600,
    },
    "Etc/GMT+11": {
      u: -660,
    },
    "Etc/GMT+12": {
      u: -720,
    },
    "Etc/GMT+2": {
      u: -120,
    },
    "Etc/GMT+3": {
      u: -180,
    },
    "Etc/GMT+4": {
      u: -240,
    },
    "Etc/GMT+5": {
      u: -300,
    },
    "Etc/GMT+6": {
      u: -360,
    },
    "Etc/GMT+7": {
      u: -420,
    },
    "Etc/GMT+8": {
      u: -480,
    },
    "Etc/GMT+9": {
      u: -540,
    },
    "Etc/GMT-0": {
      a: "Etc/GMT",
      r: 1,
    },
    "Etc/GMT-1": {
      u: 60,
    },
    "Etc/GMT-10": {
      u: 600,
    },
    "Etc/GMT-11": {
      u: 660,
    },
    "Etc/GMT-12": {
      u: 720,
    },
    "Etc/GMT-13": {
      u: 780,
    },
    "Etc/GMT-14": {
      u: 840,
    },
    "Etc/GMT-2": {
      u: 120,
    },
    "Etc/GMT-3": {
      u: 180,
    },
    "Etc/GMT-4": {
      u: 240,
    },
    "Etc/GMT-5": {
      u: 300,
    },
    "Etc/GMT-6": {
      u: 360,
    },
    "Etc/GMT-7": {
      u: 420,
    },
    "Etc/GMT-8": {
      u: 480,
    },
    "Etc/GMT-9": {
      u: 540,
    },
    "Etc/GMT0": {
      a: "Etc/GMT",
      r: 1,
    },
    "Etc/Greenwich": {
      a: "Etc/GMT",
      r: 1,
    },
    "Etc/UCT": {
      a: "Etc/UTC",
      r: 1,
    },
    "Etc/UTC": {
      u: 0,
    },
    "Etc/Universal": {
      a: "Etc/UTC",
      r: 1,
    },
    "Etc/Zulu": {
      a: "Etc/UTC",
      r: 1,
    },
    "Europe/Amsterdam": {
      u: 60,
      d: 120,
      c: ["NL"],
    },
    "Europe/Andorra": {
      u: 60,
      d: 120,
      c: ["AD"],
    },
    "Europe/Astrakhan": {
      u: 240,
      c: ["RU"],
    },
    "Europe/Athens": {
      u: 120,
      d: 180,
      c: ["GR"],
    },
    "Europe/Belfast": {
      a: "Europe/London",
      c: ["GB"],
      r: 1,
    },
    "Europe/Belgrade": {
      u: 60,
      d: 120,
      c: ["RS", "BA", "HR", "ME", "MK", "SI"],
    },
    "Europe/Berlin": {
      u: 60,
      d: 120,
      c: ["DE"],
    },
    "Europe/Bratislava": {
      a: "Europe/Prague",
      c: ["SK"],
      r: 1,
    },
    "Europe/Brussels": {
      u: 60,
      d: 120,
      c: ["BE"],
    },
    "Europe/Bucharest": {
      u: 120,
      d: 180,
      c: ["RO"],
    },
    "Europe/Budapest": {
      u: 60,
      d: 120,
      c: ["HU"],
    },
    "Europe/Busingen": {
      a: "Europe/Zurich",
      c: ["DE"],
      r: 1,
    },
    "Europe/Chisinau": {
      u: 120,
      d: 180,
      c: ["MD"],
    },
    "Europe/Copenhagen": {
      u: 60,
      d: 120,
      c: ["DK"],
    },
    "Europe/Dublin": {
      u: 60,
      d: 0,
      c: ["IE"],
    },
    "Europe/Gibraltar": {
      u: 60,
      d: 120,
      c: ["GI"],
    },
    "Europe/Guernsey": {
      a: "Europe/London",
      c: ["GG"],
      r: 1,
    },
    "Europe/Helsinki": {
      u: 120,
      d: 180,
      c: ["FI", "AX"],
    },
    "Europe/Isle_of_Man": {
      a: "Europe/London",
      c: ["IM"],
      r: 1,
    },
    "Europe/Istanbul": {
      u: 180,
      c: ["TR"],
    },
    "Europe/Jersey": {
      a: "Europe/London",
      c: ["JE"],
      r: 1,
    },
    "Europe/Kaliningrad": {
      u: 120,
      c: ["RU"],
    },
    "Europe/Kiev": {
      u: 120,
      d: 180,
      c: ["UA"],
    },
    "Europe/Kirov": {
      u: 180,
      c: ["RU"],
    },
    "Europe/Lisbon": {
      u: 0,
      d: 60,
      c: ["PT"],
    },
    "Europe/Ljubljana": {
      a: "Europe/Belgrade",
      c: ["SI"],
      r: 1,
    },
    "Europe/London": {
      u: 0,
      d: 60,
      c: ["GB", "GG", "IM", "JE"],
    },
    "Europe/Luxembourg": {
      u: 60,
      d: 120,
      c: ["LU"],
    },
    "Europe/Madrid": {
      u: 60,
      d: 120,
      c: ["ES"],
    },
    "Europe/Malta": {
      u: 60,
      d: 120,
      c: ["MT"],
    },
    "Europe/Mariehamn": {
      a: "Europe/Helsinki",
      c: ["AX"],
      r: 1,
    },
    "Europe/Minsk": {
      u: 180,
      c: ["BY"],
    },
    "Europe/Monaco": {
      u: 60,
      d: 120,
      c: ["MC"],
    },
    "Europe/Moscow": {
      u: 180,
      c: ["RU"],
    },
    "Europe/Nicosia": {
      a: "Asia/Nicosia",
      r: 1,
    },
    "Europe/Oslo": {
      u: 60,
      d: 120,
      c: ["NO", "SJ", "BV"],
    },
    "Europe/Paris": {
      u: 60,
      d: 120,
      c: ["FR"],
    },
    "Europe/Podgorica": {
      a: "Europe/Belgrade",
      c: ["ME"],
      r: 1,
    },
    "Europe/Prague": {
      u: 60,
      d: 120,
      c: ["CZ", "SK"],
    },
    "Europe/Riga": {
      u: 120,
      d: 180,
      c: ["LV"],
    },
    "Europe/Rome": {
      u: 60,
      d: 120,
      c: ["IT", "SM", "VA"],
    },
    "Europe/Samara": {
      u: 240,
      c: ["RU"],
    },
    "Europe/San_Marino": {
      a: "Europe/Rome",
      c: ["SM"],
      r: 1,
    },
    "Europe/Sarajevo": {
      a: "Europe/Belgrade",
      c: ["BA"],
      r: 1,
    },
    "Europe/Saratov": {
      u: 240,
      c: ["RU"],
    },
    "Europe/Simferopol": {
      u: 180,
      c: ["RU", "UA"],
    },
    "Europe/Skopje": {
      a: "Europe/Belgrade",
      c: ["MK"],
      r: 1,
    },
    "Europe/Sofia": {
      u: 120,
      d: 180,
      c: ["BG"],
    },
    "Europe/Stockholm": {
      u: 60,
      d: 120,
      c: ["SE"],
    },
    "Europe/Tallinn": {
      u: 120,
      d: 180,
      c: ["EE"],
    },
    "Europe/Tirane": {
      u: 60,
      d: 120,
      c: ["AL"],
    },
    "Europe/Tiraspol": {
      a: "Europe/Chisinau",
      r: 1,
    },
    "Europe/Ulyanovsk": {
      u: 240,
      c: ["RU"],
    },
    "Europe/Uzhgorod": {
      u: 120,
      d: 180,
      c: ["UA"],
    },
    "Europe/Vaduz": {
      a: "Europe/Zurich",
      c: ["LI"],
      r: 1,
    },
    "Europe/Vatican": {
      a: "Europe/Rome",
      c: ["VA"],
      r: 1,
    },
    "Europe/Vienna": {
      u: 60,
      d: 120,
      c: ["AT"],
    },
    "Europe/Vilnius": {
      u: 120,
      d: 180,
      c: ["LT"],
    },
    "Europe/Volgograd": {
      u: 180,
      c: ["RU"],
    },
    "Europe/Warsaw": {
      u: 60,
      d: 120,
      c: ["PL"],
    },
    "Europe/Zagreb": {
      a: "Europe/Belgrade",
      c: ["HR"],
      r: 1,
    },
    "Europe/Zaporozhye": {
      u: 120,
      d: 180,
      c: ["UA"],
    },
    "Europe/Zurich": {
      u: 60,
      d: 120,
      c: ["CH", "DE", "LI"],
    },
    Factory: {
      u: 0,
    },
    GB: {
      a: "Europe/London",
      c: ["GB"],
      r: 1,
    },
    "GB-Eire": {
      a: "Europe/London",
      c: ["GB"],
      r: 1,
    },
    GMT: {
      a: "Etc/GMT",
      r: 1,
    },
    "GMT+0": {
      a: "Etc/GMT",
      r: 1,
    },
    "GMT-0": {
      a: "Etc/GMT",
      r: 1,
    },
    GMT0: {
      a: "Etc/GMT",
      r: 1,
    },
    Greenwich: {
      a: "Etc/GMT",
      r: 1,
    },
    HST: {
      u: -600,
    },
    Hongkong: {
      a: "Asia/Hong_Kong",
      r: 1,
    },
    Iceland: {
      a: "Atlantic/Reykjavik",
      r: 1,
    },
    "Indian/Antananarivo": {
      a: "Africa/Nairobi",
      c: ["MG"],
      r: 1,
    },
    "Indian/Chagos": {
      u: 360,
      c: ["IO"],
    },
    "Indian/Christmas": {
      u: 420,
      c: ["CX"],
    },
    "Indian/Cocos": {
      u: 390,
      c: ["CC"],
    },
    "Indian/Comoro": {
      a: "Africa/Nairobi",
      c: ["KM"],
      r: 1,
    },
    "Indian/Kerguelen": {
      u: 300,
      c: ["TF", "HM"],
    },
    "Indian/Mahe": {
      u: 240,
      c: ["SC"],
    },
    "Indian/Maldives": {
      u: 300,
      c: ["MV"],
    },
    "Indian/Mauritius": {
      u: 240,
      c: ["MU"],
    },
    "Indian/Mayotte": {
      a: "Africa/Nairobi",
      c: ["YT"],
      r: 1,
    },
    "Indian/Reunion": {
      u: 240,
      c: ["RE", "TF"],
    },
    Iran: {
      a: "Asia/Tehran",
      r: 1,
    },
    Israel: {
      a: "Asia/Jerusalem",
      r: 1,
    },
    Jamaica: {
      a: "America/Jamaica",
      r: 1,
    },
    Japan: {
      a: "Asia/Tokyo",
      r: 1,
    },
    Kwajalein: {
      a: "Pacific/Kwajalein",
      r: 1,
    },
    Libya: {
      a: "Africa/Tripoli",
      r: 1,
    },
    MET: {
      u: 60,
      d: 120,
    },
    MST: {
      u: -420,
    },
    MST7MDT: {
      u: -420,
      d: -360,
    },
    "Mexico/BajaNorte": {
      a: "America/Tijuana",
      r: 1,
    },
    "Mexico/BajaSur": {
      a: "America/Mazatlan",
      r: 1,
    },
    "Mexico/General": {
      a: "America/Mexico_City",
      r: 1,
    },
    NZ: {
      a: "Pacific/Auckland",
      c: ["NZ"],
      r: 1,
    },
    "NZ-CHAT": {
      a: "Pacific/Chatham",
      r: 1,
    },
    Navajo: {
      a: "America/Denver",
      r: 1,
    },
    PRC: {
      a: "Asia/Shanghai",
      r: 1,
    },
    PST8PDT: {
      u: -480,
      d: -420,
    },
    "Pacific/Apia": {
      u: 780,
      c: ["WS"],
    },
    "Pacific/Auckland": {
      u: 720,
      d: 780,
      c: ["NZ", "AQ"],
    },
    "Pacific/Bougainville": {
      u: 660,
      c: ["PG"],
    },
    "Pacific/Chatham": {
      u: 765,
      d: 825,
      c: ["NZ"],
    },
    "Pacific/Chuuk": {
      u: 600,
      c: ["FM"],
    },
    "Pacific/Easter": {
      u: -360,
      d: -300,
      c: ["CL"],
    },
    "Pacific/Efate": {
      u: 660,
      c: ["VU"],
    },
    "Pacific/Enderbury": {
      a: "Pacific/Kanton",
      r: 1,
    },
    "Pacific/Fakaofo": {
      u: 780,
      c: ["TK"],
    },
    "Pacific/Fiji": {
      u: 720,
      d: 780,
      c: ["FJ"],
    },
    "Pacific/Funafuti": {
      u: 720,
      c: ["TV"],
    },
    "Pacific/Galapagos": {
      u: -360,
      c: ["EC"],
    },
    "Pacific/Gambier": {
      u: -540,
      c: ["PF"],
    },
    "Pacific/Guadalcanal": {
      u: 660,
      c: ["SB"],
    },
    "Pacific/Guam": {
      u: 600,
      c: ["GU", "MP"],
    },
    "Pacific/Honolulu": {
      u: -600,
      c: ["US", "UM"],
    },
    "Pacific/Johnston": {
      a: "Pacific/Honolulu",
      c: ["UM"],
      r: 1,
    },
    "Pacific/Kanton": {
      u: 780,
      c: ["KI"],
    },
    "Pacific/Kiritimati": {
      u: 840,
      c: ["KI"],
    },
    "Pacific/Kosrae": {
      u: 660,
      c: ["FM"],
    },
    "Pacific/Kwajalein": {
      u: 720,
      c: ["MH"],
    },
    "Pacific/Majuro": {
      u: 720,
      c: ["MH"],
    },
    "Pacific/Marquesas": {
      u: -510,
      c: ["PF"],
    },
    "Pacific/Midway": {
      a: "Pacific/Pago_Pago",
      c: ["UM"],
      r: 1,
    },
    "Pacific/Nauru": {
      u: 720,
      c: ["NR"],
    },
    "Pacific/Niue": {
      u: -660,
      c: ["NU"],
    },
    "Pacific/Norfolk": {
      u: 660,
      d: 720,
      c: ["NF"],
    },
    "Pacific/Noumea": {
      u: 660,
      c: ["NC"],
    },
    "Pacific/Pago_Pago": {
      u: -660,
      c: ["AS", "UM"],
    },
    "Pacific/Palau": {
      u: 540,
      c: ["PW"],
    },
    "Pacific/Pitcairn": {
      u: -480,
      c: ["PN"],
    },
    "Pacific/Pohnpei": {
      u: 660,
      c: ["FM"],
    },
    "Pacific/Ponape": {
      a: "Pacific/Pohnpei",
      r: 1,
    },
    "Pacific/Port_Moresby": {
      u: 600,
      c: ["PG", "AQ"],
    },
    "Pacific/Rarotonga": {
      u: -600,
      c: ["CK"],
    },
    "Pacific/Saipan": {
      a: "Pacific/Guam",
      c: ["MP"],
      r: 1,
    },
    "Pacific/Samoa": {
      a: "Pacific/Pago_Pago",
      c: ["WS"],
      r: 1,
    },
    "Pacific/Tahiti": {
      u: -600,
      c: ["PF"],
    },
    "Pacific/Tarawa": {
      u: 720,
      c: ["KI"],
    },
    "Pacific/Tongatapu": {
      u: 780,
      c: ["TO"],
    },
    "Pacific/Truk": {
      a: "Pacific/Chuuk",
      r: 1,
    },
    "Pacific/Wake": {
      u: 720,
      c: ["UM"],
    },
    "Pacific/Wallis": {
      u: 720,
      c: ["WF"],
    },
    "Pacific/Yap": {
      a: "Pacific/Chuuk",
      r: 1,
    },
    Poland: {
      a: "Europe/Warsaw",
      r: 1,
    },
    Portugal: {
      a: "Europe/Lisbon",
      r: 1,
    },
    ROC: {
      a: "Asia/Taipei",
      r: 1,
    },
    ROK: {
      a: "Asia/Seoul",
      r: 1,
    },
    Singapore: {
      a: "Asia/Singapore",
      c: ["SG"],
      r: 1,
    },
    Turkey: {
      a: "Europe/Istanbul",
      r: 1,
    },
    UCT: {
      a: "Etc/UTC",
      r: 1,
    },
    "US/Alaska": {
      a: "America/Anchorage",
      r: 1,
    },
    "US/Aleutian": {
      a: "America/Adak",
      r: 1,
    },
    "US/Arizona": {
      a: "America/Phoenix",
      c: ["US"],
      r: 1,
    },
    "US/Central": {
      a: "America/Chicago",
      r: 1,
    },
    "US/East-Indiana": {
      a: "America/Indiana/Indianapolis",
      r: 1,
    },
    "US/Eastern": {
      a: "America/New_York",
      r: 1,
    },
    "US/Hawaii": {
      a: "Pacific/Honolulu",
      c: ["US"],
      r: 1,
    },
    "US/Indiana-Starke": {
      a: "America/Indiana/Knox",
      r: 1,
    },
    "US/Michigan": {
      a: "America/Detroit",
      r: 1,
    },
    "US/Mountain": {
      a: "America/Denver",
      r: 1,
    },
    "US/Pacific": {
      a: "America/Los_Angeles",
      r: 1,
    },
    "US/Samoa": {
      a: "Pacific/Pago_Pago",
      c: ["WS"],
      r: 1,
    },
    UTC: {
      a: "Etc/UTC",
      r: 1,
    },
    Universal: {
      a: "Etc/UTC",
      r: 1,
    },
    "W-SU": {
      a: "Europe/Moscow",
      r: 1,
    },
    WET: {
      u: 0,
      d: 60,
    },
    Zulu: {
      a: "Etc/UTC",
      r: 1,
    },
  };

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return !c.dependencyMissing(timezone) ||
    c.dependencyMissing(timezones[timezone].c[0])
    ? c.toUpper(timezones[timezone].c[0])
    : undefined;
};

export const removeCartProduct = (_id) => {
  store.dispatch("setCartAttribute", {
    _id: _id,
    attribute: "term",
    value: undefined,
  });

  store.dispatch("setCartAttribute", {
    _id: _id,
    attribute: "quantity",
    value: 0,
  });

  store.dispatch("setCartAttribute", {
    _id: _id,
    attribute: "amount",
    value: undefined,
  });

  try {
    const field = document.getElementById("span_signup_product_" + _id);

    if (field) field.classList.remove("border-success");
  } catch (e) {
    console.error(e);
  }
};

export const checkByClass = (inputClass, checked = true) => {
  if (c.dependencyMissing(inputClass)) return;

  const items = document.getElementsByClassName(inputClass);

  if (c.arrayEmpty(items)) return;

  for (const i in items) items[i].checked = checked ? "checked" : "";
};

export const speak = (text) => {
  if (
    c.dependencyMissing((text = c.trimString(text))) ||
    typeof window.speechSynthesis !== "object" ||
    typeof store.getters.speakVoice !== "object"
  )
    return;

  try {
    if (c.dependencyMissing((text = text.replace(/[^a-z0-9!?@. ]/gi, ""))))
      return;

    const utterThis = new SpeechSynthesisUtterance(text);
    utterThis.voice = store.getters.speakVoice;

    window.speechSynthesis.speak(utterThis);
  } catch {
    return;
  }
};

export const setupPaypalCheckout = (
  cb,
  finalize = false,
  token = "",
  type = "",
  payerId = ""
) => {
  const finalizePaypal = (token) => {
    if (c.dependencyMissing(token)) return;

    store
      .dispatch("postObject", {
        service: "paymentmethods",
        object: {
          type: "paypal",
          token: token,
        },
      })
      .then((r) => {
        if (c.isFunction(cb)) cb(r);
      });
  };

  const addCredit = (token, payerId) => {
    if (c.dependencyMissing(token) || c.dependencyMissing(payerId)) return;
    store
      .dispatch("postObject", {
        service: "payments",
        object: {
          type: "addCredit",
          paymentMethod: "onetimepaypalfinalize",
          token,
          payerId,
        },
      })
      .then((r) => {
        if (c.isFunction(cb)) cb(r);
      })
      .catch((e) => {
        console.error("error", e);
      });
  };

  if (store.getters.isElectron)
    window.electronAPI.onPaypal((e, value) => {
      if (c.dependencyMissing(value)) return;

      const paypalError = () => {
        c.showError({ message: "Failed to add PayPal payment method." });
      };

      try {
        const hashParams = new URLSearchParams(value.split("?")[1]);

        const cancel = hashParams.get("cancel");

        const token = hashParams.get("token");
        const payerId = hashParams.get("payerId");
        const requestType = hashParams.get("requestType");

        if (c.dependencyMissing(token)) return paypalError();

        if (!c.dependencyMissing(token) && c.trimString(cancel) !== "true") {
          if (finalize) {
            if (requestType === "addcredit") addCredit(token, payerId);
            else finalizePaypal(token);
          } else if (c.isFunction(cb)) cb(token);
        }
      } catch {
        paypalError();
      }
    });
  else if (!c.dependencyMissing(token)) {
    if (type === "addcredit") addCredit(token, payerId);
    else finalizePaypal(token);
  }
};

export const getPaypalSetupUrl = (params = {}) => {
  return new Promise((resolve, reject) => {
    const object = {
      type: "paypalsetupurl",
      isDesktopApp: store.getters.isElectron,
    };

    if (!object.isDesktopApp && params.isSignUp) {
      object.cancelUrl = object.returnUrl = portalUrl + "/sign-up";
      object.cancelUrl += "?cancel=true";
    }

    store
      .dispatch("postObject", {
        service: "functions",
        object: object,
      })
      .then((r) => {
        if (c.arrayEmpty(r) || c.dependencyMissing(r.redirectUrl))
          return reject(c.errorMessages.processError);

        resolve(r.redirectUrl);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const cartStorageName = "cidcartdata";
export const clearCart = () => {
  try {
    localStorage.removeItem(cartStorageName);
  } catch (e) {
    console.error(e);
  }
};
export const getCart = () => {
  try {
    const cartData = localStorage.getItem(cartStorageName);

    return !c.dependencyMissing(cartData) ? JSON.parse(cartData) : {};
  } catch {
    return {};
  }
};

export const setBadgeCount = () => {
  let count = store.getters.chatroomUnreadMessages || 0;

  count += store.getters.conversationUnreadMessages || 0;

  try {
    if (
      store.getters.isElectron &&
      !c.arrayEmpty(window) &&
      !c.arrayEmpty(window.electronAPI) &&
      c.isFunction(window.electronAPI.setBadgeCount)
    )
      window.electronAPI.setBadgeCount(
        c.isPositiveNum(count) ? c.getInt(count) : undefined
      );
  } catch (e) {
    console.error(e);
  }
};

export const getDeveloperAppService = (name) => {
  if (c.dependencyMissing(name)) return;

  switch (name) {
    case "chatroom":
      return "chatmessages";
    case "conversation-chat":
      return "conversationmessages";
  }
};

export const getDeveloperAppRef = (name) => {
  if (c.dependencyMissing(name)) return;

  switch (name) {
    case "chatroom":
      return "chatrooms";
    case "conversation-chat":
      return "conversations";
  }
};

export const isElectronMinimalRoute = () => {
  for (const i in electronMinimalRoutes)
    if (
      window.location.href.includes(
        electronMinimalRoutes[i].replaceAll("-", "")
      )
    )
      return true;

  return false;
};

export const handleCall = (params) => {
  if (
    c.arrayEmpty(params) ||
    c.dependencyMissing((params.direction = c.toLower(params.direction))) ||
    (params.direction === "outbound" &&
      c.dependencyMissing(params.phoneNumber)) ||
    (params.direction === "inbound" && c.dependencyMissing(params.id))
  )
    return;

  const mediaStream = {
    audio: true,
    video:
      typeof params.video == "undefined" || !params.video
        ? false
        : params.video,
  };

  if (!store.getters.isElectron || store.getters.currentUser.embedCallManager) {
    if (!c.arrayEmpty(store.getters.phone.client))
      switch (params.direction) {
        case "inbound":
          if (!c.isFunction(store.getters.phone.client.answer)) return;

          store.getters.phone.client.answer(params.id, mediaStream);

          break;
        case "outbound":
          if (!c.isFunction(store.getters.phone.client.call)) return;

          store.getters.phone.client.call(params.phoneNumber, mediaStream);

          break;
      }
  } else window.electronAPI.openCallManager(params);
};

export const reload = () => {
  if (!store.getters.isElectron) window.location.reload();
  else window.electronAPI.reload();
};

export const setElectronTheme = (theme) => {
  if (store.getters.isElectron)
    // eslint-disable-next-line
    // @ts-ignore
    window.electronAPI.setTheme(theme);
};

export const getElectronWinArgs = () => {
  if (
    c.arrayEmpty(window) ||
    c.arrayEmpty(window.location) ||
    c.dependencyMissing(window.location.hash)
  )
    return {};

  try {
    return c.parseQueryString(
      window.location.hash.replace("#/phone/electroncallmanager?", "")
    );
  } catch (e) {
    console.error("getElectronWinArgs", e);
  }
};

export const setTheme = (mode) => {
  const commitTheme = (theme) => {
    // store.commit(Mutations.RESET_LAYOUT_CONFIG);
    setElectronTheme(theme);
    store.dispatch(Actions.SET_THEME_MODE_ACTION, theme);
  };

  try {
    if (!c.dependencyMissing(mode)) {
      commitTheme(mode);
      return;
    }

    let config = localStorage.getItem("config");

    if (c.dependencyMissing(config)) return;

    config = JSON.parse(config);

    if (
      !c.arrayEmpty(config) &&
      !c.arrayEmpty(config.aside) &&
      !c.dependencyMissing(config.aside.theme)
    )
      commitTheme(config.aside.theme);
  } catch (e) {
    console.error("setTheme", e);
    return;
  }
};

export const getListItemDate = (date) => {
  if (c.dependencyMissing(date)) return "";

  try {
    return c.moment()(date).isBefore(c.moment()().subtract("1", "day"))
      ? c.moment()(date).format("MM/DD")
      : c.moment()(date).format("h:mm A");
  } catch (e) {
    console.error("getListItemDate", e);
    return "";
  }
};

export const manageOrganization = (_id) => {
  return new Promise((resolve, reject) => {
    if (!c.isValidObjectId(_id))
      return reject(c.errorMessages.dependencyMissing);

    store
      .dispatch("postObject", {
        service: "accountauthentication",
        object: {
          accountId: _id,
        },
      })
      .then((response) => {
        if (
          c.arrayEmpty(response) ||
          c.arrayEmpty(response.customer) ||
          c.arrayEmpty(response.user) ||
          c.dependencyMissing(response.user._id) ||
          c.dependencyMissing(response.customer._id) ||
          c.dependencyMissing(response.accessToken)
        )
          return store.dispatch("alertPrompt", {
            type: "error",
            message: c.translate("Error Processing Request"),
          });

        response = c.formatUserAuth(response);

        store.dispatch("setTenantByAuth", {
          key: "tenant",
          object: response,
        });

        resolve(response);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const setCommon = (common) => {
  c = common;
};

export const setRouter = (r) => {
  router = r;
};

export const setRoute = (r) => {
  route = r;
};

export const setTranslate = (translate) => {
  t = translate;
};

export const confetti = () => {
  store.dispatch("setValue", {
    key: "confetti",
    value: true,
  });
  setTimeout(() => {
    store.dispatch("setValue", {
      key: "confetti",
      value: false,
    });
  }, 2000);
};

export const me = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch("findData", {
        service: "me",
        noSetData: true,
        noCache: true,
      })
      .then((r) => {
        store.dispatch("setValue", {
          key: "me",
          value: r,
        });
        resolve(r);
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const setSounds = () => {
  try {
    sounds.sendMessageAudio = getSound("sendMessage.mp3");
    sounds.receiveMessageAudio = getSound("receiveMessage.mp3");
  } catch (e) {
    console.error(e);
  }
};

export { router, route, sounds, store };

export const resetChatUnread = (d) => {
  if (
    c.arrayEmpty(d) ||
    !c.isValidObjectId(d._id) ||
    c.dependencyMissing(d.service)
  )
    return;

  const reset = () => {
    store.dispatch("patchServiceObjectAttribute", {
      service: d.service,
      attribute: "unread",
      _id: d._id,
      value: 0,
    });

    const room = store.getters.getObjectById({
      _id: d._id,
      service: d.service,
    });

    room.rightContent = getListItemDate(room.updatedAt);

    store.dispatch("patchArrayObject", {
      key: d.service,
      value: room,
    });
  };

  if (d.resetOnly) {
    reset();
    return;
  }

  store
    .dispatch("postObject", {
      service: "functions",
      object: {
        type: "readchatmessages",
        service: d.service,
        _id: d._id,
      },
    })
    .then(() => {
      reset();
    })
    .catch((e) => {
      console.error(e);
    });
};
export const cartInitWizard = (exposed) => {
  if (c.arrayEmpty(exposed) || !c.isFunction(exposed.initWizard)) return;

  setTimeout(function () {
    exposed.initWizard();
  }, 500);
};

export const cartSetHeaders = (exposed, headers) => {
  if (
    c.arrayEmpty(headers) ||
    c.arrayEmpty(exposed) ||
    c.arrayEmpty(exposed.headers)
  )
    return;

  exposed.headers.value = headers;
  cartInitWizard(exposed);
};

export const getCallQueueTypes = async () => {
  const callQueueTypes = [];
  const user = await me();

  if (!c.arrayEmpty(user.customerFeatures)) {
    if (user.customerFeatures.includes("fifocallqueues"))
      callQueueTypes.push({
        id: "fifo",
        name: "First-In, First-Out (FIFO)",
      });

    if (user.customerFeatures.includes("acdcallqueues"))
      callQueueTypes.push({
        id: "acd",
        name: "Automatic Call Distribution (ACD)",
      });
  }

  return callQueueTypes;
};

export const onActivity = (d) => {
  if (
    c.arrayEmpty(d) ||
    c.dependencyMissing(d.first) ||
    c.dependencyMissing(d.last) ||
    c.dependencyMissing(d.service) ||
    !c.isValidObjectId(d._id) ||
    !c.isValidObjectId(d.createdByUserId)
  )
    return;

  if (d.createdByUserId === store.getters.userId) return;

  let room,
    resetUserTimeout,
    user,
    name = d.first + " " + d.last + ", ";

  switch (d.service) {
    case "chatrooms":
    case "conversations":
      room = getRoom({
        _id: d._id,
        service: d.service,
      });

      if (c.arrayEmpty(room) || c.arrayEmpty(room.users)) return;

      resetUserTimeout = () => {
        removeUserTyping({
          createdByUserId: d.createdByUserId,
          _id: d._id,
          service: d.service,
        });
      };

      if (room.typingUsers.includes(d.createdByUserId)) {
        resetUserTimeout();
        return;
      }

      for (const _id of room.typingUsers) {
        user = c.getArrayItem("_id", _id, room.users);

        if (c.arrayEmpty(user)) continue;

        name += user.name + ", ";
      }

      store.dispatch("patchServiceObjectAttribute", {
        service: d.service,
        attribute: "typingUsers",
        _id: d._id,
        value: [...room.typingUsers, d.createdByUserId],
      });

      store.dispatch("patchServiceObjectAttribute", {
        service: d.service,
        attribute: "typingUsersById",
        _id: d._id,
        value: {
          ...room.typingUsersById,
          [d.createdByUserId]: new Date(),
        },
      });

      if (c.dependencyMissing(name)) return;

      name = c.rtrim(name, ", ");

      store.dispatch("setObjectAttribute", {
        key: "tempData",
        attribute: d._id + "subTitle",
        value: c.getMaxText(name, maxSidebarSubTitleLength - 8) + t("isTyping"),
      });

      resetUserTimeout();

      break;
    default:
      return;
  }
};

export const removeUserTyping = (d) => {
  const room = getRoom({
    _id: d._id,
    service: d.service,
  });

  if (c.arrayEmpty(room)) return;

  if (typingUsersDebounce[d.createdByUserId])
    clearTimeout(typingUsersDebounce[d.createdByUserId]);

  const remove = () => {
    delete room.typingUsersById[d.createdByUserId];

    const index = room.typingUsers.indexOf(d.createdByUserId);

    if (index !== -1) room.typingUsers.splice(index, 1);

    delete typingUsersDebounce[d.createdByUserId];

    if (c.arrayEmpty(typingUsersDebounce))
      store.dispatch("setObjectAttribute", {
        key: "tempData",
        attribute: d._id + "subTitle",
      });
  };

  if (d.immediate) remove();
  else
    typingUsersDebounce[d.createdByUserId] = setTimeout(
      () => {
        remove();
      },
      c.getInt(typingDebounceTime) + c.getInt(typingDebounceShowTime)
    );
};

export const getRoom = (d) => {
  if (
    c.arrayEmpty(d) ||
    c.dependencyMissing(d.service) ||
    !c.isValidObjectId(d._id)
  )
    return;

  const room = store.getters.getObjectById({
    _id: d._id,
    service: d.service,
  });

  if (c.arrayEmpty(room.typingUsers)) room.typingUsers = [];
  if (c.arrayEmpty(room.typingUsersById)) room.typingUsersById = {};

  return room;
};

export const checkMessage = (d) => {
  if (
    c.arrayEmpty(d) ||
    c.dependencyMissing(d.service) ||
    !c.isValidObjectId(d._id) ||
    !c.isValidObjectId(d.createdByUserId)
  )
    return;

  if (
    c.trimString(d.senderId) !== c.trimString(store.getters.currentUser._id)
  ) {
    if (c.trimString(d._id) === c.trimString(route.params.id))
      sounds.receiveMessageAudio.play();

    removeUserTyping({
      createdByUserId: d.createdByUserId,
      _id: d._id,
      service: d.service,
      immediate: true,
    });
  }
};

export const copy = async (d) => {
  try {
    await navigator.clipboard.writeText(d);

    store.dispatch("alert", {
      type: "info",
      message: "Copied!",
      timer: 1000,
      position: "center",
    });
  } catch (e) {
    console.error(e);
  }
};

export const getVar = (d) => {
  switch (c.trimString(d)) {
    case "t":
      return t;
    case "router":
      return router;
    case "store":
      return store;
    default:
      return;
  }
};

export const getMessageToDisplay = (d) => {
  if (c.arrayEmpty(d)) return "";

  let display = "";

  for (const number of d)
    display +=
      (c.isValidPhone(number) ? c.getFormattedPhoneNumber(number) : number) +
      ", ";

  return c.rtrim(display, ", ");
};

export const getAppSenderIdentity = (d) => {
  const unknown = "Unknown";

  if (c.arrayEmpty(d) || c.arrayEmpty(d.data)) return unknown;

  const number = () => {
    if (c.arrayEmpty(d.data.number) || c.dependencyMissing(d.data.number.name))
      return unknown;

    return c.getFormattedPhoneNumber(d.data.number.name);
  };

  switch (c.toUpper(d.data.channel)) {
    case "INSTAGRAM":
    case "LINE":
    case "MESSENGER":
    case "TELEGRAM":
      return d.data.accessToken;
    case "RCS":
    case "SMS":
      return number();
    case "WHATSAPP":
      return c.getFormattedPhoneNumber(d.data.name);
    default:
      return d.data.value;
  }
};

export const toggleSupportChat = (open = true) => {
  for (const plugin of plugins) {
    if (
      plugin.defaultSupportChat === true &&
      plugin.enabled === true &&
      !c.arrayEmpty(enabledPlugins[plugin.name]) &&
      ((open && c.isFunction(enabledPlugins[plugin.name].show)) ||
        (!open && c.isFunction(enabledPlugins[plugin.name].hide)))
    ) {
      if (open) enabledPlugins[plugin.name].show();
      else enabledPlugins[plugin.name].hide();
      return;
    }
  }
};

export const setPluginUser = (d) => {
  if (c.arrayEmpty(d) || c.arrayEmpty(d.user) || c.arrayEmpty(d.customer))
    return;

  for (const plugin of plugins) {
    if (
      plugin.enabled === true &&
      !c.arrayEmpty(enabledPlugins[plugin.name]) &&
      c.isFunction(enabledPlugins[plugin.name].setUser)
    ) {
      enabledPlugins[plugin.name].setUser(
        c.mergeObjects(d.user, { customer: d.customer })
      );
    }
  }
};

export const isMenuEnabled = (d) => {
  if (
    c.dependencyMissing(d) ||
    c.arrayEmpty(store.getters.info) ||
    c.arrayEmpty(store.getters.info.site) ||
    c.arrayEmpty(store.getters.info.site.sitemenus)
  )
    return false;

  for (const menu of store.getters.info.site.sitemenus)
    if (c.toLower(menu.key) === c.toLower(d)) {
      return true;
    }

  return false;
};

export const isMenuItemEnabled = (d) => {
  if (
    c.dependencyMissing(d) ||
    c.arrayEmpty(store.getters.info) ||
    c.arrayEmpty(store.getters.info.site) ||
    c.arrayEmpty(store.getters.info.site.sitemenus)
  )
    return false;

  for (const menu of store.getters.info.site.sitemenus)
    if (!c.arrayEmpty(menu.sitemenugroups))
      for (const group of menu.sitemenugroups)
        if (!c.arrayEmpty(group.sitemenupages))
          for (const sitepage of group.sitemenupages)
            if (!c.arrayEmpty(sitepage)) {
              if (
                !c.arrayEmpty(sitepage.page) &&
                !c.dependencyMissing(sitepage.page.routeName) &&
                c.toLower(sitepage.page.routeName) === c.toLower(d)
              ) {
                return true;
              }

              if (!c.arrayEmpty(sitepage.pages))
                for (const page of sitepage.pages) {
                  if (
                    !c.arrayEmpty(page) &&
                    !c.dependencyMissing(page.routeName)
                  )
                    if (c.toLower(page.routeName) === c.toLower(d)) {
                      return true;
                    }
                }
            }

  return false;
};

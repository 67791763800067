<template>
  <div :class="divClass">
    <div
      :class="!avatarClass ? ' symbol symbol-' + size + 'px symbol-circle' : ''"
      v-if="avatar"
    >
      <img :alt="name + ` Avatar`" :src="avatar" :class="avatarClass" />
    </div>
    <Avatar
      :background="background"
      color="white"
      :rounded="rounded"
      :size="size"
      :name="name"
      :imageSrc="avatar"
      :title="title || name"
      v-else
    />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Avatar from "vue3-avatar";

export default defineComponent({
  name: "avatar-component",
  components: { Avatar },
  props: {
    name: {
      type: String,
      required: true,
      default: function () {
        return "John Doe";
      },
    },
    divClass: {
      type: String,
      required: false,
      default: () => {
        return "";
      },
    },
    avatarClass: { type: String, required: false },
    avatar: { type: String, required: false },
    title: { type: String, required: false },
    background: { type: String, required: false },
    rounded: { type: Boolean, required: false, default: true },
    size: { type: Number, required: false, default: 40 },
  },
  setup(props) {
    if (props.name === "+1 (323) 652-4701") console.log("props", props);
  },
});
</script>
<style lang="scss">
.avatar {
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}
</style>

import { createI18n } from "vue-i18n";

const messages = {
  en: {
    status: "Status",
    name: "Name",
    type: "Type",
    save: "Save",
    credit: "Credit",
    faxCredit: "Fax Credit",
    schedule: "Schedule",
    dashboard: "Dashboard",
    provider: "Provider",
    violations: "Violations",
    created: "Created",
    signup: "Sign Up",
    countryLikeNumbersFrom: "What country would you like your numbers from?",
    city: "City",
    country: "Country",
    urls: "URLs",
    defaultEmailTemplate: "Default Email Template",
    template: "Template",
    noReplyFromEmail: "No Reply From Email",
    templates: "Templates",
    state: "State",
    street: "Street",
    portalUrl: "Portal URL",
    copyright: "Copyright",
    unsubscribeUrl: "Unsubscribe URL",
    helpdeskUrl: "Helpdesk URL",
    servicesUrl: "Services URL",

    continueWithFacebook: "Continue with Facebook",
    step1: "Step 1",
    step2: "Step 2",
    step3: "Step 3",
    linkAccount: "Link Account",
    accessToken: "Access Token",
    secret: "Secret",
    fAkeepWindowOpen:
      "Please keep this window open while you create or select your account.",
    fAAccountLinkDisclaimer:
      "If your phone number is already connected to the WhatsApp consumer or small business application, please disconnect it prior to continuing below.",
    howToLinkFAAccount:
      "You'll be guided through linking your account in a popup window.",
    layoutBuilder: "Layout builder",
    lineSenderInfo:
      'Add your Channel secret and Channel access token to add LINE account. You can get these from the <a href="https://developers.line.biz/en" target="_blank">LINE Developer Center.</a><br><br> Open the account for editing and from the Basic settings tab, copy the value from the Channel secret field.<br><br>Then, from the Messaging API tab, scroll down to the Channel access token section, and copy the value from the Channel access token (long-lived) field.',
    copyNumber: "Copy Number",
    telegramSenderInfo:
      'Add your Access token in order to configure Telegram. <br><br>Note that to be able to send and receive Telegram messages, you must first obtain an "Access token" from Telegram which you do by creating a <a href="https://telegram.org/sign-up" target="_blank">Telegram account.</a>',
    instagramSenderInfo:
      "To be able to send and receive messages with Instagram you must first have a Instagram Business Account and a Facebook page connected to it. You also need to be an admin of the page you are going to connect to your app.",
    messengerSenderInfo:
      "To be able to send and receive messages with Facebook Messenger, you must first have a Facebook Business Account and a Facebook page connected to it. You also need to be an admin of the page you are going to connect to your app.",
    enterFbNumber:
      "You will need to enter this number when prompted in the Facebook window to verify this number.",
    isTyping: " is typing...",
    media: "Media",
    smsFileTypesNotSet:
      "Supported SMS file types not set.  Please logout and back in.  If the problem persist, please contact support.",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    app: "App",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    noResourcePermission: "You don’t have permission to access this resource",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendar: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    groups: "Groups",
    offices: "Offices",
    numberDeletion: " Number Deletion",
    activateOnSignUp: "Activate On Sign Up",
    selectPortNumbers:
      "Please select the numbers you would like to port to continue...",
    users: "Users",
    serverDetails: "Server Details",
    multifactorAuth: "Multi-Factor Auth",
    processError:
      "I'm sorry, I've experienced an error processing your request",
    phoneNumbers: "Phone Numbers",
    autoAttendants: "Auto Attendants",
    myNumbers: "My Numbers",
    phoneNumberPorts: "Port in Requests",
    firewall: "Firewall",
    announcements: "Announcements",
    menus: "Menus",
    directories: "Directories",
    callQueues: "Call Queues",
    virtualExtensions: "Virtual Extensions",
    devices: "Devices",
    clients: "Clients",
    servers: "Servers",
    fax: "Fax",
    voicemail: "Voicemail",
    productGroups: "Groups",
    productFeatures: "Features",
    rules: "Rules",
    inbound: "Inbound",
    outbound: "Outbound",
    ipBlockList: "IP Block List",
    billing: "Billing",
    ipAddresses: "IP Addresses",
    ipWhiteList: "IP Whitelists",
    licenseDescription:
      "These are products owned by your organization that were bought from Circuit ID or 3rd-party providers. Assign licenses to users to enable features. Need more licenses?",
    ordersDescription:
      "These are products owned by your organization that were bought from Circuit ID or 3rd-party providers. Need more licenses?",
    purchaseServices: "Purchase Services",
    creditDescription:
      "The above balance represents the credit available for purchases on your account.",
    addCredit: "Add Credit",
    creditBalance: "Credit Balance",
    accountLoaded: "Account Loaded successfully.",
    accountUnLoaded: "Account closed successfully.",
    invalidPhoneNumber:
      "The phone number you entered is not valid.  Please check the number and try again.",
    noValidPhoneNumbers: "No valid phone numbers found.",
    nameExists:
      "Validation failed!  The name you entered already exists.  Please use another name to continue.",
    invalidEmail:
      "The email you entered is not valid.  Please check the email and try again.",
    errorProcessingRequest:
      "I'm sorry, however, I was unable to process your request.  Please try again or contact support.",
    accountSettings: "Account Settings",
    layoutSettings: "Appearance",
    appSettings: "App Settings",
    displayName: "Display Name",
    sipDomain: "SIP Domain",
    username: "Username",
    callStatistics: "Call Statistics",
    fileSystemNotConfigured:
      "File system incorrectly configured.  Please contact support.",
    fileTypeNotAllowed: "File type not allowed",
    maxUploadedFilesReached: "The maximum allowed file limit has been reached",
    products: "Products",
    features: "Features",
    schedules: "Schedules",
    inIncluded: "In Included",
    outIncluded: "Out Included",
    sendIncomingCalls:
      "Where should we send incoming calls for your new numbers?",
    invoices: "Invoices",
    paymentMethods: "Payment Methods",
    productCategories: "Categories",
    meetNow: "Meet now",
    queues: "Queues",
    orders: "Orders",
    overview: "Overview",
    brands: "Brands",
    signingUp: "",
    privacyPolicy: "Privacy Policy",
    termsOfService: "Terms of Service",
    terms: "Terms",
    privacy: "Privacy",
    termsOfServiceDescription:
      "By checking this box you agree to the [terms], [privacyPolicy], and to receive text messages.  Message and data rates may apply. Reply HELP for help or STOP to unsubscribe.",
    termsOfServiceDescriptionConsumer:
      "By checking this box you agree to the [terms] and [privacyPolicy].",
    passwordResetInstructions:
      "Must contain atleast 8 characters, one uppercase, one lowercase, one number and one special character.",
    smsConsent:
      "By providing your number, you consent to receiving text messages, calls (live, automated, and prerecorded) about our products and services. Message and data rates may apply. Reply HELP for help or STOP to unsubscribe.",
    contacts: "Contacts",
    config: "Config",
    environment: "Environment",
    environmentvariables: "Environment Variables",
    variables: "Variables",
    domains: "Domains",
    verificationType: "Verification Type",
    providers: "Providers",
    regions: "Regions",
    callrates: "Call Rates",
    allsms: "All SMS",
    allconversations: "All Conversations",
    logs: "Logs",
    faxes: "Faxes",
    filterOptions: "Filter Options",
    reset: "Reset",
    apply: "Apply",
    dateRange: "Date Range",
    direction: "Direction",
    callDirection: "Call Direction",
    select: "Select",
    callType: "Call Type",
    destinationType: "Destination Type",
    callTranscripts: "Call Transcripts",
    signingIn: "Sign In",
    newHere: "New Here",
    createAnAccount: "Create an Account",
    email: "Email",
    password: "Password",
    forgot: "Forgot",
    forgotPassword: "Forgot Password",
    pleaseWait: "Please wait",
    useGoogleAuthenticator: "Use Google Authenticator",
    textCode: "Text me a code",
    or: "or",
    organization: "Organization",
    joinMeeting: "Join Meeting",
    joinAMeeting: "Join a Meeting",
    signWithGoogle: "Sign in with Google",
    signWithMicrosoft: "Sign in with Microsoft",
    receiveVoiceCode: "Receive voice call",
    enterGoogleAuthenticatorCode: "Enter your Google Authenticator code",
    sign: "Signing in",
    info: "Info",
    setupYourLogin: "Setup your login",
    address: "Address",
    enterYourAddress: "Enter your address",
    plan: "Plan",
    selectYourPlan: "Select your plan",
    checkout: "Checkout",
    reviewAndCheckout: "Review and checkout",
    back: "Back",
    getStart: "Get Started",
    continue: "Continue",
    enterCode: "Enter Code",
    numberType: "Number Type",
    enterCodeToContinue: "Please enter your verification code to continue.",
    verifyViaTextOrVoice:
      'The verification code will appear below after you press "send code" in the Facebook window.  Otherwise, if you opted to verify via voice, you will receive a voice call with the code.',
    code: "Code",
    cancel: "Cancel",
    verify: "Verify",
    verifyYourInfo: "Verify your Info",
    submit: "Submit",
    signUpFree: "Sign Up Free",
    meetingID: "Meeting ID",
    passcode: "Passcode",
    downloadTheApp: "Download the app",
    seems: "Seems there is nothing here",
    returnHome: "Return Home",
    systemError: "System Error",
    somethingWrong: "Something went wrong!",
    pleaseTryAgain: "Please try again later.",
    goToHomepage: "Go to homepage",
    about: "About",
    contact: "Contact",
    contactUs: "Contact Us",
    accountInfo: "Account Info",
    accountInformation: "Enter your account information",
    enterYourAddressInfo: "Enter your address info",
    yourAddressForBilling:
      "Your address will be used for billing & regulatory purposes.",
    reviewCheckout: "Review & Checkout",
    product: "Product",
    sitemenu: "Site Menu",
    siteMenuGroups: "Site Menu Groups",
    siteMenuGroup: "Site Menu Group",
    siteMenuGroupPages: "Site Menu Group Pages",
    siteMenuGroupPage: "Site Menu Group Page",
    page: "Page",
    qty: "Qty",
    setup: "Setup",
    amount: "Amount",
    subTotal: "Sub-Total",
    commitment: "Commitment",
    billInterval: "Bill Interval",
    sites: "Sites",
    site: "site",
    trial: "Trial",
    siteMenuPage: "Site Menu Page",
    NA: "N/A",
    total: "Total",
    qauntity: "Qauntity",
    all: "All",
    youAreDone: "You're Done!",
    needAttention: "We need your attention!",
    greatServices: "To start using great services, head over to our",
    managementPortal: "Management Portal",
    whatWould: "What would you like to do?",
    chatWithOthers: "Chat with others in real-time",
    phone: "Phone",
    makeAndReceive: "Make and receive global calls",
    meet: "Meet",
    onlineConference: "Host an online conference",
    sms: "SMS",
    smsMessages: "Send and receive SMS messages",
    sendAndReceiveFaxes: "Send and receive faxes",
    checkYourVoicemail: "Check your voicemail messages",
    billingInfo: "View & manage your billing info",
    payInvoice: "Pay Invoice",
    strategy: "Strategy",
    callQueue: "Call Queue",
    play: "Play",
    downloadPcap: "Download PCAP",
    viewTranscription: "View Transcription",
    started: "Started",
    from: "From",
    to: "To",
    party: "Party",
    quality: "Quality",
    callHistory: "Call History",
    user: "User",
    client: "Client",
    conferenceRooms: "Conference Rooms",
    id: "ID",
    shareWith: "Share With",
    lobby: "Lobby",
    requiredPasscode: "Required Passcode",
    conferenceRoom: "Conference Room",
    directory: "Directory",
    speechRecognition: "Speech Recognition",
    menu: "Menu",
    portNumber: "Port Number",
    destination: "Destination",
    activation: "Activation",
    voice: "Voice",
    number: "Number",
    inboundRules: "Inbound Rules",
    phoneInboundRule: "Phone Inbound Rule",
    outBoundRules: "Outbound Rules",
    phoneOutboundRule: "Phone Outbound Rule",
    host: "Host",
    timeZone: "Time Zone",
    virtualExtension: "Virtual Extension",
    extension: "Extension",
    inboundRule: "Inbound Rule",
    server: "Server",
    priority: "Priority",
    target: "Target",
    param: "Param",
    hit: "Hit",
    avatar: "Avatar",
    favorites: "Favorites",
    appMarketplace: "App Marketplace",
    first: "First",
    last: "Last",
    mobile: "Mobile",
    faxaccounts: "Fax Accounts",
    faxAccounts: "Fax Accounts",
    inboundNotification: "Inbound Notification",
    outboundNotification: "Outbound Notification",
    date: "Date",
    duration: "Duration",
    brand: "Brand",
    entityType: "Entity Type",
    rate: "Rate",
    callRate: "Call Rate",
    dialPrefix: "Dial Prefix",
    buyRate: "Buy Rate",
    sellRate: "Sell Rate",
    enterSellRate: "Enter Sell Rate...",
    sellMinRate: "Sell Min. Rate",
    minCallRate: "Min. Call Rate",
    buyIncrement: "Buy Increment",
    sellIncrement: "Sell Increment",
    campaign: "Campaign",
    purchasedServices: "Purchased Services",
    details: "Details",
    billingPOC: "Billing POC",
    circuitId: "Circuit ID",
    billingEmail: "Billing Email",
    billingAddress: "Billing Address",
    mobilePhone: "Mobile Phone",
    language: "Language",
    totalUsers: "Total Users",
    edit: "Edit",
    editCustomerDetails: "Edit customer details",
    customer: "Customer",
    accountCredit: "Account Credit",
    createdOn: "Created On",
    domain: "Domain",
    due: "Due",
    assigned: "Assigned",
    quantity: "Quantity",
    paidWith: "Paid With",
    order: "Order",
    exp: "Exp",
    default: "Default",
    conversations: "Conversations",
    addAName: "Add a name...",
    selectBrand: "Select Brand",
    speechBrand: "Speech Brand",
    useCase: "Use Case",
    selectUseCase: "Select Use Case",
    subUseCase: "Sub Use Case",
    selectSubUseCases: "Select Sub Use Cases",
    useSubUseCases: "Use Sub Use Cases",
    subscriberOptin: "Subscriber Opt-in",
    subscriberOptout: "Subscriber Opt-out",
    subscriberHelp: "Subscriber Help",
    numberPooling: "Number Pooling",
    rowActions: "Actions",
    directLendingloanArrangement: "Direct Lending or Loan Arrangement",
    embeddedLink: "Embedded Link",
    embeddedPhoneNumber: "Embedded Phone Number",
    affiliatedMarketing: "Affiliated Marketing",
    ageGatedContent: "Age-Gated Content",
    pin: "Pin",
    sample1: "Sample 1",
    addSample: "Add a sample...",
    sample2: "Sample 2",
    addSample2: "Add sample 2...",
    sample3: "Sample 3",
    addSample3: "Add sample 3...",
    sample4: "Sample 4",
    addSample4: "Add sample 4...",
    sample5: "Sample 5",
    career: "Career",
    qualify: "Qualify",
    review: "Review",
    tpm: "TPM",
    messageClass: "Message Class",
    mms: "MMS",
    addressReq: "Address REQ",
    monthlyFee: "Monthly fee",
    change: "Change",
    noDataFound: "No data found",
    addMembers: "Add Members",
    selectTypeofMembers: "Select the type of members you'd like to add.",
    selectSpeechRecognition: "Select Speech Recognition",
    portable: "Portable",
    addRule: "Add Rule",
    noRulesAdded: "No rules added",
    external: "External",
    externalNumberDescription: "Enter a number from another service provider.",
    numberLikeToAdd: "What type of number(s) would you like to add?",
    callerId: "Caller ID",
    textedCode: "We texted a code to ",
    sentVoiceCodeTo: "We initiated a voice call with a code to ",
    verifyYourMobilePhone: "Verify your Mobile Phone",
    verifyYourEmail: "Verify your Email",
    emailedCode: "We emailed a code to ",
    sentVoiceCode: "We sent a voice code to ",
    callerIdNumber: "Caller ID Number",
    callerIdName: "Caller ID Name",
    selectTypeOfNumber:
      "Please select the type of number you would like to add.",
    removeCallerId: "Remove Caller ID",
    typeOfCallerIdNumber:
      "What type of number would you like to change your caller ID to?",
    officeAssociatedWithNumber:
      "What office will be associated with your new number(s?",
    selectNumberType: "Select number type to continue...",
    selectANumberToContinue: "Please select a number to continue...",
    enterANumberToContinue: "Please enter a valid phone number to continue...",
    selectVerificationType:
      "Please select the verification type to continue...",
    selectADestination: "Select a destination type to continue...",
    enterACallDestination:
      "Please enter a call forwarding destination to continue...",
    pleaseSelectADestination: "Please select a destination to continue...",
    pleaseSelectPaymentMethod: "Please select a payment method to continue...",
    invalidCreditCard:
      "The selected credit card has an invalid user account...",
    selectNpaNxxOrText:
      "At minimum, you must select a state or enter an NPA, NXX and/or Ext.",
    invalidToll:
      "Invalid Toll Free NPA! NPA must be one of 833, 844, 855, 866, 877 or 888.",
    selectAtleastNumber: "Please select at least one (1) number to continue...",
    numbers: "Numbers",
    search: "Search",
    existing: "Existing",
    local: "Local",
    localNumbersTelephoneNumbers:
      "Local numbers are telephone numbers which are assigned to a specific geographic region.",
    tollFree: "Toll-Free",
    tollFreeNumbers:
      "Toll free numbers are telephone numbers that are free of charge for the calling party.",
    internationalMobile: "International Mobile",
    mobileNumbers:
      "Mobile numbers are telephone numbers that send and receive SMS/MMS messages. Also, some mobile numbers provide voice calling features.",
    selectANumber: "Select a number from your existing inventory.",
    npa: "NPA",
    nxx: "NXX",
    ext: "Ext.",
    service: "Service",
    value: "Value",
    featureHeaderObjectName: "Feature",
    group: "Group",
    theme: "Theme",
    selectTheme: "Select Theme",
    main: "Main",
    header: "Header",
    subheader: "Subheader",
    aside: "Aside",
    content: "Content",
    footer: "Footer",
    loader: "Loader",
    loaderType: "Loader Type",
    illustrationSet: "Illustration Set",
    displayPageLoader: "Display Page Loader",
    spinner: "Spinner",
    spinnerMessage: "Spinner & Message",
    spinnerLogo: "Spinner & Logo",
    sketchy: "Sketchy",
    sigma: "Sigma",
    dozzy: "Dozzy",
    unitedPalms: "United Palms",
    display: "Display",
    width: "Width",
    fluid: "Fluid",
    fixed: "Fixed",
    dark: "Dark",
    light: "Light",
    minimized: "Minimized",
    selectLayout: "Select layout width type.",
    minimizePanel: "Minimize secondary panel by default",
    displayAside: "Display Aside",
    display2: "Display:",
    setAsideTheme: "Set Aside Theme",
    displaySubheader: "Display Subheader",
    selectHeader: "Select Header Width Type",
    enableFixedHeader: "Enable Fixed Header",
    tabMob: "Tablet & Mobile",
    desktop: "Desktop",
    fixedHeader: "Fixed Header:",
    designedIllustration:
      "Select one of our in-house designed illustration set and apply globally for all apps and pages",
    selectPage: "Select Page Loader Type",
    appTheme: "Set App Theme",
    method: "Method",
    level: "Level",
    log: "Log",
    officeDetails: "Office Details",
    saveChanges: "Save Changes",
    office: "Office",
    tryAgain: "Try again!",
    stats: "Stats",
    savedSuccessfully: "Saved Successfully",
    outboundCallUsage: "Outbound Call Usage",
    IMSorry:
      "I'm sorry, however, I've experienced an error processing your request.",
    productCategory: "Product Category",
    term: "Term",
    region: "Region",
    resource: "Resource",
    security: "Security",
    terminate: "Terminate",
    defaultInvoice: "Default Invoice Billing Method",
    defaultPaymentMethod:
      "What is the default payment method to bill invoices?",
    embedCallManager: "Embed Call Manager",
    lowBalance: "Low Balance Alerts",
    lowBalanceAmount: "Low Balance Alert Amount",
    balanceAlert: "At what account balance should we send alerts?",
    autoRefillAmount: "Automatic Refill Amount",
    balanceToRefill: "At what account balance should we refill?",
    adminContact: "Admin Contact",
    selectAdminContact: "Select Admin Contact",
    billingContact: "Billing Contact",
    selectBillingContact: "Select Billing Contact",
    whomToContactBilling:
      "Who should we contact for billing related inquiries?",
    chatDensity: "Chat Density",
    whomToContactCallRecording:
      "Who should we send call recording notifications to",
    whomToContactSupport:
      "Who should we contact for support related inquiries?",
    whomToContactAdmin:
      "Who should we contact for administrative related inquiries?",
    callRecording: "Call Recording",
    selectCallRecording: "Select Call Recording Contact",
    supportContact: "Support Contact",
    selectSupport: "Select Support Contact",
    internationalCalling: "International Calling",
    allowInternationalCalls: "Allow international calls?",
    accountLock: "Account Lock",
    preventAccountTermination: "Prevent account termination?",
    maxOutboundCallRate: "Max Outbound Call Rate",
    preventCalls: "Prevent calls that exceed what cost?",
    callerCnameNameLookup: "Caller (CNAM) Name Lookup",
    callingPartyName: "Lookup the calling party's name?",
    callDebugging: "Call Debugging",
    debugCalls: "Debug Calls?",
    mediaBypass: "Media Bypass",
    bypassMediaServers: "Should we bypass our media servers?",
    recordCalls: "Record Calls",
    recordCall: "Record calls?",
    transcribeCalls: "Transcribe Calls",
    transcribeCall: "Transcribe calls?",
    cdr: "Call Detail Record (CDR) Retention",
    storeCDR: "How many months should we store Call Detail Records (CDRs)?",
    routingType: "Routing Type",
    joined: "Joined",
    userDetails: "User Details",
    regionDetails: "Region Details",
    inboundRuleDetails: "Inbound Rule Details",
    profilePleaseWait: "Please wait...",
    emailAddress: "Email Address",
    newEmail: "Enter New Email Address",
    updateEmail: "Update Email",
    callStats: "Call Stats",
    passwordRequirements:
      "Must Contain a minimum of 8 characters that includes an uppercase, lowercase, number, and special character.",
    newPassword: "New Password",
    outboundRules: "Outbound Rules",
    confirmNewPassword: "Confirm New Password",
    updatePassword: "Update Password",
    resetPassword: "Reset Password",
    multiFactorAuth: "Multi-Factor Authentication",
    changeEmail: "Change Email",
    licenses: "Licenses",
    notifications: "Notifications",
    profileUserAvatar: "User Avatar",
    autoCreateConversations: "Auto Create Conversations",
    profileRemoveImage: "Remove Image",
    acceptedSenders: "Accepted Senders",
    senders: "Senders",
    identity: "Identity",
    messaging: "Messaging",
    createdSuccessfully: "created successfully",
    sender: "Sender",
    unknown: "Unknown",
    referenceId: "Reference ID",
    invoicepayment: "Invoice Payment",
    appSubscription: "App Subscriptions",
    rooms: "Rooms",
    joinWithId: "Join with an ID",
    terminatedSuccessfully: "Terminated Successfully",
    updatedSuccessfully: "Updated Successfully",
    copyURL: "Copy Meet URL",
    copied: "Copied!",
    title: "Title",
    location: "Location",
    requiredAttendees: "Required Attendees",
    selectAttendees: "Select or Enter Attendees",
    optionalAttendees: "Optional Attendees",
    startTime: "Start Time",
    allDay: "All Day",
    endTime: "End Time",
    eventDesc: "Event Description",
    startTimeZone: "Choose Start Timezone",
    chooseEndTime: "Choose End Timezone",
    startTimeZOne: "Start Timezone",
    endTimeZone: "End Timezone",
    enterMeetingID: "Enter meeting ID to join now",
    activeCalls: "Active Calls",
    waiting: "Waiting",
    talking: "Talking",
    percentOfTotalCalls: "% of Total Calls",
    percentOfTotalFaxes: "% of Total Faxes",
    secondaryMenu: "Secondary Menu",
    noFaxAccounts:
      "There are no fax accounts added yet. <br/>Kickstart your services by adding a your first Fax Account.",
    selectFaxAccountGetStarted:
      "Select a fax account from the left-hand to get started.",
    received: "Received",
    sent: "Send",
    calls: "Calls",
    clickType: "Click Type",
    agents: "Agents",
    history: "History",
    agentTimeouts: "Agent Timeouts",
    queueTimeouts: "Queue Timeouts",
    talkingTo: "Talking To",
    callQueueAgents: "Call Queue Agent",
    searchMembers: "Search, find members and click the checkbox to add.",
    delete: "Delete",
    aboutUrl: "About URL",
    privacyPolicyUrl: "Privacy Policy URL",
    legalUrl: "Legal URL",
    logoUrl: "Logo URL",
    deleted: "Deleted",
    successfully: "Successfully",
    maxWaitTIme: "Max Wait Time",
    signWithApple: "Sign in with Apple",
    selectMaxWaitTime: "Select Max Wait Time",
    maxWaitTimeNoAgent: "Max Wait Time No Agent",
    selectMaxWaitTimeNoAgent: "Select Max Wait Time No Agent",
    callerResumeTimeout: "Caller Resume Timeout",
    selectResumeTimeout: "Select Resume Timeout",
    none: "None",
    dashboardRoutePath: "Dashboard Route Path",
    routeName: "Route Name",
    href: "Href",
    selectStrategy: "Select Strategy",
    callQueueStrategy: "Call Queue Strategy",
    positionAnnouncementInterval: "Position Announcement Interval",
    getItNow: "Get it now",
    remove: "Remove",
    signUpEnabled: "Sign Up Enabled",
    notAuthorized: "Not Authorized",
    goBack: "Go Back",
    noUserMatch:
      "No users matching your request. Enter user's email to add user.",
    selectContacts: "Select Contacts",
    selectFromNumber: "Select from number",
    messages: "Messages",
    message: "Message",
    createdBy: "Created By",
    lastMessage: "Last Message",
    channel: "Channel",
    availableCredit: "Available Credit",
    createRecord: "Create Record",
    fewClicks: "In just a few clicks",
    recent: "Recent",
    appName: "App name",
    permissions: "Permissions",
    auth: "Auth",
    pricing: "Pricing",
    legal: "Legal",
    authType: "Auth Type",
    headerName: "Header Name",
    headerName2: "Header Name...",
    nameOfHeader: "The name of the header to be posted to the web service",
    headerValue: "Header Value",
    headerValuePosted: "The header value to be posted to your web service",
    userName: "Username",
    freeQ: "Free?",
    free: "Free",
    appFreeToUse: "Is this app free to use?",
    feeDesc: "Fee Description",
    termsOfServiceUrl: "Terms Of Service Url",
    feeDescription: "Fee Description",
    descAppCost: "A description of how much your app cost to use",
    termsOfURL: "Terms of Service URL",
    applicationPrivacy: "A link to your application's Privacy Policy",
    serviceURL: "Add Terms of Service URL...",
    privacyPolicyURL: "Privacy Policy URL",
    applicationTerms: "A link to your application's Terms of Service",
    addPrivacyPolicyURL: "Add Privacy Policy URL...",
    description: "Description",
    hostname: "Host name",
    pointsToAddress: "Points to Address",
    Ttl: "TTL",
    approved: "Approved",
    pending: "Pending",
    inProcess: "In Process",
    rejected: "Rejected",
    memberType: "Member Type:",
    author: "Author",
    enabled: "Enabled",
    quickActions: "Quick Actions",
    newTicket: "New Ticket",
    newCustomer: "New Customer",
    newGroup: "New Group",
    adminGroup: "Admin Group",
    staffGroup: "Staff Group",
    memberGroup: "Member Group",
    newContact: "New Contact",
    generateReports: "Generate Reports",
    addContact: "Add Contact",
    addContacts: "Add Contacts",
    inviteContacts: "Invite Contacts",
    createGroup: "Create Group",
    inviteMembers: "Invite Members",
    acceptedSender: "Accepted Sender",
    export: "Export",
    selected: "Selected",
    editGroup: "Edit Group",
    selectStatus: "Select Status",
    items: "Items",
    payments: "Payments",
    invoice: "Invoice",
    subtotal: "Subtotal",
    currencyFormat: "$0.00",
    invoiceItem: "Invoice Item",
    accountNo: "Account No.",
    transactionID: "Transaction ID",
    aboutInvoicePayment: "objects/AboutInvoicePayment",
    invoicePayment: "Invoice Payment",
    network: "Network",
    netmask: "Netmask",
    searchAndFindMembers: "Search and find members to add.",
    add: "Add",
    delSelected: "Delete Selected",
    actions: "Actions",
    view: "Views",
    options: "Options",
    greeting: "Greeting",
    businessHours: "Business Hours",
    timeouts: "Timeouts",
    editMenu: "Edit Menu",
    businessHoursSchedule: "Business Hours Schedule",
    selectBusinessHoursSchedule: "Select Business Hours Schedule",
    hoursDestinationType: "After Hours Destination Type",
    hoursDestination: "After Hours Destination",
    enableSpeechRecognition: "Enable speech recognition",
    externalUserInRoom: "This person is outside of your account.",
    externalUsersInRoom:
      "Some people in this group are outside of your account.",
    voiceWillBePlayed:
      "Voice that will be played for text-to-speech, errors and menu prompts",
    selectDirectory: "Select Directory",
    greetingType: "Greeting Type",
    selectGreetingType: "Select Greeting Type",
    speechToText: "Speech-to-text",
    enterSpeechToText: "Enter Text-to-Speech...",
    exitSound: "Exit Sound",
    selectExitSound: "Select Exit Sound",
    transferAnnouncement: "Transfer Announcement",
    selectTransferAnnouncement: "Select Transfer Announcement",
    voiceCommand: "Voice Command",
    digit: "Digit",
    destinationName: "Destination Name",
    menuOption: "Menu Option",
    maxFailures: "Max Failures",
    maxTimeouts: "Max Timeouts",
    businessHoursTimeout: "Business Hours Timeout",
    selectDateRange: "Select Date Range:",
    selectExportFormat: "Select Export Format:",
    excel: "Excel",
    pdf: "PDF",
    cvs: "CVS",
    zip: "ZIP",
    exportType: "Export Type",
    allData: "All Data",
    filteredData: "Filtered Data",
    discard: "Discard",
    newEpic: "New Epic",
    startDate: "Start Date",
    create: "Create",
    memberTypeD: "Member Type",
    done: "Done",
    membersLikeToAdd: "The type of members you would like to add.",
    comments: "Comments",
    billingInfoD: "Billing Info:",
    billTo: "Bill to:",
    customerName: "Customer Name:",
    paymentMethod: "Payment Method",
    currency: "Currency:",
    usd: "USD - US Dollar",
    epicName: "Epic Name",
    addEpicName: "Add a epic name...",
    addChildIssue: "Add a child issue",
    linkIssue: "Link issue",
    View: "View",
    clone: "Clone",
    assignee: "Assignee",
    taskAssignee: "Task Assignee",
    selectAssignee: "Select Assignee",
    reporter: "Reporter",
    reporterPerson: "The person who reported this task",
    selectReporter: "Select Reporter",
    dependentTask: "Dependent Task",
    predecessorTask: "Predecessor Task",
    selectPredecessorTask: "Select Predecessor Task",
    projectName: "Project Name",
    epics: "Epics",
    sprints: "Sprints",
    tasks: "Tasks",
    editProject: "Edit Project",
    agileProjects: "Agile, Projects",
    sprint: "Sprint",
    sprintName: "Sprint Name",
    endDate: "End Date",
    sprintGoal: "Sprint Goal",
    selectStartDate: "Select a start date",
    selectEndDate: "Select an end date",
    epic: "Epic",
    agileSprints: "Agile, Sprints",
    voiceCall: "Voice Call",
    videoCall: "Video Call",
    sendSMS: "Send SMS",
    paymentMethodType: "Payment Method Type",
    johnDoe: "John Doe",
    download: "Download",
    userMobile: "User Mobile",
    myMobile: "My Mobile",
    call: "Call",
    onHold: "On Hold",
    ringing: "Ringing",
    connecting: "Connecting",
    incomingCall: "Incoming Call",
    answerTitle: "AnswerTitle",
    rejectTitle: "RejectTitle",
    dialPad: "Dial pad",
    hold: "Hold",
    transfer: "Transfer",
    people: "People",
    camera: "Camera",
    mic: "Mic",
    videoAnswer: "Video Answer",
    answer: "Answer",
    leave: "Leave",
    unavailable: "Unavailable",
    personNotAvailable:
      "The person you're trying to reach isn't available. Try again later.",
    dismiss: "Dismiss",
    isCallingYou: "is calling you",
    calling: "Calling",
    selectInboundRule: "Select Inbound Rule",
    expiresIn: "Expires In",
    token: "Token",
    expiresInYears: "Expires In (Years)",
    selectExpiresInYears: "Select Expires In (Years)",
    yearsTokenExpires: "In how many years should this token expire?",
    fullName: "Full Name",
    jobTitleDepartment: "Job Title/Department",
    selectOffice: "Select Office",
    jobTitle: "Job Title",
    department: "Department",
    changeAvatar: "Change avatar",
    removeAvatar: "Remove avatar",
    task: "Task",
    taskName: "Task Name",
    addTaskName: "Add a task name...",
    selectEpic: "Select Epic",
    selectSprint: "Select Sprint",
    storyPointEstimate: "Story point estimate",
    enterEstimatedStoryPoints: "Enter estimated story points...",
    inBoundActions: "Inbound Rule Action",
    outboundCallerId: "Outbound Caller ID",
    configureOutboundCallerId: "Configure Outbound Caller ID...",
    phoneOutBoundRuleName: "Phone Outbound Rule Name",
    outBoundRuleAction: "Outbound Rule Action",
    statusOfRule: "Status of Rule",
    attributes: "Attributes",
    productAttribute: "Product Attribute",
    commitmentInterval: "Commitment Interval",
    productTerm: "Product Term",
    usNumbers: "US Numbers",
    internationalNumbers: "International Numbers",
    numberPorts: "Number Ports",
    regionProvider: "Region Provider",
    domesticOut: "Domestic Out",
    domesticOutFax: "Domestic Out Fax",
    tfOut: "TF Out",
    tfOutFax: "TF Out Fax",
    intlOut: "Intl Out",
    e911Out: "E911 Out",
    addServiceObjectsModal: "Add Service Objects Modal",
    addResource: "Add Resource",
    searchFindResource: "Search, find resources and click the checkbox to add.",
    project: "Project",
    whatProjectCreatingTaskFor: "What project are you creating this task for?",
    summary: "Summary",
    summaryTaskCreating: "Summarize the task you are creating",
    taskDescriptionInDetail: "Describe the task in detail",
    storyPoints: "Story Points",
    storyPointsEstimate: "How many story points you estimate this may take?",
    reportingTask: "Who's reporting this task?",
    attachments: "Attachments",
    attachAnyNecessaryFiles: "Attach any necessary files",
    whoWorkOnTask: "Who will be working on this task?",
    epicTaskPart: "Which Epic will this task be part of?",
    taskBeScheduled: "Which sprint should this task be scheduled?",
    createTask: "Create Task",
    createAnother: "Create Another",
    holidays: "Holidays",
    holiday: "Holiday",
    login: "Login",
    tokens: "Tokens",
    sound: "Sound",
    callRecordings: "Call Recordings",
    missedCalls: "Missed Calls",
    newsletters: "Newsletters",
    voicemails: "Voicemails",
    web: "Web",
    admin: "Admin",
    adminPermissions: "Admin Permissions",
    billingPermissions: "Billing Permissions",
    phonePermissions: "Phone Permissions",
    agile: "Agile",
    agilePermissions: "Agile Permissions",
    globalAdmin: "Global Admin",
    globalAdminPermissions: "Global Admin Permissions",
    mediaServer: "Media Server",
    mediaServerPermissions: "Media Server Permissions",
    mailServer: "Mail Server",
    mailServerPermissions: "Mail Server Permissions",
    jobServer: "Job Server",
    jobServerPermissions: "Job Server Permissions",
    changeNumber: "Change Number",
    changeCallerId: "Change Caller ID",
    callerIdOverride: "Caller ID Override",
    SIPAccount: "SIP Account",
    remoteConfiguration: "Remote Configuration",
    QRCode: "QR Code",
    linphoneIntegration: "Linphone Integration",
    callUsageInMins: "Call Usage (in minutes)",
    totalIncluded: "Total Included",
    totalDomestic: "Total Domestic",
    totalInternational: "Total International",
    totalUsed: "Total Used",
    usedDomestic: "Used Domestic",
    usedInternational: "Used International",
    domesticRemaining: "Domestic Remaining",
    internationalRemaining: "International Remaining",
    timezone: "Timezone",
    mustContain:
      "Must Contain a minimum of 8 characters that includes an uppercase, lowercase, number and special character.",
    userToken: "User Token",
    playbackNumber: "Playback Number",
    file: "File",
    voicemailTimeout: "Voicemail Timeout",
    transcribe: "Transcribe?",
    shouldVoicemailsBeTranscribed: "Should your voicemails be transcribed?",
    loading: "Loading...",
    callExtension: "Call Extension",
    callMobile: "Call Mobile",
    callBusinessPhone: "Call Business Phone",
    searchGroups: "Search Groups...",
    removeFromGroup: "Remove From Group",
    addUsers: "Add Users",
    invites: "Invites",
    addRecipients: "Add Recipients",
    addRecipient: "Add Recipient",
    changeGroup: "Change Group",
    roomUsers: "Room Users",
    audioCall: "Audio Call",
    searchForUsers: "Search for Users...",
    noUser: "No users matching your request.",
    addUser: "Add User",
    addComment: "Add a comment...",
    externalUser: "External User",
    selectTimezone: "Select Timezone",
    selectType: "Select type",
    sip: "SIP",
    skype: "Skype",
    enterHost: "Enter Host...",
    port: "Port",
    enterPort: "Enter Port...",
    inboundLicense: "Inbound License",
    selectInboundlicense: "Select inbound license",
    outboundLicense: "Outbound License",
    selectOutboundLicense: "Select outbound license",
    customCallerID: "Custom Caller ID?",
    callerID: "Caller ID",
    enterCallerID: "Enter Caller ID...",
    bypassMedia: "Bypass Media",
    disableRTP: "Disable RTP Auto Adjust",
    advanced: "Advanced",
    privateIPv4: "Private IPv4",
    enterPrivateIPv4: "Enter Private IPv4...",
    publicIPv4: "Public IPv4",
    enterPublicIPv4: "Enter Public IPv4...",
    enterUsername: "Enter Username...",
    enterPassword: "Enter Password...",
    allowDomesticOutboundFax: "Allow Domestic Outbound Fax",
    allowInbound: "Allow Inbound",
    selectRegion: "Select Region",
    transport: "Transport",
    selectTransport: "Select Transport",
    domesticIntlOut: "Domestic Intl Out",
    tollFreeOut: "Toll Free Out",
    tollFreeOutFax: "Toll Free Out Fax",
    identifier: "Identifier",
    latitude: "Latitude",
    longitude: "Longitude",
    addIdentifier: "Add an Identifier...",
    enterLatitude: "Enter Latitude...",
    enterLongitude: "Enter Longitude...",
    ruleType: "Rule Type",
    usOutbound: "US Outbound",
    intlOutbound: "Intl Outbound",
    portLocal: "Port Local",
    portTollFree: "Port Toll Free",
    portIntlLocal: "Port Intl Local",
    portIntlTollFree: "Port Intl Toll-Free",
    intlLocal: "Intl Local",
    intlTollFree: "Intl Toll Free",
    searchQuantity: "Search Quantity",
    selectBuyIncrement: "Select Buy Increment",
    selectSellIncrement: "Select Sell Increment",
    enterSearchQuantity: "Enter Search Quantity...",
    clientName: "Client Name",
    commitmentIntervalType: "Commitment Interval Type",
    billIntervalType: "Bill Interval Type",
    trialInterval: "Trial Interval",
    trialIntervalType: "Trial Interval Type",
    enterAmount: "Enter Amount...",
    selectInterval: "Select interval",
    selectCommitmentIntervalType: "Select commitment interval type",
    selectBillInterval: "Select bill interval",
    selectBillIntervalType: "Select bill interval type",
    selectTrialInterval: "Select trial interval",
    selectTrialIntervalType: "Select trial interval type",
    selectSchedule: "Select schedule",
    addAccountCredit: "Add account credit...",
    disabled: "Disabled",
    enable: "Enable",
    accountTypes: "Account Types",
    consumer: "Consumer",
    business: "Business",
    icon: "Icon",
    addIcon: "Add an icon...",
    categories: "Categories",
    selectCategories: "Select Categories",
    selectFeatures: "Select Features",
    types: "Types",
    selectTypes: "Select Types",
    orderable: "Orderable",
    showOnSignUp: "Show on Sign Up?",
    showInWebPortal: "Show in Web Portal",
    startingChars: "Starting Characters",
    startingCharacters: "Starting Characters",
    contains: "Contains",
    lengthType: "Length Type",
    length: "Length",
    selectLength: "Select Length",
    rangeStart: "Range Start",
    selectRangeStart: "Select Range Start",
    rangeEnd: "Range End",
    selectRangeEnd: "Select Range End",
    appendChars: "Append Characters",
    prependChars: "Prepend Characters",
    removeStartingChars: "Remove Starting Characters",
    selectRemoveStartingChars: "Select Remove Starting Characters",
    removeEndingChars: "Remove Ending Characters",
    selectRemoveEndingChars: "Select Remove Ending Characters",
    route: "Route",
    selectRoute: "Select Route",
    deviceGroup: "Device Group",
    selectDeviceGroup: "Select Device Group",
    selectRoutingType: "Select Routing Type",
    match: "Match",
    normalize: "Normalize",
    atLeast: "Atleast",
    exactly: "Exactly",
    range: "Range",
    any: "Any",
    publicSwitchedTelephone: "Public Switched Telephone Network (PSTN)",
    loadBalance: "Load Balance",
    simultaneousRing: "Simultaneous Ring",
    ruleActionRouted: "How should calls be routed?",
    selectContact: "Select Contact",
    selectDomain: "Select Domain",
    selectCreditAmount: "Select account credit amount",
    selectPaymentMethod: "Select Payment Method",
    selectVoice: "Select Voice",
    text: "Text",
    brandName: "Brand Name",
    googleAuthenticator: "Google Authenticator",
    ein: "EIN",
    enterYourEin: "Enter your EIN",
    doingBusinessAs: "Doing Business As",
    dba: "DBA?",
    legalName: "Legal Name",
    enterLegalName: "Enter Legal Name...",
    selectPointOfContact: "Select Point of Contact",
    pointOfContact: "Point of Contact",
    selectEntityType: "Select Entity Type",
    selectVertical: "Select Vertical",
    ringTimeout: "Ring Timeout",
    selectRingTimeout: "Select Ring Timeout",
    noAnswerTimeout: "No Answer Timeout",
    selectNoAnswerTimeout: "Select No Answer Timeout",
    rejectTimeout: "Reject Timeout",
    selectRejectTimeout: "Select Reject Timeout",
    busyTimeout: "Busy Timeout",
    selectBusyTimeout: "Select Busy Timeout",
    wrapUpTimeout: "Wrap Up Timeout",
    selectWrapUpTimeout: "Select Wrap Up Timeout",
    selectCallerResumeTimeout: "Select Caller Resume Timeout",
    selectProvider: "Select Provider",
    sellMinimumAmount: "Sell Minimum Amount",
    sample: "Sample",
    brandID: "Brand ID",
    forceUpdate: "Force Update",
    clientApps: "Client Apps",
    clientApp: "Client App",
    embeddedPhone: "Embedded Phone",
    affiliateMarketing: "Affiliate Marketing",
    numberPool: "Number Pool",
    ageGated: "Age Gated",
    directLending: "Direct Lending",
    selectUsers: "Select Users",
    clientMAC: "MAC",
    version: "Version",
    androidVersion: "Android Version",
    iosVersion: "iOS Version",
    forceIOSUpdate: "Force IOS Version",
    forceAndroidUpdate: "Force Android Version",
    ios: "iOS",
    android: "Android",
    appStoreUrl: "App Store URL",
    appId: "App ID",
    androidAppId: "Android App ID",
    iosAppId: "iOS App ID",
    MACAddress: "MAC Address",
    enterMACAddress: "Enter MAC Address...",
    manufacture: "Manufacture",
    selectManufacture: "Select manufacture",
    phoneManufacture: "Phone Manufacture",
    dialInPin: "Dial-in Pin",
    requirePasscode: "Require Passcode",
    businessPhone: "Business Phone",
    faxPhone: "Fax Phone",
    allowedFileTypes: "Allowed file types: png, jpg, jpeg.",
    addAText: "Add a text...",
    accountType: "Account Type",
    consumerAccount: "Consumer Account",
    customerForUse: "For use by an individual for their own personal needs",
    businessAccount: "Business Account",
    forUseCompany: "For use by companies to support their business",
    companyName: "Company Name",
    firstName: "First Name",
    lastName: "Last Name",
    forwardCallsToo: "Forward calls To?",
    selectVisibility: "Select Visibility",
    visibility: "Visibility",
    services: "Services",
    selectServices: "Select Services",
    events: "Events",
    selectEvents: "Select Events",
    maxRingTimeout: "Max Ring Timeout",
    requireResourceId: "Require Resource ID",
    tooltip:
      "Require the user to add the app from the resource they would like it applied to.",
    integrationType: "Integration Type",
    selectIntegrationType: "Select Integration Type",
    webhookUrl: "Webhook URL",
    addWebhookUrl: "Add a Webhook URL...",
    addName: "Add a name",
    serverType: "Server Type",
    selectGroups: "Select groups",
    announcement: "Announcement",
    selectAnnouncement: "Select announcement",
    addDomain: "Add a domain...",
    addValue: "Add a value...",
    faxAccount: "Fax Account",
    selectFaxAccount: "Select fax account",
    recipients: "Recipients",
    faxNumbersOrContacts: "Enter Fax Numbers or Select Contacts",
    toNumbers: "To Numbers",
    selectLicense: "Select License",
    selectNumber: "Select Number",
    key: "Key",
    addKey: "Add a key...",
    firewallRuleStatus: "Firewall Rule Status",
    selectTarget: "Select target",
    selectDirection: "Select direction",
    firewallRuleDirection: "Firewall Rule Direction",
    phoneNumber: "Phone number",
    IPAddress: "IP Address",
    addDescription: "Add a description...",
    dataRanges: "Data Ranges",
    avs: "AVS",
    transactionId: "Transaction ID",
    paymentGateway: "Payment Gateway",
    refundAmount: "Refund Amount",
    void: "Void",
    refund: "Refund",
    addressAddNetwork: "Add a network...",
    enterNetmask: "Enter netmask...",
    selectService: "Select Service",
    textToSpeech: "Text-to-Speech",
    aboutGreetingSelectExitSound: "Select Exit Sound",
    aboutOptionDigit: "Digit",
    speaker: "Speaker",
    microphone: "Microphone",
    addCallerID: "Add a caller ID...",
    directoryListing: "Directory Listing",
    messagingCampaign: "Messaging Campaign",
    smsCampaign: "SMS/MMS Campaign",
    e911Location: "e911 Location",
    callerNameLookup: "Caller Name Lookup",
    typeOfService: "Type of Service",
    residence: "Residence",
    desiredDueDate: "Desired Due Date",
    authorizedPerson: "Authorized Person",
    serviceAddress: "Service Address",
    e911: "e911",
    billingPhoneNumberLabel: "Billing Phone Number",
    nameOfOffice: "Name of office",
    streetNumber: "Street Number",
    preDirection: "Pre Direction",
    selectPreDirection: "Select Pre Direction",
    streetName: "Street Name",
    streetSuffix: "Street Suffix",
    selectStreetSuffix: "Select Street Suffix",
    suiteApt: "Suite/Apt",
    addressCity: "Address city",
    addressState: "Address state",
    enterZipCode: "Enter your zip code",
    selectCountry: "Select Country",
    north: "North",
    south: "South",
    east: "East",
    west: "West",
    northWest: "North West",
    northEast: "North East",
    southWest: "South West",
    southEast: "South East",
    selectQuantity: "Select quantity",
    nextBillDate: "Next Bill Date",
    nextRenewalDate: "Next Renewal Date",
    priceEach: "Price Each",
    quantityChange: "Quantity Change",
    proRatedTotalChargedToday: "Pro-rated Total (Charged Today)",
    proRatedTotal: "Pro-rated Total",
    terminateAmount: "Terminate Amount",
    confirm: "Confirm",
    changeQty: "Change Qty",
    pay: "Pay",
    selectUser: "Select user",
    paymentType: "Payment Type",
    creditCard: "Credit Card",
    paymentMethodOpt: "Visa, Mastercard, Amex, or Discover",
    paypal: "Paypal",
    payWithPayPal: "Pay quickly and securely with PayPal",
    cardNumber: "Card Number",
    enterCardNumber: "Enter card number",
    accountNumber: "Account Number",
    expirationDate: "Expiration Date",
    CVV: "CVV",
    enterACardCVVCode: "Enter a card CVV code",
    validationAmount: "Validation Amount",
    inboundRuleActionName: "Inbound Rule Action Name",
    timeout: "Timeout",
    selectTimeout: "Select Timeout",
    actionTimeout: "Action timeout",
    selectTimeSchedule: "Select Time Schedule",
    timeSchedule: "Time Schedule",
    howShouldCallsBeRouted: "How Should Calls Be Routed",
    isAppFree: "Is this app free to use?",
    addAnExtension: "Add an extension...",
    shouldVoicemailBeTranscribed: "Should your voicemails be transcribed?",
  },
  es: {
    dashboard: "Tablero",
    layoutBuilder: "Constructor de maquetación",
    craft: "Elaborado",
    pages: "Paginas",
    profile: "Perfil",
    profileOverview: "Descripción general",
    projects: "Proyectos",
    campaigns: "Campañas",
    documents: "Documentos",
    connections: "Conexiones",
    wizards: "Magos",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Cuenta",
    accountOverview: "Descripción general",
    settings: "Ajustes",
    authentication: "Autenticación",
    basicFlow: "Flujo básico",
    signIn: "Registrarse",
    signUp: "Inscribirse",
    passwordReset: "Restablecimiento de contraseña",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Aplicaciones",
    chat: "Chat",
    privateChat: "Chat privado",
    groupChat: "Grupo de chat",
    drawerChat: "Chat del cajón",
    widgets: "Widgets",
    widgetsLists: "Liza",
    widgetsStatistics: "Estadísticas",
    widgetsCharts: "Gráficos",
    widgetsMixed: "Mezclada",
    widgetsTables: "Mesas",
    widgetsFeeds: "Alimenta",
    changelog: "Registro de cambios",
    docsAndComponents: "Documentos & Componentes",
    megaMenu: "Mega menú",
    exampleLink: "Enlace de ejemplo",
    modals: "Modales",
    general: "General",
    inviteFriends: "Invitar A Amigos",
    viewUsers: "Ver Usuarios",
    upgradePlan: "Plan De Actualización",
    shareAndEarn: "Compartir Y Ganar",
    forms: "Formas",
    newTarget: "Nuevo Objetivo",
    newCard: "Nueva Tarjeta",
    newAddress: "Nueva Direccion",
    createAPIKey: "Crea Clave De Api",
    twoFactorAuth: "Dos Factores",
    createApp: "Crear Aplicacion",
    createAccount: "Crear Una Cuenta",
    activity: "Actividad",
    documentation: "Documentación",
    components: "Componentes",
    resources: "Recursos",
    customers: "Clientes",
    gettingStarted: "Empezando",
    customersListing: "Listado De Clientes",
    customerDetails: "Detalles De Los Clientes",
    calendar: "Calendario",
    subscriptions: "Suscripciones",
    getStarted: "Empezando",
    subscriptionList: "Lista De Suscripción",
    addSubscription: "Añadir Suscripción",
    viewSubscription: "Suscripción",
  },
  de: {
    dashboard: "Instrumententafel",
    layoutBuilder: "Layout-Builder",
    craft: "Hergestellt",
    pages: "Seiten",
    profile: "Profil",
    profileOverview: "Überblick",
    projects: "Projekte",
    campaigns: "Kampagnen",
    documents: "Unterlagen",
    connections: "Anschlüsse",
    wizards: "Zauberer",
    horizontal: "Horizontal",
    vertical: "Vertikal",
    account: "Konto",
    accountOverview: "Überblick",
    settings: "Die Einstellungen",
    authentication: "Authentifizierung",
    basicFlow: "Grundfluss",
    signIn: "Einloggen",
    signUp: "Anmelden",
    passwordReset: "Passwort zurücksetzen",
    error404: "Fehler 404",
    error500: "Fehler 500",
    apps: "Apps",
    chat: "Plaudern",
    privateChat: "Private Chat",
    groupChat: "Privater Chat",
    drawerChat: "Gruppenchat Schubladen-Chat",
    widgets: "Widgets",
    widgetsLists: "Listen",
    widgetsStatistics: "Statistiken",
    widgetsCharts: "Diagramme",
    widgetsMixed: "Gemischt",
    widgetsTables: "Tabellen",
    widgetsFeeds: "Einspeisungen",
    changelog: "Änderungsprotokoll",
    docsAndComponents: "Dokumente & Komponenten",
    megaMenu: "Mega-Menü",
    exampleLink: "Beispiellink",
    modals: "Modale",
    general: "Allgemeines",
    inviteFriends: "Freunde Einladen",
    viewUsers: "Benutzer Anzeigen.",
    upgradePlan: "Upgrade-Plan",
    shareAndEarn: "Teilen & Verdienen",
    forms: "Formen",
    newTarget: "Neues Ziel",
    newCard: "Neue Karte",
    newAddress: "Neue Adresse",
    createAPIKey: "Api-Key Erstellen",
    twoFactorAuth: "Zwei Faktor Auth.",
    createApp: "App Erstellen",
    createAccount: "Benutzerkonto Erstellen",
    activity: "Aktivität",
    documentation: "Dokumentation",
    components: "Bauteile",
    resources: "Ressourcen",
    customers: "Kunden",
    gettingStarted: "Einstieg",
    customersListing: "Kundenauflistung",
    customerDetails: "Kundenangaben",
    calendar: "Kalender",
    subscriptions: "Abonnements",
    getStarted: "Einstieg",
    subscriptionList: "Abonnementliste",
    addSubscription: "Subskription Hinzufügen.",
    viewSubscription: "Abonnement Anzeigen.",
  },
  ja: {
    dashboard: "ダッシュボード",
    layoutBuilder: "レイアウトビルダー",
    craft: "作成された",
    pages: "ページ",
    profile: "プロフィール",
    profileOverview: "概要",
    projects: "プロジェクト",
    campaigns: "キャンペーン",
    documents: "書類",
    connections: "接続",
    wizards: "ウィザード",
    horizontal: "横",
    vertical: "垂直",
    account: "アカウント",
    accountOverview: "概要",
    settings: "設定",
    authentication: "認証",
    basicFlow: "基本的な流れ",
    signIn: "サインイン",
    signUp: "サインアップ",
    passwordReset: "パスワードのリセット",
    error404: "エラー404",
    error500: "エラー 500",
    apps: "アプリ",
    chat: "チャット",
    privateChat: "プライベートチャット",
    groupChat: "グループチャット",
    drawerChat: "ドロワーチャット",
    widgets: "ウィジェット",
    widgetsLists: "リスト",
    widgetsStatistics: "統計",
    widgetsCharts: "チャート",
    widgetsMixed: "混合",
    widgetsTables: "テーブル",
    widgetsFeeds: "フィード",
    changelog: "変更ログ",
    docsAndComponents: "ドキュメントとコンポーネント",
    megaMenu: "メガメニュー",
    exampleLink: "リンク例",
    modals: "モーダルズ",
    general: "一般",
    inviteFriends: "友達を招待",
    viewUsers: "ユーザーを表示します",
    upgradePlan: "アップグレードプラン",
    shareAndEarn: "シェア＆稼働",
    forms: "フォーム",
    newTarget: "新しいターゲット",
    newCard: "新しいカード",
    newAddress: "新しいアドレス",
    createAPIKey: "Apiキーを作成します",
    twoFactorAuth: "2つの要因Auth",
    createApp: "アプリを作成します",
    createAccount: "アカウントを作成する",
    activity: "アクティビティ",
    documentation: "ドキュメンテーション",
    components: "コンポーネント",
    resources: "資力",
    customers: "お客様のお客様",
    gettingStarted: "入門",
    customersListing: "顧客のリスト",
    customerDetails: "お客様の詳細",
    calendar: "カレンダー",
    subscriptions: "購読",
    getStarted: "入門",
    subscriptionList: "サブスクリプションリスト",
    addSubscription: "サブスクリプションを追加します",
    viewSubscription: "購読を見る",
  },
  fr: {
    dashboard: "Générateur de mise",
    layoutBuilder: "En page",
    craft: "Fabriqué",
    pages: "Pages",
    profile: "Profil",
    profileOverview: "Aperçu",
    projects: "Projets",
    campaigns: "Campagnes",
    documents: "Documents",
    connections: "Connexions",
    wizards: "Sorciers",
    horizontal: "Horizontal",
    vertical: "Verticale",
    account: "Compte",
    accountOverview: "Aperçu",
    settings: "Paramètres",
    authentication: "Authentification",
    basicFlow: "Flux de base",
    signIn: "SS'identifier",
    signUp: "Inscrivez-vous",
    passwordReset: "Réinitialisation du mot de passe",
    error404: "Erreur 404",
    error500: "Erreur 500",
    apps: "Applications",
    chat: "Discuter",
    privateChat: "Discussion privée",
    groupChat: "Discussion de groupe",
    drawerChat: "Chat de tiroir",
    widgets: "Widgets",
    widgetsLists: "Listes",
    widgetsStatistics: "Statistiques",
    widgetsCharts: "Graphiques",
    widgetsMixed: "Mixte",
    widgetsTables: "Les tables",
    widgetsFeeds: "Flux",
    changelog: "Journal des modifications",
    docsAndComponents: "Documents & composants",
    megaMenu: "Méga Menu",
    exampleLink: "Exemple de lien",
    modals: "Modals",
    general: "Général",
    inviteFriends: "Inviter Des Amis",
    viewUsers: "Voir Les Utilisateurs",
    upgradePlan: "Plan De Mise À Niveau",
    shareAndEarn: "Partager Et Gagner",
    forms: "Formes",
    newTarget: "Nouvelle Cible",
    newCard: "Nouvelle Carte",
    newAddress: "Nouvelle Adresse",
    createAPIKey: "Créer Une Touche Api",
    twoFactorAuth: "Deux Facteurs D'Authentification",
    createApp: "Créer Une Application",
    createAccount: "Créer Un Compte",
    activity: "Activité",
    documentation: "Documentation",
    components: "Composants",
    resources: "Ressources",
    customers: "Les Clients",
    gettingStarted: "Commencer",
    customersListing: "Liste Des Clients",
    customerDetails: "Détails Des Clients",
    calendar: "Calendrier",
    subscriptions: "Abonnements",
    getStarted: "Commencer",
    subscriptionList: "Liste D'Abonnement",
    addSubscription: "Ajouter Un Abonnement",
    viewSubscription: "Voir L'Abonnement",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
  warnHtmlMessage: false,
});

export default i18n;

import { computed } from "vue";
import store from "@/store/index";
import c from "@circuitid/common";

/**
 * Returns layout config
 * @returns {object}
 */
export const config = computed(() => {
  return store.getters.layoutConfig();
});

/**
 * Set the sidebar display
 * @returns {boolean}
 */
export const displaySidebar = computed(() => {
  return store.getters.layoutConfig("sidebar.display");
});

/**
 * Check if footer container is fluid
 * @returns {boolean}
 */
export const footerWidthFluid = computed(() => {
  return store.getters.layoutConfig("footer.width") === "fluid";
});

/**
 * Check if header container is fluid
 * @returns {boolean}
 */
export const headerWidthFluid = computed(() => {
  return store.getters.layoutConfig("header.width") === "fluid";
});

/**
 * Returns header left part type
 * @returns {string}
 */
export const headerLeft = computed(() => {
  return store.getters.layoutConfig("header.left");
});

/**
 * Returns header fixed on desktop
 * @returns {boolean}
 */
export const headerFixed = computed(() => {
  return store.getters.layoutConfig("header.fixed.desktop");
});

/**
 * Returns header fixed on tablet and mobile
 * @returns {boolean}
 */
export const headerFixedOnMobile = computed(() => {
  return store.getters.layoutConfig("header.fixed.tabletAndMobile");
});

/**
 * Set the aside display
 * @returns {boolean}
 */
export const asideDisplay = computed(() => {
  return store.getters.layoutConfig("aside.display") === true;
});

/**
 * Aside secondary minimized by default
 * @returns {boolean}
 */
export const minimizedAsideSecondary = computed(() => {
  return store.getters.layoutConfig("aside.minimized");
});

/**
 * Aside minimization enabled
 * @returns {boolean}
 */
export const minimizationEnabled = computed(() => {
  return store.getters.layoutConfig("aside.minimize");
});

/**
 * Aside secondary display
 * @returns {boolean}
 */
export const asideSecondaryDisplay = computed(() => {
  return store.getters.layoutConfig("aside.secondaryDisplay");
});

/**
 * Check if toolbar width is fluid
 * @returns {boolean}
 */
export const toolbarWidthFluid = computed(() => {
  return store.getters.layoutConfig("toolbar.width") === "fluid";
});

/**
 * Set the toolbar display
 * @returns {boolean}
 */
export const toolbarDisplay = computed(() => {
  return store.getters.layoutConfig("toolbar.display");
});

/**
 * Check if the page loader is enabled
 * @returns {boolean}
 */
export const loaderEnabled = computed(() => {
  return store.getters.layoutConfig("loader.display");
});

/**
 * Check if container width is fluid
 * @returns {boolean}
 */
export const contentWidthFluid = computed(() => {
  return store.getters.layoutConfig("content.width") === "fluid";
});

/**
 * Page loader logo image
 * @returns {string}
 */
export const loaderLogo = computed(() => {
  return process.env.BASE_URL + store.getters.layoutConfig("loader.logo");
});

/**
 * Check if the aside menu is enabled
 * @returns {boolean}
 */
export const asideEnabled = computed(() => {
  return !!store.getters.layoutConfig("aside.display");
});

/**
 * Set the aside theme
 * @returns {string}
 */
export const asideTheme = computed(() => {
  return store.getters.layoutConfig("aside.theme");
});

/**
 * Set the subheader display
 * @returns {boolean}
 */
export const subheaderDisplay = computed(() => {
  return store.getters.layoutConfig("toolbar.display");
});

/**
 * Set the aside menu icon type
 * @returns {string}
 */
export const asideMenuIcons = computed(() => {
  return store.getters.layoutConfig("aside.menuIcon");
});

/**
 * Light theme logo image
 * @returns {string}
 */
export const themeLightLogo = computed(() => {
  return store.getters.layoutConfig("main.logo.light");
});

/**
 * Dark theme logo image
 * @returns {string}
 */
export const themeDarkLogo = computed(() => {
  return store.getters.layoutConfig("main.logo.dark");
});

/**
 * Set the header menu icon type
 * @returns {string}
 */
export const headerMenuIcons = computed(() => {
  return store.getters.layoutConfig("header.menuIcon");
});

/**
 * Illustrations set
 * @returns {string}
 */
export const illustrationsSet = computed(() => {
  return store.getters.layoutConfig("illustrations.set");
});

/**
 * Circuit ID config
 */

export const commonLgLabelDivClass = "col-12 col-xl-2 col-lg-2 col-md-3";
export const commonLgInputDivClass = "col-12 col-xl-4 col-lg-6 col-md-6";

export const commonLabelDivClass = "col-12 col-xl-3 col-lg-4 col-md-3";
export const commonInputDivClass = "col-12 col-xl-4 col-lg-6 col-md-6";

export const commonDrawerLabelDivClass = "col-12 col-xl-4 col-lg-4 col-md-4";
export const commonDrawerInputDivClass = "col-12 col-xl-8 col-lg-8 col-md-8";

export const commonPageLabelDivClass = "col-lg-4";
export const commonPageInputDivClass = "col-lg-8";

export const smsMessageMaxFiles = 10;
export const smsMessageMaxFileSize = 1000000;

export const useDataCache = true; // caches data from the api and refresh
export const dev =
  (!c.arrayEmpty(location) && location.hostname) === "localhost" ? true : false;
export const portalHostname = !dev ? "console.circuitid.com" : "localhost:8080";
export const hostname = !dev ? "rest.circuitid.com" : "cloud9.circuitid.com";
export const ssl = true;
export const port = 443;
export const apiUrl =
  (ssl === true ? "https" : "http") + "://" + hostname + ":" + port;

export const portalUrl =
  (ssl === true ? "https" : "http") + "://" + portalHostname;
export const mediaPath = "/media/";
export const cardLogoPath = mediaPath + "svg/card-logos/";
export const alertTimer = 3000;
export const primaryMenuBgColor = "#12344d";
export const enabledPlugins = {};
export const plugins = [
  {
    name: "freshdesk",
    import: "freshdesk-client",
    token: "9261e3ca-1642-4607-831a-8ffed6ae18a5",
    host: "https://circuitid.freshchat.com",
    enabled: false,
    lifecycle: "startup",
    hideByDefault: true,
    defaultSupportChat: false,
  },
  {
    appId: "l1lajc5v",
    name: "intercom",
    import: "intercom-client",
    enabled: true,
    lifecycle: "startup",
    hideByDefault: true,
    defaultSupportChat: true,
  },
  {
    name: "meta",
    import: "meta-client",
    enabled: true,
    lifecycle: "authenticated",
  },
];

export const getPlugin = (param) => {
  if (c.dependencyMissing((param = c.toLower(param)))) return {};

  for (let i = 0; i < plugins.length; i++)
    if (param === plugins[i].name) return plugins[i];
};
export const termHeaderTemplate = () => {
  return `<table>
<tr>
<th style='min-width:75px;'>Billing</th>
<th style='min-width:85px;'>Commitment</th>
<th style='min-width:65px;'>Amount</th>
</tr>
</table>`;
};
export const termItemTemplate = (d, trial = false) => {
  if (c.arrayEmpty(d)) return "";

  const billInterval = c.isPositiveNum(d.billInterval) ? d.billInterval : "N/A";
  const billIntervalType =
    c.isPositiveNum(d.billInterval) && !c.dependencyMissing(d.billIntervalType)
      ? d.billInterval > 1
        ? c.ucFirst(d.billIntervalType)
        : c.ucFirst(d.billIntervalType.replace("s", ""))
      : "";

  const commitmentInterval = c.isPositiveNum(d.commitmentInterval)
    ? d.commitmentInterval
    : "N/A";
  const commitmentIntervalType =
    c.isPositiveNum(d.commitmentInterval) &&
    !c.dependencyMissing(d.billIntervalType)
      ? d.commitmentInterval > 1
        ? c.ucFirst(d.commitmentIntervalType)
        : c.ucFirst(d.commitmentIntervalType.replace("s", ""))
      : "";

  return (
    `<table>
<tr>
  <td style="min-width:75px;">` +
    billInterval +
    " " +
    billIntervalType +
    `</td>
  <td style="min-width:85px;">` +
    commitmentInterval +
    " " +
    commitmentIntervalType +
    `</td>
  <td style="min-width:65px;">` +
    c.formatCurrency(d.amount) +
    `</td>` +
    (trial
      ? `<td style="min-width:35px;">` + (d.trial ? "Yes" : "No") + `</td>`
      : ``) +
    `</tr>
</table>`
  );
};
export const termValueTemplate = (d) => {
  if (c.arrayEmpty(d)) return "";

  if (
    !c.isPositiveNum(d.billInterval) ||
    c.dependencyMissing(d.commitmentIntervalType)
  )
    return c.formatCurrency(d.amount);

  return (
    c.formatCurrency(d.amount) +
    " every " +
    (d.billInterval > 1 ? c.getInt(d.billInterval) + " " : "") +
    (d.billInterval > 1
      ? c.trimString(d.billIntervalType)
      : c.trimString(d.billIntervalType).replace("s", "")) +
    ", " +
    d.commitmentInterval +
    " " +
    d.commitmentIntervalType.replace("s", "") +
    " term"
  );
};

const getDefaultTemplateLabel = (s) => {
  return (
    '<div class="d-flex flex-column align-items-start justify-content-center mb-3"><span class="text-black fs-7 lh-1 mb-1 pt-2 pb-1" >' +
    s +
    "</span>"
  );
};

export const userItemTemplate = (d, e, m, x, f, showUserType = false) => {
  if (c.arrayEmpty(d) || c.arrayEmpty(d.data))
    return getDefaultTemplateLabel(d.name) + "</div>";

  d = d.data;

  let item = getDefaultTemplateLabel(d.displayName);

  if (!c.dependencyMissing(d.email) && e)
    item +=
      '<span class="text-gray-500 lh-1 pb-2"><i class="far fa-envelope"></i> ' +
      d.email +
      "</span>";

  if (!c.dependencyMissing(d.faxPhone) && f)
    item +=
      '<span class="text-gray-500 lh-1 pb-2"><i class="fas fa-fax"></i> ' +
      c.getFormattedPhoneNumber(d.faxPhone) +
      "</span>";

  if (!c.dependencyMissing(d.mobilePhone) && m)
    item +=
      '<span class="text-gray-500 lh-1 pb-2"><i class="fas fa-mobile-screen"></i> ' +
      c.getFormattedPhoneNumber(d.mobilePhone) +
      "</span>";

  if (!c.dependencyMissing(d.extension) && x)
    item +=
      '<span class="text-gray-500 lh-1 pb-2"><i class="fa fa-phone"></i> ' +
      d.extension +
      "</span>";

  if (showUserType && !c.dependencyMissing(d.userType))
    item +=
      '<span class="text-gray-500 lh-1 pb-2"><i class="fa fa-user"></i> ' +
      (c.toLower(d.userType) === "contacts" ? "Contact" : "User") +
      "</span>";

  item += "</div>";

  return item;
};
export const userValueTemplate = (d) => {
  if (c.arrayEmpty(d) || c.arrayEmpty(d.data)) return d;

  d = d.data;

  return d.displayName;
};

const getFaxLabel = (d) => {
  return isNaN(d) ? d : c.getFormattedPhoneNumber(d);
};

export const faxItemTemplate = (d: any) => {
  d = c.cloneObject(d);

  if (c.arrayEmpty(d) && c.dependencyMissing(d)) return "";
  else if (c.arrayEmpty(d)) return getDefaultTemplateLabel(getFaxLabel(d));

  d.name = getFaxLabel(d.name);

  let item = getDefaultTemplateLabel(d.name);

  if (!c.arrayEmpty(d.data) && !c.dependencyMissing(d.data.faxPhone))
    item +=
      '<span class="text-gray-500"><i class="fas fa-fax"></i> ' +
      c.getFormattedPhoneNumber(d.data.faxPhone) +
      "</span>";

  item += "</div>";

  return item;
};

export const faxRecipientValueTemplate = (d) => {
  return getFaxLabel(d.name);
};

export const maxSidebarTitleLength = 18;
export const maxSidebarSubTitleLength = 25;
export const maxContactItemListSubTitleLength = 150;
export const phoneDrawerId = "phoneID_viewDrawer";
export const phoneDailpadDrawerId = "phoneDialpadID_viewDrawer";
export const changeListGroupDrawerId = "changeListGroup_viewDrawer";
export const changeConferencePasscodeDrawerId =
  "changeConferencePasscode_viewDrawer";
export const calendarItemDrawerId = "calendarItem_viewDrawer";
export const phoneMicCameraSettingsDrawerId =
  "phoneMicCameraSettings_viewDrawer";
export const recentDrawerId = "recentDrawerId_viewDrawer";
export const removeDeveloperAppSubscriptionDrawerId =
  "removeDeveloperAppSubscriptionDrawerId_viewDrawer";
export const removeDeveloperAppId = "removeDeveloperAppSubscription";
export const addDeveloperAppSubscriptionDrawerId =
  "addDeveloperAppSubscription";
export const viewCdrTranscriptsDrawerId = "viewCdrTranscriptsDrawerId";
export const changeCallerIdDrawerId = "changeCallerIdDrawerId";
export const typingDebounceTime = 1000;
export const isTypingDebounceTime = 200;
export const typingDebounceShowTime = 4500;

export const awsRumConfig = {
  sessionSampleRate: 1,
  guestRoleArn:
    "arn:aws:iam::877563506820:role/RUM-Monitor-us-east-1-877563506820-4414496862761-Unauth",
  identityPoolId: "us-east-1:e8af82ec-df96-4525-8e18-241e06ba65f9",
  endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
  telemetries: ["performance", "errors", "http"],
  allowCookies: true,
  enableXRay: false,
  appId: "f9cf10bf-6ff3-4e70-92c7-d210503bcf55",
  appVersion: "1.0.0",
  appRegion: "us-east-1",
};

export const syncfusionLicense =
  "Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdmWX1edXVRQ2RfUUVzV0A=";

export const icon = mediaPath + "logos/favicon.ico";

export const maxCallerIdNameLength = 20;
export const maxCallerIdNumberLength = 20;
export const lastVisitedPageVar = "lastVisitedPage";
export const defaultFindLimit = 20;

export const soundPath = mediaPath + "sounds/";
export const sounds = {
  successAlert: "on_success_alert.wav",
  errorAlert: "on_error_alert.wav",
};
export const electronMinimalRoutes = ["electron-call-manager"];
export const defaultAvatar = mediaPath + "avatars/default.jpg";

export const minNumberPortingDays = computed(() => {
  return c.isPositiveNum(store.getters.info.numberports.minDesiredDueDateDays)
    ? c.getInt(store.getters.info.numberports.minDesiredDueDateDays)
    : 3;
});

export const maxNumberPortingDays = computed(() => {
  return c.isPositiveNum(store.getters.info.numberports.maxDesiredDueDateDays)
    ? c.getInt(store.getters.info.numberports.maxDesiredDueDateDays)
    : 30;
});

export const resendCodeSeconds = 15;

export const thumbnails = {
  doc: mediaPath + "icons/thumbnail-doc.png",
  video: mediaPath + "icons/thumbnail-video.png",
  unknown: mediaPath + "icons/thumbnail-unknown.png",
};

export const minPresenceIdleSeconds: number = 60000;

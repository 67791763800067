import c from "@circuitid/common";
import store from "@/store";
import {
  maxSidebarTitleLength,
  maxSidebarSubTitleLength,
  defaultAvatar,
} from "@/core/helpers/config";
import { getName, getListItemDate } from "@/core/helpers/functions";

export const formatUser = ({ ...d }) => {
  if (c.arrayEmpty(d)) return {};

  const user = {
    _id: d._id,
    email: d.email,
    name: getName(d),
    avatar: d.avatar,
    customerId: d.customerId,
    status: d.status,
    mobilePhone: d.mobilePhone,
    extension: d.extension,
    isUser: d.isUser ? true : false,
  };

  if (c.dependencyMissing(user.name) && !c.dependencyMissing(user.mobilePhone))
    user.name = user.mobilePhone;

  user.username = user.name;

  if (c.dependencyMissing(d.avatar) && !isNaN(d.username))
    user.avatar = defaultAvatar;

  return user;
};

export const deleteContact = (_id, roomId) => {
  const service = "conversations";
  const room = {
    ...store.getters.getObjectById({ _id: roomId, service: service }),
  };

  if (c.arrayEmpty(room) || c.arrayEmpty(room.users)) return;

  if (room.users.length === 1)
    return c.showError(
      "You can not delete the last contact.  Delete the conversation instead.",
    );

  if (c.trimString(_id) === c.trimString(store.getters.userId))
    return c.showError(
      "You can not remove yourself from the conversation.  Delete the conversation instead.",
    );

  room.users = room.users.filter((a) => a._id != _id);

  store.dispatch("patchArrayObject", {
    key: service,
    value: room,
  });

  store
    .dispatch("patchData", {
      service: service,
      data: {
        $pull: {
          contacts: { _id: c.getObjectIdById(_id) },
        },
      },
      id: roomId,
    })
    .catch((e) => {
      console.error(e);
    });
};

export const loadFirstRoom = (router) => {
  if (!c.arrayEmpty(store.getters.conversationData)) {
    const id = router.currentRoute.value.params.id;
    const index = c.inArray("_id", id, store.getters.conversationData);

    if (
      c.isValidObjectId(id) &&
      !c.arrayEmpty(store.getters.conversationData[index]) &&
      store.getters.conversationData[index].chatroomService === "conversations"
    )
      return;

    for (const i in store.getters.conversationData)
      if (
        !c.arrayEmpty(store.getters.conversationData[i]) &&
        c.isValidObjectId(store.getters.conversationData[i]._id) &&
        c.toLower(
          store.getters.conversationData[i].chatroomService === "conversations",
        )
      ) {
        router.push({
          path: "/conversations/" + store.getters.conversationData[0]._id,
        });
        return;
      }
  }

  router.push({ path: "/conversations/welcome" });
};

const getMessageFrom = (d) => {
  const from = {};
  if (
    c.arrayEmpty(d) ||
    !c.isValidObjectId(d.conversation) ||
    c.arrayEmpty(d.contacts) ||
    c.dependencyMissing(d.direction)
  )
    return from;

  if (d.direction === "inbound") {
    if (!c.isValidObjectId(d.contacts[0])) return from;

    const contact = getContact({
      conversation: d.conversation,
      _id: d.contacts[0],
    });

    from._id = contact._id;
    from.name = contact.name;
    from.avatar = contact.avatar;
  } else {
    if (
      c.arrayEmpty(d.user) ||
      (c.dependencyMissing(d.user.displayName) &&
        c.dependencyMissing(d.user.first) &&
        c.dependencyMissing(d.user.last))
    )
      return from;

    from._id = d.user._id;
    from.avatar = d.user.avatar;

    if (d.user.displayName) from.name = d.user.displayName;
    else from.name = d.user.first + " " + d.user.last;
  }
  return from;
};

const getConversationFrom = (d) => {
  const from = {};
  if (
    c.arrayEmpty(d) ||
    c.arrayEmpty(d.contacts) ||
    c.arrayEmpty(d.lastMessage.contacts) ||
    c.arrayEmpty(d.lastMessage) ||
    c.dependencyMissing(d.lastMessage.direction)
  )
    return from;

  if (d.lastMessage.direction === "inbound") {
    if (
      c.arrayEmpty(d.contacts) ||
      !c.isValidObjectId(d.lastMessage.contacts[0])
    )
      return from;

    for (const contact of d.contacts)
      if (d.lastMessage.contacts[0] === contact._id) {
        from._id = contact._id;
        from.name = from.subTitle = getName(contact);
        from.avatar = contact.avatar;
      }
  } else {
    if (
      c.arrayEmpty(d.lastMessage.user) ||
      c.dependencyMissing(d.lastMessage.user.displayName)
    )
      return from;

    from._id = d.lastMessage.user._id;
    from.avatar = d.lastMessage.user.avatar;
    from.name = from.subTitle = d.lastMessage.user.displayName;

    if (d.lastMessage.user._id === store.getters.userId) {
      from.subTitle = "You";
    }
  }
  return from;
};

const getContact = (d) => {
  if (
    c.arrayEmpty(d) ||
    !c.isValidObjectId(d.conversation) ||
    !c.isValidObjectId(d._id)
  )
    return {};

  const conversation = store.getters.getObjectById({
    _id: d.conversation,
    service: "conversations",
  });

  if (c.arrayEmpty(conversation) || c.arrayEmpty(conversation.users)) return {};

  for (const user of conversation.users) if (user._id === d._id) return user;

  return {};
};

export const conversationFormatter = function (d) {
  //console.log("conversationFormatter1", d);
  if (
    c.arrayEmpty(d) ||
    c.arrayEmpty(d.contacts) ||
    c.arrayEmpty(d.lastMessage) ||
    c.arrayEmpty(d.lastMessage.message) ||
    c.arrayEmpty(d.lastMessage.contacts) ||
    c.arrayEmpty(d.contacts) ||
    c.dependencyMissing(d.lastMessage.createdAt) ||
    c.dependencyMissing(d.lastMessage.direction)
  )
    return;

  const from = getConversationFrom(d);

  if (c.arrayEmpty(from)) return;

  if (c.arrayEmpty(d.conversationmessages)) d.conversationmessages = [{}];

  const updatedAt =
    !c.arrayEmpty(d.lastMessage) &&
    !c.dependencyMissing(d.lastMessage.createdAt)
      ? d.lastMessage.createdAt
      : d.updatedAt;

  let unread =
    !c.arrayEmpty(d.unread) &&
    !c.arrayEmpty(d.unread[store.getters.userId]) &&
    c.isPositiveNum(d.unread[store.getters.userId].count)
      ? c.getInt(d.unread[store.getters.userId].count)
      : 0;

  const existingRoom = store.getters.getObjectById({
    _id: d._id,
    service: "conversations",
  });

  if (!c.arrayEmpty(existingRoom)) {
    unread = existingRoom.unread || 0;

    if (
      (c.arrayEmpty(store.getters.currentChatRoom) ||
        c.trimString(store.getters.currentChatRoom._id) !==
          c.trimString(d._id)) &&
      !c.arrayEmpty(d.lastMessage) &&
      c.isValidObjectId(d.lastMessage._id) &&
      !c.inArray("_id", d._id, store.getters.conversationmessageData)
    )
      unread++;
  }

  const unreadHtml = c.isPositiveNum(unread)
    ? '<span class="badge badge-sm badge-circle badge-danger me-2">' +
      (c.getInt(unread) <= 99 ? c.getInt(unread) : "99+") +
      "</span>"
    : "";
  d.lastConversationMessageDate = !c.dependencyMissing(d.lastMessage.updatedAt)
    ? parseInt(c.moment()(d.lastMessage.updatedAt).format("x"))
    : undefined;
  d.chatroomService = "conversations";
  d.link = "/conversations/" + d._id;
  d.roomId = d._id;
  d.unread = unread;
  d.show = true;
  d.updatedAt = updatedAt;
  d.items = [];
  d.users = [
    formatUser(c.mergeObjects(store.getters.currentUser, { isUser: true })),
  ];

  switch (d.lastMessage.type) {
    case "text":
      d.subTitle = c.getMaxText(
        from.subTitle + ": " + d.lastMessage.message.text,
        maxSidebarSubTitleLength,
      );
      break;
    case "media":
      d.subTitle =
        c.getMaxText(from.subTitle, maxSidebarSubTitleLength - 6) + ": Image";
      break;
  }

  d.rightContent = unreadHtml + getListItemDate(updatedAt);

  if (
    !c.arrayEmpty(d.createdByUserId) &&
    c.isValidObjectId(d.createdByUserId._id) &&
    c.trimString(d.createdByUserId._id) !==
      c.trimString(store.getters.currentUser._id)
  )
    d.users.push(
      formatUser(c.mergeObjects(d.createdByUserId, { isUser: true })),
    );

  for (const j in d.contacts)
    try {
      if (c.arrayEmpty(d.contacts[j])) continue;

      d.contacts[j].name = d.contacts[j].title = getName(d.contacts[j]);

      if (
        c.dependencyMissing(d.contacts[j].avatar) &&
        !isNaN(d.contacts[j].name)
      )
        d.contacts[j].avatar = defaultAvatar;

      d.items.push(d.contacts[j]);
    } catch (e) {
      console.error(e);
    }

  if (!c.arrayEmpty(d.items)) d.users = c.mergeArrays(d.items, d.users);

  let name = "";

  for (const user of d.users)
    if (
      !c.arrayEmpty(user) &&
      !c.dependencyMissing(user.name) &&
      c.trimString(store.getters.currentUser._id) !== c.trimString(user._id)
    )
      name += user.name + ", ";

  name = c.rtrim(name, ", ");

  d.name = d.roomName = d.title = c.getMaxText(name, maxSidebarTitleLength);

  d.lastMessage = messageFormatter(d.lastMessage, true);

  //console.log("conversationFormatter2", d);
  return d;
};

export const messageFormatter = (d, isConversationLastMessage = false) => {
  //console.log("messageFormatter1", d);

  if (
    c.arrayEmpty(d) ||
    c.arrayEmpty(d.contacts) ||
    c.arrayEmpty(d.message) ||
    !c.isValidObjectId(d.conversation) ||
    !c.isValidObjectId(d._id)
  )
    return d;

  d.content = "";

  switch (d.type) {
    case "text":
      if (c.dependencyMissing(d.message.text)) return d;

      d.content = d.message.text;

      break;
    case "media":
      if (isConversationLastMessage) break;

      if (
        c.arrayEmpty(d.message.file) ||
        c.dependencyMissing(d.message.file.name) ||
        c.dependencyMissing(d.message.file.url)
      ) {
        console.log("file empty", d);
        return d;
      }

      d.files = [
        {
          name: d.message.file.name,
          size: d.message.file.size,
          type: d.message.file.contentType,
          url: d.message.file.url,
          preview: d.message.file.preview,
        },
      ];

      break;
    default:
      return d;
  }

  const from = getMessageFrom(d);

  d.chatroom = d.conversation;
  d.senderId = from._id;
  d.username = from.name;
  d.date = c.moment()(d.createdAt).format("dddd, MMMM Do YYYY");
  d.timestamp = c.moment()(d.createdAt).format("LT");
  d.reactions = !c.arrayEmpty(d.reactions) ? d.reactions : {};
  d.distributed =
    d.status === "processed" &&
    (d.direction === "inbound" ||
      (!c.arrayEmpty(d.delivery) && !c.arrayEmpty(d.delivery.sent)));
  d.failure = d.status === "failed";
  d.saved = ["processed", "processing", "failed"].includes(d.status);

  if (c.arrayEmpty(d.delivery)) d.delivery = {};

  if (d.direction === "outbound") {
    if (
      c.trimString(from._id) === c.trimString(store.getters.userId) ||
      (!c.arrayEmpty(d.delivery.read) &&
        (d.delivery.read.length > 1 ||
          !c.inArray("_id", store.getters.userId, d.delivery.read)))
    )
      d.seen = true;
    else d.seen = false;
  } else {
    if (
      !c.arrayEmpty(d.delivery.read) &&
      c.inArray("_id", store.getters.userId, d.delivery.read)
    )
      d.seen = true;
    else d.seen = false;
  }

  if (!c.dependencyMissing(from.avatar)) d.avatarUrl = from.avatar;

  if (c.dependencyMissing(d.avatarUrl) && !isNaN(d.username))
    d.avatarUrl = defaultAvatar;

  if (!c.arrayEmpty(d.reply)) d.replyMessage = messageFormatter(d.reply);

  //console.log("messageFormatter2", d);
  return d;
};

export const sendMessageFormatter = (d) => {
  if (c.arrayEmpty(d) || c.dependencyMissing(d.type)) return;

  if (c.arrayEmpty(d.contacts)) {
    if (!c.isValidObjectId(d.conversation)) return;

    const conversation = store.getters.getObjectById({
      _id: d.conversation,
      service: "conversations",
    });

    if (c.arrayEmpty(conversation) || c.arrayEmpty(conversation.contacts))
      return;

    d.contacts = [];

    for (const i in conversation.contacts)
      if (
        !c.arrayEmpty(conversation.contacts[i]) &&
        c.isValidObjectId(conversation.contacts[i]._id)
      )
        d.contacts.push(conversation.contacts[i]._id);

    if (c.arrayEmpty(d.contacts)) return;
  }

  for (const i in d.contacts) d.contacts[i] = { _id: d.contacts[i] };

  d.user = d.from;

  delete d.from;

  let file;

  switch (d.type) {
    case "media":
      if (!c.arrayEmpty(d.file) && !c.dependencyMissing(d.file.name)) {
        file = d.file;

        delete d.file;
      } else if (
        !c.arrayEmpty(d.attachments) &&
        !c.arrayEmpty(d.attachments[0]) &&
        c.isValidObjectId(d.attachments[0]._id)
      ) {
        file = d.attachments[0]._id;
      } else return;

      d.message = {
        file: file,
      };

      break;
    default:
      d.message = {
        text: d.text,
      };
      break;
  }

  return d;
};

import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  RouteRecordRaw,
} from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import c from "@circuitid/common";
import { defaultPagePage } from "@/core/helpers/functions.js";
import {
  lastVisitedPageVar,
  electronMinimalRoutes,
} from "@/core/helpers/config";
import { useService } from "@/store/feathers";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    alias: ["/index.html"],
    redirect: defaultPagePage(),
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/organization/layout-settings",
        name: "layout-settings",
        component: () => import("@/views/organization/LayoutSettings.vue"),
      },
      {
        path: "/organization/sites",
        name: "sites",
        component: () => import("@/views/organization/Sites.vue"),
      },
      {
        path: "/organization/templates/:id",
        name: "template-object",
        component: () => import("@/components/templates/Template.vue"),
        children: [
          {
            path: "/organization/templates/:id/general",
            name: "template-general",
            component: () =>
              import("@/components/templates/TemplateGeneral.vue"),
          },
        ],
      },
      {
        path: "/organization/sites/:id",
        name: "site",
        component: () => import("@/components/sites/Site.vue"),
        children: [
          {
            path: "/organization/sites/:id/general",
            name: "site-general",
            component: () => import("@/components/sites/SiteGeneral.vue"),
          },
          {
            path: "/organization/sites/:id/settings",
            name: "site-settings",
            component: () => import("@/components/sites/SiteSettings.vue"),
          },
          {
            path: "/organization/sites/:id/signup",
            name: "site-signup",
            component: () => import("@/components/sites/SiteSignUp.vue"),
          },
          {
            path: "/organization/sites/:id/email",
            name: "site-email",
            component: () => import("@/components/sites/SiteEmail.vue"),
          },
          {
            path: "/organization/sites/:id/urls",
            name: "site-urls",
            component: () => import("@/components/sites/SiteUrls.vue"),
          },
          {
            path: "/organization/sites/:id/menus",
            name: "site-menus",
            component: () => import("@/components/sites/SiteMenus.vue"),
          },
          {
            path: "/organization/sites/:id/menus/:sitemenu/groups",
            name: "site-menu-groups",
            component: () => import("@/components/sites/SiteMenuGroups.vue"),
          },
          {
            path: "/organization/sites/:id/menus/:sitemenu/groups/:sitemenugroup",
            name: "site-menu-group-pages",
            component: () =>
              import("@/components/sites/SiteMenuGroupPages.vue"),
          },
        ],
      },
      {
        path: "/billing/order",
        name: "add-orders",
        component: () => import("@/views/create/CreateResource.vue"),
      },
      {
        path: "/billing/orders",
        name: "orders",
        component: () => import("@/views/billing/Orders.vue"),
      },
      {
        path: "/billing/invoices",
        name: "invoices",
        component: () => import("@/views/billing/Invoices.vue"),
      },
      {
        path: "/billing/invoices/:id",
        name: "invoice",
        component: () => import("@/components/invoices/Invoice.vue"),
        children: [
          {
            path: "/billing/invoices/:id/items",
            name: "invoice-items",
            component: () => import("@/components/invoices/InvoiceItems.vue"),
          },
          {
            path: "/billing/invoices/:id/payments",
            name: "invoice-payments",
            component: () =>
              import("@/components/invoices/InvoicePayments.vue"),
          },
        ],
      },
      {
        path: "/billing/payment-methods",
        name: "payment-methods",
        component: () => import("@/views/billing/PaymentMethods.vue"),
      },
      {
        path: "/organization/faxes",
        name: "faxes",
        component: () => import("@/views/fax/Faxes.vue"),
      },
      {
        path: "/faxaccounts/welcome/dashboard",
        name: "fax-accounts-welcome",
        component: () =>
          import("@/components/faxaccounts/FaxAccountsWelcome.vue"),
      },
      {
        path: "/organization/faxaccounts",
        name: "fax-accounts",
        component: () => import("@/views/fax/FaxAccounts.vue"),
      },
      {
        path: "/faxaccounts/:id",
        name: "fax-accounts-dashboard",
        component: () => import("@/components/faxaccounts/Dashboard.vue"),
        children: [
          {
            path: "/faxaccounts/:id/all",
            name: "fax-accounts-dashboard-faxes-all",
            component: () =>
              import("@/components/faxaccounts/FaxAccountFaxesAll.vue"),
          },
          {
            path: "/faxaccounts/:id/inbound",
            name: "fax-accounts-dashboard-faxes-inbound",
            component: () =>
              import("@/components/faxaccounts/FaxAccountFaxesInbound.vue"),
          },
          {
            path: "/faxaccounts/:id/outbound",
            name: "fax-accounts-dashboard-faxes-outbound",
            component: () =>
              import("@/components/faxaccounts/FaxAccountFaxesOutbound.vue"),
          },
          {
            path: "/faxaccounts/:id/settings",
            name: "fax-accounts-dashboard-settings",
            component: () => import("@/components/objects/AboutFaxAccount.vue"),
          },
          {
            path: "/faxaccounts/:id/acceptedsenders",
            name: "fax-accounts-dashboard-accepted-senders",
            component: () =>
              import("@/components/faxaccounts/AcceptedSenders.vue"),
          },
        ],
      },
      {
        path: "/network/firewall",
        name: "firewall",
        component: () => import("@/views/network/Firewall.vue"),
      },
      {
        path: "/organization/violations",
        name: "violations",
        component: () => import("@/views/network/Violations.vue"),
      },
      {
        path: "/organization/violations/:id",
        name: "violation",
        component: () => import("@/components/violations/Violation.vue"),
        children: [
          {
            path: "/organization/violations/:id/general",
            name: "violation-general",
            component: () =>
              import("@/components/violations/ViolationGeneral.vue"),
          },
          {
            path: "/organization/violations/:id/comments",
            name: "violation-comments",
            component: () =>
              import("@/components/violations/ViolationComments.vue"),
          },
          {
            path: "/organization/violations/:id/callstats",
            name: "violation-call-stats",
            component: () =>
              import("@/components/violations/ViolationCallStats.vue"),
          },
        ],
      },
      {
        path: "/organization/app",
        name: "apps-patch",
        component: () => import("@/components/apps/App.vue"),
        children: [
          {
            path: "/organization/apps/:id/general",
            name: "app-general",
            component: () => import("@/components/apps/AppGeneral.vue"),
          },
          {
            path: "/organization/apps/:id/permissions",
            name: "app-permissions",
            component: () => import("@/components/apps/AppPermissions.vue"),
          },
          {
            path: "/organization/apps/:id/senders",
            name: "app-senders",
            component: () => import("@/components/apps/AppSenders.vue"),
          },
          {
            path: "/organization/apps/:id/auth",
            name: "app-auth",
            component: () => import("@/components/apps/AppAuth.vue"),
          },
          {
            path: "/organization/apps/:id/legal",
            name: "app-legal",
            component: () => import("@/components/apps/AppLegal.vue"),
          },
          {
            path: "/organization/apps/:id/pricing",
            name: "app-pricing",
            component: () => import("@/components/apps/AppPricing.vue"),
          },
          {
            path: "/organization/apps/:id/messaging",
            name: "app-messaging",
            component: () => import("@/components/apps/AppMessaging.vue"),
          },
        ],
      },
      {
        path: "/organization/apps",
        name: "apps",
        component: () => import("@/views/organization/Apps.vue"),
      },
      {
        path: "/apps",
        name: "app-marketplace",
        component: () =>
          import("@/components/appmarketplace/AppMarketplace.vue"),
      },
      {
        path: "/organization/brands",
        name: "brands",
        component: () => import("@/views/organization/Brands.vue"),
      },
      {
        path: "/organization/brand",
        name: "add-messagebrands",
        component: () => import("@/views/create/CreateResource.vue"),
      },
      {
        path: "/organization/campaigns",
        name: "campaigns",
        component: () => import("@/views/organization/Campaigns.vue"),
      },
      {
        path: "/organization/campaign",
        name: "add-messagecampaigns",
        component: () => import("@/views/create/CreateResource.vue"),
      },
      {
        path: "/organization/logs",
        name: "logs",
        component: () => import("@/views/organization/Logs.vue"),
      },
      {
        path: "/organization/settings",
        name: "settings",
        component: () => import("@/views/organization/Settings.vue"),
      },
      {
        path: "/organization/customers/:id",
        name: "customer",
        component: () => import("@/views/organization/Customer.vue"),
      },
      {
        path: "/organization/customers",
        name: "customers",
        component: () => import("@/views/organization/Customers.vue"),
      },
      {
        path: "/organization/customer",
        name: "add-customers",
        component: () => import("@/views/create/CreateResource.vue"),
      },
      {
        path: "/billing/organization",
        name: "billing",
        component: () => import("@/views/organization/Customer.vue"),
      },
      {
        path: "/organization/group",
        name: "add-groups",
        component: () => import("@/views/create/CreateResource.vue"),
      },
      {
        path: "/organization/groups",
        name: "groups",
        component: () => import("@/views/organization/Groups.vue"),
      },
      {
        path: "/organization/groups/:id",
        name: "group",
        component: () => import("@/components/groups/Group.vue"),
        children: [
          {
            path: "/organization/groups/:id/:ref",
            name: "group-members",
            component: () =>
              import("@/components/groups/GroupMembersResource.vue"),
          },
        ],
      },
      {
        path: "/organization/offices",
        name: "offices",
        component: () => import("@/views/organization/Offices.vue"),
      },
      {
        path: "/organization/domains",
        name: "domains",
        component: () => import("@/views/organization/Domains.vue"),
      },
      {
        path: "/organization/domains/:id/",
        name: "PatchDomain",
        component: () => import("@/components/domains/Domain.vue"),
        children: [
          {
            path: "/organization/domains/:id/:ref",
            name: "domain-verify",
            component: () => import("@/components/domains/DNSRecords.vue"),
          },
        ],
      },
      {
        path: "/organization/offices/:id",
        name: "office",
        component: () => import("@/views/organization/Office.vue"),
        meta: { reuse: false },
      },
      {
        path: "/organization/faxaccount",
        name: "add-faxaccounts",
        component: () => import("@/views/create/CreateResource.vue"),
      },
      {
        path: "/organization/user",
        name: "add-users",
        component: () => import("@/views/create/CreateResource.vue"),
      },
      {
        path: "/organization/users",
        name: "users",
        component: () => import("@/views/organization/Users.vue"),
      },
      {
        path: "/organization/users/:id",
        name: "user",
        component: () => import("@/components/users/User.vue"),
        children: [
          {
            path: "/organization/users/:id/general",
            name: "user-general",
            component: () => import("@/components/users/UserGeneral.vue"),
          },
          {
            path: "/organization/users/:id/signin",
            name: "user-signin",
            component: () => import("@/components/users/UserSignIn.vue"),
          },
          {
            path: "/organization/users/:id/phone",
            name: "user-phone",
            component: () => import("@/components/users/UserPhone.vue"),
          },
          {
            path: "/organization/users/:id/voicemail",
            name: "user-voicemail",
            component: () => import("@/components/users/UserVoicemail.vue"),
          },
          {
            path: "/organization/users/:id/licenses",
            name: "user-licenses",
            component: () => import("@/components/users/UserLicenses.vue"),
          },
          {
            path: "/organization/users/:id/notifications",
            name: "user-notifications",
            component: () => import("@/components/users/UserNotifications.vue"),
          },
          {
            path: "/organization/users/:id/permissions",
            name: "user-permissions",
            component: () => import("@/components/users/UserPermissions.vue"),
          },
          {
            path: "/organization/users/:id/tokens",
            name: "user-tokens",
            component: () => import("@/components/users/UserTokens.vue"),
          },
          {
            path: "/organization/users/:id/stats",
            name: "user-stats",
            component: () => import("@/components/users/UserStats.vue"),
          },
          {
            path: "/organization/users/:id/settings",
            name: "user-settings",
            component: () => import("@/components/users/UserSettings.vue"),
          },
          {
            path: "/organization/users/:id/selectnumber",
            name: "user-select-number",
            component: () => import("@/views/create/CreateResource.vue"),
          },
        ],
      },
      {
        path: "/more/messages",
        name: "messages",
        component: () => import("@/views/more/Messages.vue"),
      },
      {
        path: "/messages",
        name: "messages-main",
        component: () => import("@/views/more/Messages.vue"),
      },
      {
        path: "/contacts",
        name: "contacts",
        component: () => import("@/views/more/Contacts.vue"),
      },
      {
        path: "/more/contacts",
        name: "contacts",
        component: () => import("@/views/more/Contacts.vue"),
      },
      {
        path: "/more/contacts/:id",
        name: "contact",
        component: () => import("@/components/contacts/ContactWrapper.vue"),
        children: [
          {
            path: "/more/contacts/:id/general",
            name: "contact-general",
            component: () => import("@/components/contacts/ContactGeneral.vue"),
          },
          {
            path: "/more/contacts/:id/calls",
            name: "contact-calls",
            component: () => import("@/components/contacts/ContactCalls.vue"),
          },
          {
            path: "/more/contacts/:id/conversations",
            name: "contact-conversations",
            component: () =>
              import("@/components/contacts/ContactConversations.vue"),
          },
          {
            path: "/more/contacts/:id/faxes",
            name: "contact-faxes",
            component: () => import("@/components/contacts/ContactFaxes.vue"),
          },
        ],
      },
      {
        path: "/organization/profile/:id",
        name: "profile",
        component: () => import("@/views/organization/Profile.vue"),
        meta: { reuse: false },
      },
      {
        path: "/organization/offices",
        name: "offices",
        component: () => import("@/views/organization/Offices.vue"),
      },
      {
        path: "/organization/products/categories",
        name: "product-categories",
        component: () => import("@/views/organization/ProductCategories.vue"),
      },
      {
        path: "/organization/products/groups",
        name: "product-groups",
        component: () => import("@/views/organization/ProductGroups.vue"),
      },
      {
        path: "/organization/products",
        name: "products",
        component: () => import("@/views/organization/Products.vue"),
      },
      {
        path: "/organization/products/:id",
        name: "Product",
        component: () => import("@/components/products/Product.vue"),
        children: [
          {
            path: "/organization/products/:id/general",
            name: "product-general",
            component: () => import("@/components/objects/AboutProduct.vue"),
          },
          {
            path: "/organization/products/:id/terms",
            name: "product-terms",
            component: () => import("@/components/products/ProductTerms.vue"),
          },
          {
            path: "/organization/products/:id/attributes",
            name: "product-attributes",
            component: () =>
              import("@/components/products/ProductAttributes.vue"),
          },
        ],
      },
      {
        path: "/organization/products/features",
        name: "features",
        component: () => import("@/views/organization/Features.vue"),
      },
      {
        path: "/organization/environmentvariables",
        name: "environmentvariables",
        component: () =>
          import("@/views/organization/EnvironmentVariables.vue"),
      },
      {
        path: "/organization/callrates",
        name: "call-rates",
        component: () => import("@/views/organization/CallRates.vue"),
      },
      {
        path: "/organization/resources",
        name: "resources",
        component: () => import("@/views/organization/Resources.vue"),
      },
      {
        path: "/organization/providers",
        name: "providers",
        component: () => import("@/views/organization/Providers.vue"),
      },
      {
        path: "/organization/providers/:id",
        name: "provider",
        component: () => import("@/components/providers/Provider.vue"),
        children: [
          {
            path: "/organization/providers/:id/general",
            name: "provider-general",
            component: () => import("@/components/objects/AboutProvider.vue"),
          },
          {
            path: "/organization/providers/:id/usnumbers",
            name: "provider-usnumbers",
            component: () =>
              import("@/components/objects/AboutProviderUSNumbers.vue"),
          },
          {
            path: "/organization/providers/:id/intlnumbers",
            name: "provider-intlnumbers",
            component: () =>
              import("@/components/objects/AboutProviderIntlNumbers.vue"),
          },
          {
            path: "/organization/providers/:id/numberports",
            name: "provider-numberports",
            component: () =>
              import("@/components/objects/AboutProviderNumberPorts.vue"),
          },
          {
            path: "/organization/providers/:id/outbound",
            name: "provider-outbound",
            component: () =>
              import("@/components/objects/AboutProviderOutbound.vue"),
          },
          {
            path: "/organization/providers/:id/callrates",
            name: "provider-callrates",
            component: () =>
              import("@/components/providers/ProviderCallRates.vue"),
          },
        ],
      },
      {
        path: "/organization/regions",
        name: "regions",
        component: () => import("@/views/organization/Regions.vue"),
      },
      {
        path: "/organization/regions/:id",
        name: "region",
        component: () => import("@/components/regions/Region.vue"),
        children: [
          {
            path: "/organization/regions/:id/general",
            name: "region-general",
            component: () => import("@/components/objects/AboutRegion.vue"),
          },
          {
            path: "/organization/regions/:id/providers",
            name: "region-providers",
            component: () => import("@/components/regions/RegionProviders.vue"),
          },
          {
            path: "/organization/regions/:id/ipaddresses",
            name: "region-ipaddresses",
            component: () =>
              import("@/components/regions/RegionIPAddresses.vue"),
          },
        ],
      },
      {
        path: "/phone/dashboard",
        name: "phone-dashboard",
        component: () => import("@/components/phone/Dashboard.vue"),
      },
      {
        path: "/phone/callmanager",
        name: "call-manager",
        component: () => import("@/components/phone/CallManager.vue"),
      },
      {
        path: "/phone/dashboard/:id",
        name: "phone-contact",
        component: () => import("@/components/contacts/ContactWrapper.vue"),
        children: [
          {
            path: "/phone/dashboard/:id/general",
            name: "phone-contact-general",
            component: () => import("@/components/contacts/ContactGeneral.vue"),
          },
          {
            path: "/phone/dashboard/:id/calls",
            name: "phone-contact-calls",
            component: () => import("@/components/contacts/ContactCalls.vue"),
          },
          {
            path: "/phone/dashboard/:id/conversations",
            name: "phone-contact-conversations",
            component: () =>
              import("@/components/contacts/ContactConversations.vue"),
          },
          {
            path: "/phone/dashboard/:id/faxes",
            name: "phone-contact-faxes",
            component: () => import("@/components/contacts/ContactFaxes.vue"),
          },
        ],
      },
      {
        path: "/phone/callqueues",
        name: "call-queues",
        component: () => import("@/views/phone/CallQueues.vue"),
      },
      {
        path: "/callqueues/dashboard",
        name: "call-queue-dashboard",
        component: () =>
          import("@/components/callqueues/CallQueuesDashboard.vue"),
      },
      {
        path: "/callqueues/:id",
        name: "call-queue",
        component: () => import("@/components/callqueues/CallQueue.vue"),
        children: [
          {
            path: "/callqueues/:id/agents",
            name: "call-queue-agents",
            component: () =>
              import("@/components/callqueues/CallQueueAgents.vue"),
          },
          {
            path: "/callqueues/:id/agenttimeouts",
            name: "call-queue-agent-timeouts",
            component: () =>
              import("@/components/callqueues/CallQueueAgentTimeouts.vue"),
          },
          {
            path: "/callqueues/:id/queuetimeouts",
            name: "call-queue-queue-timeouts",
            component: () =>
              import("@/components/callqueues/CallQueueQueueTimeouts.vue"),
          },
          {
            path: "/callqueues/:id/settings",
            name: "call-queue-queue-settings",
            component: () =>
              import("@/components/callqueues/CallQueueSettings.vue"),
          },
          {
            path: "/callqueues/:id/logs",
            name: "call-queue-queue-logs",
            component: () =>
              import("@/components/callqueues/CallQueueCDRs.vue"),
          },
          {
            path: "/callqueues/:id/activecalls",
            name: "call-queue-queue-activecalls",
            component: () =>
              import("@/components/callqueues/CallQueueActiveCalls.vue"),
          },
          {
            path: "/callqueues/:id/dashboard",
            name: "call-queue-queue-dashboard",
            component: () =>
              import("@/components/callqueues/CallQueueDashboard.vue"),
          },
        ],
      },
      {
        path: "/phone/clients",
        name: "clients",
        component: () => import("@/views/phone/Clients.vue"),
      },
      {
        path: "/phone/conferencerooms",
        name: "conference-rooms",
        component: () => import("@/views/phone/ConferenceRooms.vue"),
      },
      {
        path: "/phone/activecalls",
        name: "cdr-active-calls",
        component: () => import("@/views/phone/ActiveCalls.vue"),
      },
      {
        path: "/phone/menus",
        name: "menus",
        component: () => import("@/views/phone/Menus.vue"),
      },
      {
        path: "/phone/menu",
        name: "add-menus",
        component: () => import("@/views/create/CreateResource.vue"),
      },
      {
        path: "/phone/menus/:id",
        name: "menu-object",
        component: () => import("@/components/menus/Menu.vue"),
        children: [
          {
            path: "/phone/menus/:id/options",
            name: "menu-options",
            component: () => import("@/components/menus/MenuOptions.vue"),
          },
          {
            path: "/phone/menus/:id/business-hours",
            name: "menu-business-hours",
            component: () => import("@/components/menus/MenuBusinessHours.vue"),
          },
          {
            path: "/phone/menus/:id/general",
            name: "menu-general",
            component: () => import("@/components/menus/MenuGeneral.vue"),
          },
          {
            path: "/phone/menus/:id/greeting",
            name: "menu-greeting",
            component: () => import("@/components/menus/MenuGreeting.vue"),
          },
          {
            path: "/phone/menus/:id/timeouts",
            name: "menu-timeouts",
            component: () => import("@/components/menus/MenuTimeouts.vue"),
          },
        ],
      },
      {
        path: "/phone/schedule",
        name: "add-timeschedules",
        component: () => import("@/views/create/CreateResource.vue"),
      },
      {
        path: "/phone/schedules",
        name: "time-schedules",
        component: () => import("@/views/phone/TimeSchedules.vue"),
      },
      {
        path: "/phone/schedules/:id",
        name: "time-schedule",
        component: () => import("@/components/timeschedules/TimeSchedule.vue"),
        children: [
          {
            path: "/phone/schedules/:id/general",
            name: "time-schedule-general",
            component: () =>
              import("@/components/timeschedules/TimeScheduleGeneral.vue"),
          },
          {
            path: "/phone/schedules/:id/holidays",
            name: "time-schedule-holidays",
            component: () =>
              import("@/components/timeschedules/TimeScheduleHolidays.vue"),
          },
        ],
      },
      {
        path: "/organization/servers",
        name: "servers",
        component: () => import("@/views/phone/Servers.vue"),
      },
      {
        path: "/organization/servers/:id",
        name: "server",
        component: () => import("@/components/servers/Server.vue"),
        children: [
          {
            path: "/organization/servers/:id/general",
            name: "server-general",
            component: () => import("@/components/servers/ServerGeneral.vue"),
          },
          {
            path: "/organization/servers/:id/call-history",
            name: "servers-call-history",
            component: () => import("@/components/servers/ServerCDRs.vue"),
          },
          {
            path: "/organization/servers/:id/licenses",
            name: "servers-licenses",
            component: () => import("@/components/servers/ServerLicenses.vue"),
          },
          {
            path: "/organization/servers/:id/settings",
            name: "servers-settings",
            component: () => import("@/components/servers/ServerSettings.vue"),
          },
        ],
      },
      {
        path: "/phone/directories",
        name: "directories",
        component: () => import("@/views/phone/Directories.vue"),
      },
      {
        path: "/phone/announcements",
        name: "announcements",
        component: () => import("@/views/phone/Announcements.vue"),
      },
      {
        path: "/phone/rules/inbound",
        name: "phone-inbound-rules",
        component: () => import("@/views/phone/PhoneInboundRules.vue"),
      },
      {
        path: "/phone/phoneinboundrule",
        name: "add-phoneinboundrules",
        component: () => import("@/views/create/CreateResource.vue"),
      },
      {
        path: "/phone/rules/outbound",
        name: "phone-outbound-rules",
        component: () => import("@/views/phone/PhoneOutboundRules.vue"),
      },
      {
        path: "/phone/phoneoutboundrule",
        name: "add-phoneoutboundrules",
        component: () => import("@/views/create/CreateResource.vue"),
      },
      {
        path: "/phone/numbers",
        name: "numbers",
        component: () => import("@/views/phone/Numbers.vue"),
      },
      {
        path: "/phone/number",
        name: "add-numbers",
        component: () => import("@/views/create/CreateResource.vue"),
      },
      {
        path: "/phone/numberport",
        name: "add-numberports",
        component: () => import("@/views/create/CreateResource.vue"),
      },
      {
        path: "/phone/numberports",
        name: "number-ports",
        component: () => import("@/views/phone/NumberPorts.vue"),
      },
      {
        path: "/phone/numberports/:id",
        name: "numberport",
        component: () => import("@/components/numberports/NumberPort.vue"),
        children: [
          {
            path: "/phone/numberports/:id/general",
            name: "numberport-general",
            component: () =>
              import("@/components/numberports/NumberPortGeneral.vue"),
          },
          {
            path: "/phone/numberports/:id/numbers",
            name: "numberport-numbers",
            component: () =>
              import("@/components/numberports/NumberPortNumbers.vue"),
          },
          {
            path: "/phone/numberports/:id/comments",
            name: "numberport-comments",
            component: () =>
              import("@/components/numberports/NumberPortComments.vue"),
          },
        ],
      },
      {
        path: "/phone/rules/inbound/:id",
        name: "phone-inbound-rule",
        component: () =>
          import("@/components/phoneinboundrules/PhoneInboundRule.vue"),
        children: [
          {
            path: "/phone/rules/inbound/:id/actions",
            name: "phone-inbound-rule-actions",
            component: () =>
              import(
                "@/components/phoneinboundrules/PhoneInboundRuleActions.vue"
              ),
          },
          {
            path: "/phone/rules/inbound/:id/settings",
            name: "phone-inbound-rule-settings",
            component: () =>
              import(
                "@/components/phoneinboundrules/PhoneInboundRuleSettings.vue"
              ),
          },
        ],
      },
      {
        path: "/phone/rules/outbound/:id",
        name: "phone-outbound-rule",
        component: () =>
          import("@/components/phoneoutboundrules/PhoneOutboundRule.vue"),
        children: [
          {
            path: "/phone/rules/outbound/:id/actions",
            name: "phone-outbound-rule-actions",
            component: () =>
              import(
                "@/components/phoneoutboundrules/PhoneOutboundRuleActions.vue"
              ),
          },
          {
            path: "/phone/rules/outbound/:id/general",
            name: "phone-outbound-rule-general",
            component: () =>
              import(
                "@/components/phoneoutboundrules/PhoneOutboundRuleGeneral.vue"
              ),
          },
        ],
      },
      {
        path: "/phone/virtualextensions",
        name: "virtual-extensions",
        component: () => import("@/views/phone/VirtualExtensions.vue"),
      },
      {
        path: "/voicemail",
        name: "voicemail",
        component: () => import("@/views/Voicemail.vue"),
      },
      {
        path: "/conversations/:id/",
        name: "conversation-chat",
        component: () =>
          import("@/components/conversations/ConversationChat.vue"),
      },
      {
        path: "/chat/:id",
        name: "chatroom",
        component: () => import("@/components/chat/Chat.vue"),
      },
      {
        path: "/calendar",
        name: "calendar",
        component: () => import("@/components/calendars/Calendar.vue"),
      },
      {
        path: "/organization/clientapps",
        name: "client-apps",
        component: () => import("@/views/organization/ClientApps.vue"),
      },
      {
        path: "/organization/templates",
        name: "templates",
        component: () => import("@/views/organization/Templates.vue"),
      },
      {
        path: "/organization/cdrs",
        name: "organization-cdrs",
        component: () => import("@/views/phone/CDRs.vue"),
      },
      {
        path: "/organization/callstats",
        name: "organization-callstats",
        component: () => import("@/components/reports/CallStats.vue"),
      },
      {
        path: "/organization/cdrs/:id/transcripts",
        name: "cdr-transcript",
        component: () => import("@/components/cdrs/CDRTranscripts.vue"),
      },
    ],
  },
  {
    path: "/phone/electroncallmanager",
    name: "electron-call-manager",
    component: () => import("@/components/phone/CallManager.vue"),
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/public/SignUp.vue"),
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/public/SignIn.vue"),
      },
      {
        path: "/join",
        name: "join-meeting",
        component: () => import("@/views/public/JoinMeeting.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/public/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/public/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/public/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: process.env.IS_ELECTRON
    ? createWebHashHistory()
    : createWebHistory(),
  routes,
});

const api = useService("recent");

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  const ignorePaths = c.mergeArrays(
    ["sign-in", "sign-up", "join-meeting", "password-reset", "forgot-password"],
    electronMinimalRoutes
  );

  const recent = async () => {
    if (
      !c
        .mergeArrays(ignorePaths, ["dashboard", "phone-dashboard"])
        .includes(String(to.name))
    ) {
      if (
        c.arrayEmpty(store.getters.info) ||
        c.arrayEmpty(store.getters.info.site) ||
        !store.getters.info.site.isFavoritesEnabled
      ) {
        return;
      }

      // eslint-disable-next-line
      // @ts-ignore
      const n = api.new({
        _id: c.newObjectId().toString(),
        path: to.fullPath,
        name: to.name,
        displayName: c.formatWords(String(to.name).replaceAll("-", " ")),
        params: to.params,
        query: to.query,
        fromPath: from.fullPath,
        fromName: from.name,
        fromDisplayName: c.formatWords(String(from.name).replaceAll("-", " ")),
        fromParams: from.params,
        fromQuery: from.query,
      });

      n.createInStore();
    }
  };

  if (!ignorePaths.includes(String(to.name)))
    store.dispatch("verifyAuth", next).then(() => {
      localStorage.setItem(lastVisitedPageVar, to.fullPath);
      recent();
    });
  else {
    next();
    recent();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;

<template>
  <!--begin::Menu-->
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px"
    data-kt-menu="true"
  >
    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-3">
        <!--begin::Avatar-->
        <div class="symbol symbol-50px me-5">
          <img
            alt="Logo"
            :src="profile.user.avatar"
            v-if="profile.user.avatar"
          />
          <Avatar :name="profile.user.displayName" v-else></Avatar>
        </div>
        <!--end::Avatar-->

        <!--begin::Username-->
        <div class="d-flex flex-column">
          <div class="fw-bolder d-flex align-items-center fs-5">
            {{ profile.user.displayName }}
            <span
              class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2"
              v-if="profile.user.isPrimary"
              >Primary</span
            >
          </div>
          <a href="#" class="fw-bold text-muted text-hover-primary fs-7">{{
            profile.user.email
          }}</a>
          <span class="fw-normal text-muted fs-8">
            {{ pkgConfig.productName + " v" + pkgConfig.version }}
          </span>
        </div>
        <!--end::Username-->
      </div>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu separator-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->

    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <router-link
        :to="'/organization/profile/' + profile.user._id"
        class="menu-link px-5"
      >
        My Profile
      </router-link>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu separator-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->

    <!--begin::Menu item-->
    <div
      class="menu-item px-5"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="left-start"
      data-kt-menu-flip="center, top"
    >
      <router-link to="" class="menu-link px-5">
        <span class="menu-title position-relative">
          Language
          <span
            class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0"
          >
            {{ currentLangugeLocale.name }}
            <img
              class="w-15px h-15px rounded-1 ms-2"
              :src="$imagesUrl + currentLangugeLocale.flag"
              alt="metronic"
            />
          </span>
        </span>
      </router-link>

      <!--begin::Menu sub-->
      <div class="menu-sub menu-sub-dropdown w-175px py-4">
        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <a
            @click="setLang('en')"
            href="#"
            class="menu-link d-flex px-5"
            :class="{ active: currentLanguage('en') }"
          >
            <span class="symbol symbol-20px me-4">
              <img
                class="rounded-1"
                :src="$imagesUrl + 'flags/united-states.svg'"
                alt="metronic"
              />
            </span>
            English
          </a>
        </div>
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <a
            @click="setLang('es')"
            href="#"
            class="menu-link d-flex px-5"
            :class="{ active: currentLanguage('es') }"
          >
            <span class="symbol symbol-20px me-4">
              <img
                class="rounded-1"
                :src="$imagesUrl + 'flags/spain.svg'"
                alt="metronic"
              />
            </span>
            Spanish
          </a>
        </div>
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <a
            @click="setLang('de')"
            href="#"
            class="menu-link d-flex px-5"
            :class="{ active: currentLanguage('de') }"
          >
            <span class="symbol symbol-20px me-4">
              <img
                class="rounded-1"
                :src="$imagesUrl + 'flags/germany.svg'"
                alt="metronic"
              />
            </span>
            German
          </a>
        </div>
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <a
            @click="setLang('ja')"
            href="#"
            class="menu-link d-flex px-5"
            :class="{ active: currentLanguage('ja') }"
          >
            <span class="symbol symbol-20px me-4">
              <img
                class="rounded-1"
                :src="$imagesUrl + 'flags/japan.svg'"
                alt="metronic"
              />
            </span>
            Japanese
          </a>
        </div>
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <a
            @click="setLang('fr')"
            href="#"
            class="menu-link d-flex px-5"
            :class="{ active: currentLanguage('fr') }"
          >
            <span class="symbol symbol-20px me-4">
              <img
                class="rounded-1"
                :src="$imagesUrl + 'flags/france.svg'"
                alt="metronic"
              />
            </span>
            French
          </a>
        </div>
        <!--end::Menu item-->
      </div>
      <!--end::Menu sub-->
    </div>
    <!--end::Menu item-->
    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <a @click="signOut()" class="menu-link px-5"> Sign Out </a>
    </div>
    <!--end::Menu item-->
  </div>
  <!--end::Menu-->
</template>

<script>
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore, mapGetters } from "vuex";
import { useRouter } from "vue-router";
import Avatar from "@/components/avatar/Avatar";
import pkgConfig from "../../../../package.json";

export default defineComponent({
  name: "UserMenu",
  components: { Avatar },
  computed: {
    ...mapGetters(["profile"]),
  },
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    i18n.locale = window.localStorage.getItem("lang")
      ? window.localStorage.getItem("lang")
      : "en";

    const countries = {
      en: {
        flag: "flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "flags/spain.svg",
        name: "Spanish",
      },
      de: {
        flag: "flags/germany.svg",
        name: "German",
      },
      ja: {
        flag: "flags/japan.svg",
        name: "Japanese",
      },
      fr: {
        flag: "flags/france.svg",
        name: "French",
      },
    };

    const signOut = () => {
      setTimeout(() => {
        store.dispatch("logout").then(() => router.push({ name: "sign-in" }));
      }, 100);
    };

    const setLang = (lang) => {
      window.localStorage.setItem("lang", lang);
      i18n.locale = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale];
    });

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
      pkgConfig,
    };
  },
});
</script>

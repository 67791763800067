<template>
  <header
    id="titlebar"
    class="electron-header-bg"
    :style="'height: ' + $store.getters.style.appHeaderHeight + 'px;'"
  >
    <div id="drag-region">
      <div id="window-title">
        <div>
          <img
            alt="Logo"
            :src="$store.getters.info.logoSymbol"
            class="h-20px ps-5"
            v-if="$store.getters.info.logoSymbol"
          />
        </div>
        <span v-if="title" class="ps-5 my-auto">{{ title }}</span>
      </div>
      <slot name="slot1"></slot>
      <slot name="slot2"></slot>
      <slot name="slot3"></slot>
      <slot name="slot4"></slot>
    </div>
  </header>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { config } from "@/core/helpers/config";
export default defineComponent({
  name: "AppTitleBarBase",
  components: {},
  props: {
    title: {
      type: String,
      required: false,
      default: () => "",
    },
  },
  setup(props) {
    config.value.content.width = "fluid";
    import("@/assets/css/fluid.css");
    onMounted(() => {
      document.body.setAttribute("data-electron-app", true);
      document.getElementById("app").style.marginTop =
        props.config.store.getters.style.appHeaderHeight + "px";
    });
  },
});
</script>
<style>
#titlebar {
  display: block;
  position: fixed;
  width: 100%;
  z-index: 99;
}

#titlebar #drag-region {
  width: 100%;
  height: 100%;
  -webkit-app-region: drag;
}

#titlebar #drag-region {
  display: grid;
  grid-template-columns: auto 138px;
}

#window-title {
  grid-column: 1;
  display: flex;
  align-items: center;
  margin-left: 8px;
  overflow: hidden;
  font-family: "Segoe UI", sans-serif;
  font-size: 12px;
}

#window-title span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5;
}

#window-title-slot3 {
  grid-column: 5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 185px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0px;
  height: 100%;
}

#window-controls {
  display: grid;
  grid-template-columns: repeat(3, 46px);
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

#window-controls-left {
  display: grid;
  grid-template-columns: repeat(3, 35px);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
#window-controls-update {
  display: grid;
  position: absolute;
  top: 0;
  right: 185px;
  height: 100%;
  padding: 10px 0px;
  cursor: pointer;
}

#window-controls-right {
  display: grid;
  grid-template-columns: repeat(3, 35px);
  position: absolute;
  top: 0;
  right: 70px;
  height: 100%;
}

#window-controls,
#window-controls-left,
#window-controls-right,
#window-controls-update {
  -webkit-app-region: no-drag;
}

#window-controls .button,
#window-controls-left .button {
  grid-row: 1 / span 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (-webkit-device-pixel-ratio: 1.5),
  (device-pixel-ratio: 1.5),
  (-webkit-device-pixel-ratio: 2),
  (device-pixel-ratio: 2),
  (-webkit-device-pixel-ratio: 3),
  (device-pixel-ratio: 3) {
  #window-controls .icon,
  #window-controls-left .icon {
    width: 10px;
    height: 10px;
  }
}

#window-controls .button,
#window-controls-left .button {
  user-select: none;
  cursor: pointer;
}

#window-controls .button:hover,
#window-controls-left .button:hover {
  background: white;
}

#window-controls .button:active,
#window-controls-left .button:active {
  background: #dcdcdc;
}

#back-button {
  grid-column: 1;
}

#forward-button {
  grid-column: 2;
}
</style>

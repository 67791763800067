import c from "@circuitid/common";
import store from "@/store";

export const callQueueFormatter = (d) => {
  if (c.arrayEmpty(d)) return d;

  d.link = "/callqueues/" + d._id + "/dashboard/";
  d.show = true;
  d.items = [
    {
      name: d.name,
      _id: d._id,
      badge: "secondary",
    },
  ];

  let totalActive = 0;

  if (!c.arrayEmpty(store.getters.livecdrstats.callqueues[d._id])) {
    if (
      c.isPositiveNum(store.getters.livecdrstats.callqueues[d._id].totalActive)
    )
      totalActive = c.getInt(
        store.getters.livecdrstats.callqueues[d._id].totalActive,
      );

    if (store.getters.livecdrstats.callqueues[d._id].totalWaiting)
      d.items[0].badge = "warning";
    else if (store.getters.livecdrstats.callqueues[d._id].totalTalking)
      d.items[0].badge = "warning";
  }

  d.rightContent = totalActive + " Calls";

  return d;
};

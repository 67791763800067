import Swal from "sweetalert2/dist/sweetalert2.min.js";
import parallel from "async/parallel";
import reflectAll from "async/reflectAll";
import {
  permitted,
  stopPresence,
  patchPresence,
  getUserStatusByStatus,
  speak,
  hasFeature,
  onActivity,
  getMeetUrl,
} from "@/core/helpers/functions.js";
import { sounds, soundPath, useDataCache, apiUrl } from "@/core/helpers/config";
import c from "@circuitid/common";
import { callQueueFormatter } from "@/components/callqueues/callQueueFunctions.js";
import { faxAccountFormatter } from "@/components/faxaccounts/faxAccountFunctions";
import { userJoinedRoom } from "@/components/chat/chatRoomFunctions.js";

const defaultResults = {
  results: {
    data: [],
    limit: 0,
    skip: 0,
    total: 0,
  },
};
const defaults = {
  limit: 100,
  impersonationNotAllowedServices: [],
};

const defaultObjects = {
  profile: {
    user: {},
  },
  tenant: {
    user: {},
    customer: {
      adminUserId: {},
      supportUserId: {},
    },
  },
  user: {
    communication: {
      notifications: {
        email: true,
        sms: true,
        sound: true,
      },
      missedCalls: {
        email: true,
        sms: true,
      },
      callRecordings: {
        email: true,
        sms: true,
      },
      newsletters: {
        email: true,
        sms: true,
      },
      voicemails: {
        email: true,
        sms: true,
      },
      web: {
        sounds: true,
      },
    },
    language: "en",
  },
};

const cartDefaults = (params) => {
  return {
    type: params.type,
    paymentMethod: {},
    products: {},
    total: 0,
    taxes: 0,
    subTotal: 0,
    proRatedTotal: 0,
  };
};

export default {
  state: () => ({
    app: {},
    apps: JSON.parse(JSON.stringify(defaultResults)),
    appsender: {},
    appsenders: JSON.parse(JSON.stringify(defaultResults)),
    appsubscription: {},
    appsubscriptions: JSON.parse(JSON.stringify(defaultResults)),
    appmarketplaceapp: {},
    appmarketplace: JSON.parse(JSON.stringify(defaultResults)),
    acceptedsender: {},
    acceptedsenders: JSON.parse(JSON.stringify(defaultResults)),
    announcement: {},
    announcements: JSON.parse(JSON.stringify(defaultResults)),
    calendarevent: {},
    calendarevents: JSON.parse(JSON.stringify(defaultResults)),
    cart: JSON.parse(JSON.stringify(cartDefaults({ type: "orders" }))),
    cartNumbers: JSON.parse(JSON.stringify(cartDefaults({ type: "numbers" }))),
    cartGeneral: JSON.parse(JSON.stringify(cartDefaults({ type: "general" }))),
    callrate: {},
    callrates: JSON.parse(JSON.stringify(defaultResults)),
    callqueue: {},
    callqueues: JSON.parse(JSON.stringify(defaultResults)),
    callqueueagent: {},
    callqueueagents: JSON.parse(JSON.stringify(defaultResults)),
    chatroom: {},
    chatrooms: JSON.parse(JSON.stringify(defaultResults)),
    chatmessage: {},
    chatmessages: JSON.parse(JSON.stringify(defaultResults)),
    client: {},
    clients: JSON.parse(JSON.stringify(defaultResults)),
    clientapp: {},
    clientapps: JSON.parse(JSON.stringify(defaultResults)),
    cdr: {},
    cdrs: JSON.parse(JSON.stringify(defaultResults)),
    cdrtranscript: {},
    cdrtranscripts: JSON.parse(JSON.stringify(defaultResults)),
    conferenceroom: {},
    conferencerooms: JSON.parse(JSON.stringify(defaultResults)),
    confetti: undefined,
    currentChatRoom: {},
    livecdr: {},
    livecdrstats: {},
    livecdrs: JSON.parse(JSON.stringify(defaultResults)),
    liveCDRFilter: undefined,
    liveCallQueueAgent: {},
    liveCallQueueAgentsById: {},
    liveCallQueueAgents: JSON.parse(JSON.stringify(defaultResults)),
    listgroup: {},
    listgroups: JSON.parse(JSON.stringify(defaultResults)),
    customer: {},
    customers: JSON.parse(JSON.stringify(defaultResults)),
    contact: {},
    contacts: JSON.parse(JSON.stringify(defaultResults)),
    environmentvariable: {},
    environmentvariables: JSON.parse(JSON.stringify(defaultResults)),
    comment: {},
    comments: JSON.parse(JSON.stringify(defaultResults)),
    focused: true,
    containerStyle: "",
    contentStyle: "",
    pageTitleStyle: "",
    country: {},
    countries: JSON.parse(JSON.stringify(defaultResults)),
    directory: {},
    directories: JSON.parse(JSON.stringify(defaultResults)),
    domain: {},
    domains: JSON.parse(JSON.stringify(defaultResults)),
    drawers: {},
    dnsrecord: {},
    dnsrecords: JSON.parse(JSON.stringify(defaultResults)),
    epic: {},
    epics: JSON.parse(JSON.stringify(defaultResults)),
    epicPatchId: undefined,
    fax: {},
    faxes: JSON.parse(JSON.stringify(defaultResults)),
    faxaccount: {},
    faxaccounts: JSON.parse(JSON.stringify(defaultResults)),
    firewallrule: {},
    firewall: JSON.parse(JSON.stringify(defaultResults)),
    findnumber: {},
    findnumbers: JSON.parse(JSON.stringify(defaultResults)),
    feature: {},
    features: JSON.parse(JSON.stringify(defaultResults)),
    group: {},
    groups: JSON.parse(JSON.stringify(defaultResults)),
    groupmembers: JSON.parse(JSON.stringify(defaultResults)),
    hideHeader: false,
    hideFooter: false,
    height: 0,
    width: 0,
    holiday: {},
    holidays: JSON.parse(JSON.stringify(defaultResults)),
    info: {},
    invoice: {},
    invoices: JSON.parse(JSON.stringify(defaultResults)),
    invoiceitem: {},
    invoiceitems: JSON.parse(JSON.stringify(defaultResults)),
    ipaddress: {},
    ipaddresses: JSON.parse(JSON.stringify(defaultResults)),
    payment: {},
    payments: JSON.parse(JSON.stringify(defaultResults)),
    license: {},
    licenses: JSON.parse(JSON.stringify(defaultResults)),
    licensesByOrderId: {},
    me: {},
    menuConfig: {},
    message: {},
    messages: JSON.parse(JSON.stringify(defaultResults)),
    messagebrand: {},
    messagebrands: JSON.parse(JSON.stringify(defaultResults)),
    messagecampaign: {},
    messagecampaigns: JSON.parse(JSON.stringify(defaultResults)),
    menu: {},
    menus: JSON.parse(JSON.stringify(defaultResults)),
    menuoption: {},
    menuoptions: JSON.parse(JSON.stringify(defaultResults)),
    number: {},
    numbers: JSON.parse(JSON.stringify(defaultResults)),
    numberport: {},
    numberports: JSON.parse(JSON.stringify(defaultResults)),
    numberportcomment: {},
    numberportcomments: JSON.parse(JSON.stringify(defaultResults)),
    numberportnumber: {},
    numberportnumbers: JSON.parse(JSON.stringify(defaultResults)),
    office: {},
    offices: JSON.parse(JSON.stringify(defaultResults)),
    order: {},
    orders: JSON.parse(JSON.stringify(defaultResults)),
    phonecontact: {},
    phonecontacts: JSON.parse(JSON.stringify(defaultResults)),
    presence: JSON.parse(JSON.stringify(defaultResults)),
    paymentmethod: {},
    paymentmethods: JSON.parse(JSON.stringify(defaultResults)),
    phoneinboundrule: {},
    phoneinboundrules: JSON.parse(JSON.stringify(defaultResults)),
    phoneinboundruleaction: {},
    phoneinboundruleactions: JSON.parse(JSON.stringify(defaultResults)),
    phoneInboundRuleActionId: undefined,
    phoneoutboundrule: {},
    phoneoutboundrules: JSON.parse(JSON.stringify(defaultResults)),
    phoneoutboundruleaction: {},
    phoneoutboundruleactions: JSON.parse(JSON.stringify(defaultResults)),
    phoneOutboundRuleActionId: undefined,
    postTask: undefined,
    phone: {
      client: {},
      sessions: [],
      session: {},
      audioinput: {},
      audiooutput: {},
      videoinput: {},
      videooutput: {},
      audioinputs: [],
      audiooutputs: [],
      videoinputs: [],
      videooutputs: [],
      isMediaDevicesLoaded: false,
      isMediaDevicesReady: false,
    },
    phoneConfig: {},
    product: {},
    products: JSON.parse(JSON.stringify(defaultResults)),
    productattribute: {},
    productattributes: JSON.parse(JSON.stringify(defaultResults)),
    productterm: {},
    productterms: JSON.parse(JSON.stringify(defaultResults)),
    productcategory: {},
    productcategories: JSON.parse(JSON.stringify(defaultResults)),
    productgroup: {},
    productgroups: JSON.parse(JSON.stringify(defaultResults)),
    project: {},
    projects: JSON.parse(JSON.stringify(defaultResults)),
    projectId: window.localStorage.getItem("projectId"),
    projecttemplate: {},
    projecttemplates: JSON.parse(JSON.stringify(defaultResults)),
    projectstatus: {},
    projectstatuses: JSON.parse(JSON.stringify(defaultResults)),
    profile: JSON.parse(JSON.stringify(defaultObjects.profile)),
    provider: {},
    providers: JSON.parse(JSON.stringify(defaultResults)),
    presenceWatcher: undefined,
    presenceIdleWatcher: undefined,
    userProfile: {},
    recent: JSON.parse(JSON.stringify(defaultResults)),
    region: {},
    regions: JSON.parse(JSON.stringify(defaultResults)),
    regionprovider: {},
    regionproviders: JSON.parse(JSON.stringify(defaultResults)),
    resource: {},
    resources: JSON.parse(JSON.stringify(defaultResults)),
    server: {},
    servers: JSON.parse(JSON.stringify(defaultResults)),
    serviceWorker: {},
    speakVoice: undefined,
    sprint: {},
    sprints: JSON.parse(JSON.stringify(defaultResults)),
    site: {},
    sites: JSON.parse(JSON.stringify(defaultResults)),
    sitemenu: {},
    sitemenus: JSON.parse(JSON.stringify(defaultResults)),
    sitemenugroup: {},
    sitemenugroups: JSON.parse(JSON.stringify(defaultResults)),
    sitemenupage: {},
    sitemenupages: JSON.parse(JSON.stringify(defaultResults)),
    page: {},
    pages: JSON.parse(JSON.stringify(defaultResults)),
    sprintPatchId: undefined,
    editSprint: undefined,
    state: {},
    subscription: {},
    subscriptions: JSON.parse(JSON.stringify(defaultResults)),
    conversation: {},
    conversations: JSON.parse(JSON.stringify(defaultResults)),
    conversationmessage: {},
    conversationmessages: JSON.parse(JSON.stringify(defaultResults)),
    style: {
      appHeaderHeight: 47,
    },
    showSubMenuToggleButton: true,
    sounds: {},
    task: {},
    tasks: JSON.parse(JSON.stringify(defaultResults)),
    taskPatchId: undefined,
    tenant: JSON.parse(JSON.stringify(defaultObjects.tenant)),
    tempData: {},
    template: {},
    templates: JSON.parse(JSON.stringify(defaultResults)),
    timeschedule: {},
    timeschedules: JSON.parse(JSON.stringify(defaultResults)),
    user: JSON.parse(JSON.stringify(defaultObjects.user)),
    users: JSON.parse(JSON.stringify(defaultResults)),
    userId: "",
    usertoken: {},
    usertokens: JSON.parse(JSON.stringify(defaultResults)),
    violation: {},
    violations: JSON.parse(JSON.stringify(defaultResults)),
    virtualextension: {},
    virtualextensions: JSON.parse(JSON.stringify(defaultResults)),
    voicemail: JSON.parse(JSON.stringify(defaultResults)),
    windowTitle: "",
    winLocHash: "",
    winLocSearch: "",
  }),
  getters: {
    accessToken(state) {
      return !c.arrayEmpty(state.tenant) &&
        !c.dependencyMissing(state.tenant.accessToken)
        ? state.tenant.accessToken
        : {};
    },
    app(state) {
      return state.app;
    },
    apps(state) {
      return state.apps;
    },
    appData(state) {
      return state.apps.results.data;
    },
    appsender(state) {
      return state.appsender;
    },
    appsenders(state) {
      return state.appsubscriptions;
    },
    appsenderData(state) {
      return state.appsubscriptions.results.data;
    },
    appsubscription(state) {
      return state.appsubscription;
    },
    appsubscriptions(state) {
      return state.appsubscriptions;
    },
    appsubscriptionData(state) {
      return state.appsubscriptions.results.data;
    },
    acceptedsender(state) {
      return state.acceptedsender;
    },
    acceptedsenders(state) {
      return state.acceptedsenders;
    },
    acceptedsenderData(state) {
      return state.acceptedsenders.results.data;
    },
    announcement(state) {
      return state.announcement;
    },
    announcements(state) {
      return state.announcements;
    },
    announcementData(state) {
      return state.announcements.results.data;
    },
    appmarketplaceapp(state) {
      return state.appmarketplaceapp;
    },
    appmarketplace(state) {
      return state.appmarketplace;
    },
    appmarketplaceData(state) {
      return state.appmarketplace.results.data;
    },
    cart(state) {
      return state.cart;
    },
    cartNumbers(state) {
      return state.cartNumbers;
    },
    cartGeneral(state) {
      return state.cartGeneral;
    },
    calendarevent(state) {
      return state.calendarevent;
    },
    calendarevents(state) {
      return state.calendarevents;
    },
    calendareventData(state) {
      return state.calendarevents.results.data;
    },
    callqueue(state) {
      return state.callqueue;
    },
    callqueues(state) {
      return state.callqueues;
    },
    callqueueData(state) {
      return state.callqueues.results.data;
    },
    callqueueListData(state) {
      if (c.arrayEmpty(state.callqueues.results.data)) return [];

      const objects = [];

      let object;

      for (let i = 0; i < state.callqueues.results.data.length; i++)
        if (
          !c.arrayEmpty(
            (object = callQueueFormatter(state.callqueues.results.data[i]))
          )
        )
          objects.push(object);

      return c.sort(objects, "name", 0);
    },
    callqueueagent(state) {
      return state.callqueueagent;
    },
    callqueueagents(state) {
      return state.callqueueagents;
    },
    callqueueagentData(state) {
      return state.callqueueagents.results.data;
    },
    chatmessage(state) {
      return state.chatmessage;
    },
    chatmessages(state) {
      return state.chatmessages;
    },
    chatmessageData(state) {
      return state.chatmessages.results.data;
    },
    chatroom(state) {
      return state.chatroom;
    },
    chatrooms(state) {
      return state.chatrooms;
    },
    chatroomData(state) {
      return c.sort(state.chatrooms.results.data, "updatedAt");
    },
    chatRoomListData(state, context) {
      const objects = [];

      if (c.arrayEmpty(context.chatroomData)) return objects;

      for (const i in context.chatroomData)
        if (
          !c.arrayEmpty(context.chatroomData[i]) &&
          userJoinedRoom(context.userId, "_id", context.chatroomData[i].users)
        )
          objects.push(context.chatroomData[i]);

      return objects;
    },
    currentChatRoom(state) {
      return state.currentChatRoom;
    },
    chatroomUnreadMessages(state, context) {
      if (c.arrayEmpty(context.chatroomData)) return 0;

      let count = 0;

      for (const i in context.chatroomData)
        if (
          userJoinedRoom(context.userId, "_id", context.chatroomData[i].users)
        )
          count += c.isPositiveNum(context.chatroomData[i].unread)
            ? c.getInt(context.chatroomData[i].unread)
            : 0;

      return count;
    },
    chatroomInvites(state, context) {
      const objects = [];

      for (const i in context.chatroomData)
        if (
          !c.arrayEmpty(context.chatroomData[i]) &&
          !c.arrayEmpty(context.chatroomData[i].users)
        )
          for (const u in context.chatroomData[i].users)
            if (
              !c.arrayEmpty(context.chatroomData[i].users[u]) &&
              c.trimString(context.chatroomData[i].users[u]._id) ===
                c.trimString(context.userId) &&
              c.toLower(context.chatroomData[i].users[u].status) === "invited"
            )
              objects.push(context.chatroomData[i]);

      return objects;
    },
    chatroomInvitesCount(state, context) {
      return context.chatroomInvites.length;
    },
    conversationUnreadMessages(state) {
      if (
        c.arrayEmpty(state.conversations.results) ||
        c.arrayEmpty(state.conversations.results.data)
      )
        return 0;

      let count = 0;

      for (const i in state.conversations.results.data)
        count += c.isPositiveNum(state.conversations.results.data[i].unread)
          ? c.getInt(state.conversations.results.data[i].unread)
          : 0;

      return count;
    },
    conferenceroom(state) {
      return state.conferenceroom;
    },
    conferencerooms(state) {
      return state.conferencerooms;
    },
    conferenceroomData(state) {
      return state.conferencerooms.results.data;
    },
    credit(state) {
      return !c.arrayEmpty(state.tenant) &&
        !c.arrayEmpty(state.tenant.customer) &&
        c.isPositiveNum(state.tenant.customer.credit)
        ? c.formatCurrency(state.tenant.customer.credit)
        : "$0";
    },
    containerStyle(state) {
      return state.containerStyle;
    },
    contentStyle(state) {
      return state.contentStyle;
    },
    confetti(state) {
      return state.confetti;
    },
    customerId(state) {
      return typeof state.tenant.user === "object" &&
        typeof state.tenant.user.customerId === "string"
        ? state.tenant.user.customerId
        : "";
    },
    pageTitleStyle(state) {
      return state.pageTitleStyle;
    },
    hideHeader(state) {
      return state.hideHeader;
    },
    hideFooter(state) {
      return state.hideFooter;
    },
    isFocused(state) {
      return state.focused ? true : false;
    },
    contentHeight(state) {
      const heightAdjustment = !state.isElectron
        ? 300
        : 125 + state.style.appHeaderHeight;
      const minHeight = 300;

      return c.getInt(
        state.height - heightAdjustment > minHeight
          ? state.height - heightAdjustment
          : minHeight
      );
    },
    isElectron() {
      return !c.arrayEmpty(window) && !c.arrayEmpty(window.electronAPI)
        ? true
        : false;
    },
    livecdrstats: (state, context) => {
      const results = {
        answered: 0,
        ringing: 0,
        inProgress: 0,
        hangup: 0,
        inbound: 0,
        outbound: 0,
        tollFree: 0,
        intlInbound: 0,
        intlOutbound: 0,
        inboundActive: 0,
        outboundActive: 0,
        tollFreeActive: 0,
        intlInboundActive: 0,
        intlOutboundActive: 0,
        totalActive: 0,
        callqueues: {},
        callqueueagents: {},
        total: c.getInt(context.livecdrData.length),
      };

      if (!context.livecdrData.length) return results;

      let isIntl, inProgress, talking, isCallQueueAgent, agentInProgress;

      for (let i = 0; i < context.livecdrData.length; i++) {
        isIntl =
          context.livecdrData[i].callerDestination.substr(0, 1) === "1"
            ? false
            : true;
        inProgress = ["RINGING", "ANSWERED", "IN_PROGRESS"].includes(
          c.toUpper(context.livecdrData[i].hangUpCause)
        )
          ? 1
          : 0;
        agentInProgress = ["RINGING", "IN_PROGRESS"].includes(
          c.toUpper(context.livecdrData[i].hangUpCause)
        )
          ? 1
          : 0;
        talking = ["ANSWERED"].includes(
          c.toUpper(context.livecdrData[i].hangUpCause)
        )
          ? 1
          : 0;
        isCallQueueAgent = 0;

        if (c.isValidObjectId(context.livecdrData[i].callqueue)) {
          if (
            c.arrayEmpty(results.callqueues[context.livecdrData[i].callqueue])
          )
            results.callqueues[context.livecdrData[i].callqueue] = {
              agents: {},
              totalActive: 0,
              percentOfTotal: 0,
              totalWaiting: 0,
              totalTalking: 0,
            };

          if (c.isValidObjectId(context.livecdrData[i].callqueueagent)) {
            if (
              c.arrayEmpty(
                results.callqueues[context.livecdrData[i].callqueue].agents[
                  context.livecdrData[i].callqueueagent
                ]
              )
            )
              results.callqueues[context.livecdrData[i].callqueue].agents[
                context.livecdrData[i].callqueueagent
              ] = {
                trying: 0,
                totalTalking: 0,
                talking: {},
              };

            if (
              ["agent", "agentmember"].includes(
                context.livecdrData[i].callQueueSide
              )
            ) {
              isCallQueueAgent = 1;

              if (agentInProgress)
                results.callqueues[context.livecdrData[i].callqueue].agents[
                  context.livecdrData[i].callqueueagent
                ].trying++;
              else if (talking) {
                results.callqueues[context.livecdrData[i].callqueue].agents[
                  context.livecdrData[i].callqueueagent
                ].talking = {
                  _id: context.livecdrData[i]._id,
                  callerIdNumber: context.livecdrData[i].callerIdNumber,
                  callerIdName: context.livecdrData[i].callerIdName,
                  callerDestination: context.livecdrData[i].callerDestination,
                };

                results.callqueues[context.livecdrData[i].callqueue].agents[
                  context.livecdrData[i].callqueueagent
                ].totalTalking++;
                results.callqueues[context.livecdrData[i].callqueue]
                  .totalTalking++;
              }
            }
          } else if (inProgress)
            results.callqueues[context.livecdrData[i].callqueue].totalWaiting++;

          if (inProgress && context.livecdrData[i].callQueueSide !== "agent")
            results.callqueues[context.livecdrData[i].callqueue].totalActive++;
        }

        if (!isCallQueueAgent) {
          switch (context.livecdrData[i].direction) {
            case "inbound":
              if (!isIntl) {
                if (inProgress) results.inboundActive++;
                else results.inbound++;
              } else {
                if (inProgress) results.intlInboundActive++;
                else results.intlInbound++;
              }

              break;
            case "outbound":
              if (!isIntl) {
                if (inProgress) results.outboundActive++;
                else results.outbound++;
              } else {
                if (inProgress) results.intlOutboundActive++;
                else results.intlOutbound++;
              }

              break;
          }

          switch (context.livecdrData[i].hangUpCause) {
            case "ANSWERED":
              results.answered++;
              results.totalActive++;
              break;
            case "RINGING":
              results.ringing++;
              results.totalActive++;
              break;
            case "IN_PROGRESS":
              results.inProgress++;
              results.totalActive++;
              break;
            default:
              results.hangup++;
              break;
          }
        }
      }

      const getPercentage = (n) => {
        return c.getFloat(
          c.getFloat(c.getFloat((c.getInt(n) / results.total) * 100)).toFixed(2)
        );
      };

      results.percentInbound = getPercentage(results.inbound);
      results.percentOutbound = getPercentage(results.outbound);
      results.percentTollFree = getPercentage(results.tollFree);
      results.percentIntlInbound = getPercentage(results.intlInbound);
      results.percentIntlOutbound = getPercentage(results.intlOutbound);

      for (const i in results.callqueues)
        if (c.isPositiveNum(results.totalActive))
          results.callqueues[i].percentOfTotal = c.getFloat(
            (c.getInt(results.callqueues[i].totalActive) /
              c.getInt(results.totalActive)) *
              100
          );
        else results.callqueues[i].percentOfTotal = 0;

      return results;
    },
    liveCallQueueAgent(state) {
      return state.liveCallQueueAgent;
    },
    liveCallQueueAgents(state) {
      return state.liveCallQueueAgents;
    },
    liveCallQueueAgentData(state) {
      return state.liveCallQueueAgents.results.data;
    },
    liveCallQueueAgentsById(state, context) {
      const objects = {};

      if (!c.arrayEmpty(context.liveCallQueueAgentData))
        for (let i = 0; i < context.liveCallQueueAgentData.length; i++)
          if (
            !c.arrayEmpty(context.liveCallQueueAgentData[i]) &&
            c.isValidObjectId(context.liveCallQueueAgentData[i]._id)
          )
            objects[context.liveCallQueueAgentData[i]._id] =
              context.liveCallQueueAgentData[i];

      return objects;
    },
    livecdr(state) {
      return state.livecdr;
    },
    livecdrs(state) {
      // hangUpCause Checks to see if it's a live call, or a hangup call that we are already monitoring.
      if (!c.arrayEmpty(state.livecdrs.results.data))
        for (let i = 0; i < state.livecdrs.results.data.length; i++)
          if (
            c.arrayEmpty(state.livecdrs.results.data[i]) ||
            c.dependencyMissing(state.livecdrs.results.data[i].startedAt) ||
            c
              .moment()(state.livecdrs.results.data[i].startedAt)
              .isBefore(c.getMomentUTC().subtract(5, "hours").format()) ||
            (!["RINGING", "ANSWERED", "IN_PROGRESS"].includes(
              c.toUpper(state.livecdrs.results.data[i].hangUpCause) &&
                !c.inArray(
                  "_id",
                  state.livecdrs.results.data[i]._id,
                  state.livecdrs.results.data
                )
            ) &&
              c
                .moment()(state.livecdrs.results.data[i].endedAt)
                .isBefore(c.getMomentUTC().subtract(10, "minute").format()))
          )
            state.livecdrs.results.data.splice(i, 1);

      return state.livecdrs;
    },
    livecdrData(state) {
      return state.livecdrs.results.data;
    },
    liveCDRFilter(state) {
      return state.liveCDRFilter;
    },
    cdr(state) {
      return state.cdr;
    },
    cdrs(state) {
      return state.cdrs;
    },
    cdrData(state) {
      return state.cdrs.results.data;
    },
    cdrtranscript(state) {
      return state.cdrtranscript;
    },
    cdrtranscripts(state) {
      return state.cdrtranscripts;
    },
    cdrtranscriptData(state) {
      return state.cdrtranscripts.results.data;
    },
    client(state) {
      return state.client;
    },
    clients(state) {
      return state.clients;
    },
    clientData(state) {
      return state.clients.results.data;
    },
    clientapp(state) {
      return state.clientapp;
    },
    clientapps(state) {
      return state.clientapps;
    },
    clientappData(state) {
      return state.clientapps.results.data;
    },
    dnsrecord(state) {
      return state.dnsrecord;
    },
    dnsrecords(state) {
      return state.dnsrecords;
    },
    dnsrecordData(state) {
      return state.dnsrecords.results.data;
    },
    environmentvariable(state) {
      return state.environmentvariable;
    },
    environmentvariables(state) {
      return state.environmentvariables;
    },
    environmentvariableData(state) {
      return state.environmentvariables.results.data;
    },
    callerId(state) {
      if (
        c.arrayEmpty(state.phone.session) ||
        c.arrayEmpty(state.phone.session.a)
      )
        return "";

      if (!c.dependencyMissing(state.phone.session.a.callerIdName))
        return state.phone.session.a.callerIdName;
      else if (!c.dependencyMissing(state.phone.session.a.callerIdNumber))
        return state.phone.session.a.callerIdNumber;
      else return "";
    },
    callrate(state) {
      return state.callrate;
    },
    callrates(state) {
      return state.callrates;
    },
    callrateData(state) {
      return state.callrates.results.data;
    },
    country(state) {
      return state.country;
    },
    countries(state) {
      return state.countries;
    },
    countryData(state) {
      return state.countries.results.data;
    },
    contact(state) {
      return state.contact;
    },
    contacts(state) {
      return state.contacts;
    },
    contactData(state) {
      return state.contacts.results.data;
    },
    phonecontact(state) {
      return state.phonecontact;
    },
    phonecontacts(state) {
      return state.phonecontacts;
    },
    phonecontactData(state) {
      return c.sort(state.phonecontacts.results.data, "displayName", 0);
    },
    comment(state) {
      return state.comment;
    },
    comments(state) {
      return state.comments;
    },
    commentData(state) {
      return state.comments.results.data;
    },
    customer(state) {
      return state.customer;
    },
    customers(state) {
      return state.customers;
    },
    customerData(state) {
      return state.customers.results.data;
    },
    directory(state) {
      return state.directory;
    },
    directories(state) {
      return state.directories;
    },
    directoryData(state) {
      return state.directories.results.data;
    },
    domain(state) {
      return state.domain;
    },
    domains(state) {
      return state.domains;
    },
    domainData(state) {
      return state.domains.results.data;
    },
    drawers(state) {
      return state.drawers;
    },
    epic(state) {
      return state.epic;
    },
    epics(state) {
      return state.epics;
    },
    epicData(state) {
      return state.epics.results.data;
    },
    epicPatchId(state) {
      return state.epicPatchId;
    },
    fax(state) {
      return state.fax;
    },
    faxes(state) {
      return state.faxes;
    },
    faxData(state) {
      return state.faxes.results.data;
    },
    faxaccount(state) {
      return state.faxaccount;
    },
    faxaccounts(state) {
      return state.faxaccounts;
    },
    faxaccountData(state) {
      return state.faxaccounts.results.data;
    },
    faxaccountListData(state) {
      if (c.arrayEmpty(state.faxaccounts.results.data)) return [];

      const objects = [];

      let object;

      for (let i = 0; i < state.faxaccounts.results.data.length; i++)
        if (
          !c.arrayEmpty(
            (object = faxAccountFormatter(state.faxaccounts.results.data[i]))
          )
        )
          objects.push(object);

      return c.sort(objects, "name", 0);
    },
    feature(state) {
      return state.feature;
    },
    features(state) {
      return state.features;
    },
    featureData(state) {
      return state.features.results.data;
    },
    findnumber(state) {
      return state.findnumber;
    },
    findnumbers(state) {
      return state.findnumbers;
    },
    findnumberData(state) {
      return state.findnumbers.results.data;
    },
    getItemsByKey(state, key) {
      const objectsByKey = {};

      if (state[key].results.data.length)
        for (let i = 0; i < state[key].results.data.length; i++)
          objectsByKey[state[key].results.data[i]._id] =
            state[key].results.data[i];

      return objectsByKey;
    },
    getItem:
      (state) =>
      (data = {}) => {
        if (
          typeof data !== "object" ||
          typeof data.service !== "string" ||
          !data._id
        )
          return {};

        data.service = data.service.trim().toLowerCase();

        if (state[data.service].results.data.length)
          for (let i = 0; i < state[data.service].results.data.length; i++)
            if (state[data.service].results.data[i]._id === data._id)
              return state[data.service].results.data[i];

        return {};
      },
    firewallrule(state) {
      return state.firewallrule;
    },
    firewall(state) {
      return state.firewall;
    },
    firewallData(state) {
      return state.firewall.results.data;
    },
    getData(state, data) {
      if (!data || !data.service) return;

      return state[data.service];
    },
    getObjectById: (state) => (data) => {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.service) ||
        c.dependencyMissing(data._id) ||
        c.arrayEmpty(state[data.service]) ||
        c.arrayEmpty(state[data.service].results) ||
        c.arrayEmpty(state[data.service].results.data)
      )
        return {};

      for (const k in state[data.service].results.data)
        if (
          c.trimString(state[data.service].results.data[k]._id) ===
          c.trimString(data._id)
        )
          return state[data.service].results.data[k];

      return {};
    },
    group(state) {
      return state.group;
    },
    groups(state) {
      return state.groups;
    },
    groupData(state) {
      return state.groups.results.data;
    },
    groupmembers(state) {
      return state.groupmembers;
    },
    groupmemberData(state) {
      return state.groupmembers.results.data;
    },
    impersonationAccessToken: (state) => () => {
      try {
        return typeof state.profile === "object" &&
          typeof state.tenant == "object" &&
          typeof state.profile.user === "object" &&
          typeof state.tenant.user == "object" &&
          //  typeof data == "object" &&
          //   Object.keys(data).length > 0 &&
          Object.keys(state.profile.user).length > 0 &&
          Object.keys(state.tenant.user).length > 0 &&
          //  typeof data.service === "string" &&
          typeof state.tenant.accessToken === "string" &&
          typeof state.profile.user.customerId === "string" &&
          typeof state.tenant.user.customerId === "string" &&
          state.tenant.user.customerId.trim() !==
            state.profile.user.customerId.trim() /*&& !defaults.impersonationNotAllowedServices.includes(data.service)) */
          ? state.tenant.accessToken
          : "";
      } catch {
        throw new Error("Failed checking impersonationAccessToken parameters");
      }
    },
    hasFeature:
      (state, context) =>
      (param, oneOf = true) => {
        return c.arrayEmpty(param) ||
          c.arrayEmpty(context.currentUser) ||
          c.arrayEmpty(context.currentUser.features)
          ? false
          : hasFeature(param, oneOf);
      },
    height(state) {
      return state.height;
    },
    width(state) {
      return state.width;
    },
    holiday(state) {
      return state.holiday;
    },
    holidays(state) {
      return state.holidays;
    },
    holidayData(state) {
      return state.holidays.results.data;
    },
    info(state) {
      return state.info;
    },
    invoice(state) {
      return state.invoice;
    },
    invoices(state) {
      return state.invoices;
    },
    invoiceData(state) {
      return state.invoices.results.data;
    },
    invoiceitem(state) {
      return state.invoice;
    },
    invoiceitems(state) {
      return state.invoiceitems;
    },
    invoiceitemData(state) {
      return state.invoiceitems.results.data;
    },
    ipaddress(state) {
      return state.ipaddress;
    },
    ipaddresses(state) {
      return state.ipaddresses;
    },
    ipaddressData(state) {
      return state.ipaddresses.results.data;
    },
    listgroup(state) {
      return state.ipaddress;
    },
    listgroups(state) {
      return state.listgroups;
    },
    listgroupData(state) {
      return state.listgroups.results.data;
    },
    listGroupsByServiceById(state, context) {
      if (c.arrayEmpty(context.listgroupData)) return {};

      const objects = {};

      for (let i = 0; i < context.listgroupData.length; i++)
        if (
          c.isValidObjectId(context.listgroupData[i]._id) &&
          !c.dependencyMissing(context.listgroupData[i].name)
        ) {
          if (typeof objects[context.listgroupData[i].service] !== "object")
            objects[context.listgroupData[i].service] = {};

          objects[context.listgroupData[i].service][
            context.listgroupData[i]._id
          ] = context.listgroupData[i];
        }

      return objects;
    },
    menuConfig(state) {
      return state.menuConfig;
    },
    shouldShowMenu: (state) => (menu) => {
      if (c.objectEmpty(state.menuConfig) || c.dependencyMissing(menu)) {
        return false;
      }

      for (const key in state.menuConfig) {
        if (key === menu) {
          const hasPermission = state.menuConfig[key].some(
            (item) => item.permitted === true
          );
          return hasPermission;
        }
      }

      return false;
    },

    phone(state) {
      return state.phone;
    },
    phoneConfig(state) {
      return !c.arrayEmpty(state.tenant) &&
        !c.arrayEmpty(state.tenant.user) &&
        !c.arrayEmpty(state.tenant.user.phone)
        ? state.tenant.user.phone
        : {};
    },
    isPhoneEnabled(state, context) {
      return !c.arrayEmpty(state.info) &&
        !c.arrayEmpty(state.info.site) &&
        state.info.site.isPhoneEnabled &&
        !c.arrayEmpty(context.phoneConfig) &&
        !c.arrayEmpty(context.phoneConfig.wssHosts) &&
        !c.dependencyMissing(context.phoneConfig.uri) &&
        !c.dependencyMissing(context.phoneConfig.email) &&
        !c.dependencyMissing(context.phoneConfig.password) &&
        !c.dependencyMissing(context.phoneConfig.registerHost) &&
        c.isPositiveNum(context.phoneConfig.registerPort)
        ? true
        : false;
    },
    isMeetNowEnabled(state) {
      return !c.arrayEmpty(state.info) &&
        !c.arrayEmpty(state.info.site) &&
        state.info.site.isMeetNowEnabled &&
        !c.dependencyMissing(getMeetUrl())
        ? true
        : false;
    },
    payment(state) {
      return state.payment;
    },
    payments(state) {
      return state.payments;
    },
    paymentData(state) {
      return state.payments.results.data;
    },
    permissions(state) {
      return typeof state.profile == "object" &&
        typeof state.profile.user == "object" &&
        typeof state.profile.user.permissions == "object"
        ? state.profile.user.permissions
        : [];
    },
    permitted:
      (state) =>
      (param, oneOf = true) => {
        return c.arrayEmpty(param) ||
          c.arrayEmpty(state.profile) ||
          c.arrayEmpty(state.profile.user) ||
          c.arrayEmpty(state.profile.user.permissions)
          ? false
          : permitted(state.profile.user.permissions, param, oneOf);
      },
    presenceWatcher(state) {
      return state.presenceWatcher;
    },
    presenceIdleWatcher(state) {
      return state.presenceIdleWatcher;
    },
    license(state) {
      return state.license;
    },
    licenses(state) {
      return state.licenses;
    },
    licenseData(state) {
      return state.licenses.results.data;
    },
    licensesByOrderId(state) {
      return state.licensesByOrderId;
    },
    me(state) {
      return state.me;
    },
    messagebrand(state) {
      return state.messagebrand;
    },
    messagebrands(state) {
      return state.messagebrands;
    },
    messagebrandData(state) {
      return state.messagebrands.results.data;
    },
    message(state) {
      return state.message;
    },
    messages(state) {
      return state.messages;
    },
    messagesData(state) {
      return state.messages.results.data;
    },
    messagecampaign(state) {
      return state.messagecampaign;
    },
    messagecampaigns(state) {
      return state.messagecampaigns;
    },
    messagecampaignData(state) {
      return state.messagecampaigns.results.data;
    },
    menu(state) {
      return state.menu;
    },
    menus(state) {
      return state.menus;
    },
    menuData(state) {
      return state.menus.results.data;
    },
    menuoption(state) {
      return state.menuoption;
    },
    menuoptions(state) {
      return state.menuoptions;
    },
    menuoptionData(state) {
      return state.menuoptions.results.data;
    },
    number(state) {
      return state.number;
    },
    numbers(state) {
      return state.numbers;
    },
    numberData(state) {
      return state.numbers.results.data;
    },
    numberport(state) {
      return state.numberport;
    },
    numberports(state) {
      return state.numberports;
    },
    numberportData(state) {
      return state.numberports.results.data;
    },

    numberportcomment(state) {
      return state.numberpnumberportcommentort;
    },
    numberportcomments(state) {
      return state.numberportcomments;
    },
    numberportcommentData(state) {
      return state.numberportcomments.results.data;
    },
    numberportnumber(state) {
      return state.numberportnumber;
    },
    numberportnumbers(state) {
      return state.numberportnumbers;
    },
    numberportnumberData(state) {
      return state.numberportnumbers.results.data;
    },
    order(state) {
      return state.order;
    },
    orders(state) {
      return state.orders;
    },
    orderData(state) {
      return state.orders.results.data;
    },
    paymentmethod(state) {
      return state.paymentmethod;
    },
    paymentmethods(state) {
      return state.paymentmethods;
    },
    paymentmethodData(state) {
      return state.paymentmethods.results.data;
    },
    phoneDeactiveSessions(state) {
      const objects = [];

      if (
        c.arrayEmpty(state.phone.sessions) ||
        c.arrayEmpty(state.phone.client)
      )
        return objects;

      let session;

      for (let i = 0; i < state.phone.sessions.length; i++)
        if (
          !c.arrayEmpty(state.phone.sessions[i]) &&
          !c.arrayEmpty(state.phone.sessions[i].s) &&
          !c.dependencyMissing(state.phone.sessions[i].s.id) &&
          !c.arrayEmpty(
            (session = state.phone.client.getSessionById(
              state.phone.sessions[i].s.id
            ))
          ) &&
          !c.arrayEmpty(session.a) &&
          !session.a.isActive
        )
          objects.push(session);

      return objects;
    },
    phoneSessionById: (state) => (_id) => {
      if (c.arrayEmpty(state.phone.sessions)) return {};

      for (let i = 0; i < state.phone.sessions.length; i++)
        if (
          !c.arrayEmpty(state.phone.sessions[i]) &&
          !c.arrayEmpty(state.phone.sessions[i].s) &&
          !c.dependencyMissing(state.phone.sessions[i].s.id) &&
          c.trimString(_id) === c.trimString(state.phone.sessions[i].s.id)
        )
          return state.phone.sessions[i];

      return {};
    },
    phoneinboundrule(state) {
      return state.phoneinboundrule;
    },
    phoneinboundrules(state) {
      return state.phoneinboundrules;
    },
    phoneinboundruleData(state) {
      return state.phoneinboundrules.results.data;
    },
    phoneinboundruleaction(state) {
      return state.phoneinboundruleaction;
    },
    phoneInboundRuleActionId(state) {
      return state.phoneInboundRuleActionId;
    },
    phoneinboundruleactions(state) {
      return state.phoneinboundruleactions;
    },
    phoneinboundruleactionData(state) {
      return state.phoneinboundruleactions.results.data;
    },
    phoneoutboundrule(state) {
      return state.phoneoutboundrule;
    },
    phoneoutboundrules(state) {
      return state.phoneoutboundrules;
    },
    phoneoutboundruleData(state) {
      return state.phoneoutboundrules.results.data;
    },
    phoneoutboundruleaction(state) {
      return state.phoneoutboundruleaction;
    },
    phoneoutboundruleactions(state) {
      return state.phoneoutboundruleactions;
    },
    phoneoutboundruleactionData(state) {
      return state.phoneoutboundruleactions.results.data;
    },
    phoneOutboundRuleActionId(state) {
      return state.phoneOutboundRuleActionId;
    },
    postTask(state) {
      return state.postTask;
    },
    presence(state) {
      return state.presence;
    },
    presenceData(state) {
      return state.presence.results.data;
    },
    presenceColor(state, context) {
      return context.presenceColorById(context.userId);
    },
    presenceColorById: (state, context) => (_id) => {
      if (c.arrayEmpty(context.presenceData)) return "secondary";

      let status;

      for (const i in context.presenceData)
        if (context.presenceData[i].createdByUserId === _id) {
          if (
            !c.dependencyMissing(context.presenceData[i].status.status) &&
            !c.dependencyMissing(context.presenceData[i].status.createdAt)
          )
            status = getUserStatusByStatus([context.presenceData[i].status]);
          else status = getUserStatusByStatus(context.presenceData[i].status);

          switch (status) {
            case "online":
              return "success";
            case "idle":
              return "warning";
            case "busy":
              return "danger";
          }
        }

      return "secondary";
    },
    profile(state) {
      return state.profile;
    },
    product(state) {
      return state.product;
    },
    products(state) {
      return state.products;
    },
    productData(state) {
      return state.products.results.data;
    },
    productterm(state) {
      return state.productterm;
    },
    productterms(state) {
      return state.productterms;
    },
    producttermData(state) {
      return state.productterms.results.data;
    },
    productattribute(state) {
      return state.productattribute;
    },
    productattributes(state) {
      return state.productattributes;
    },
    productattributeData(state) {
      return state.productattributes.results.data;
    },
    productcategory(state) {
      return state.productcategory;
    },
    productcategories(state) {
      return state.productcategories;
    },
    productcategoryData(state) {
      return state.productcategories.results.data;
    },
    productgroup(state) {
      return state.productgroup;
    },
    productgroups(state) {
      return state.productgroups;
    },
    productgroupData(state) {
      return state.productgroups.results.data;
    },
    project(state) {
      return state.project;
    },
    projects(state) {
      return state.projects;
    },
    projectData(state) {
      return state.projects.results.data;
    },
    projectId(state) {
      return state.projectId;
    },
    projecttemplate(state) {
      return state.projecttemplate;
    },
    projecttemplates(state) {
      return state.projecttemplates;
    },
    projecttemplateData(state) {
      return state.projecttemplates.results.data;
    },
    projectstatus(state) {
      return state.projectstatus;
    },
    projectstatuses(state) {
      return state.projectstatuses;
    },
    projectstatusData(state) {
      return state.projectstatuses.results.data;
    },
    provider(state) {
      return state.provider;
    },
    providers(state) {
      return state.providers;
    },
    providerData(state) {
      return state.providers.results.data;
    },
    office(state) {
      return state.office;
    },
    offices(state) {
      return state.offices;
    },
    officeData(state) {
      return state.offices.results.data;
    },
    recent(state) {
      return state.recent;
    },
    recentData(state) {
      return state.recent.results.data;
    },
    region(state) {
      return state.region;
    },
    regions(state) {
      return state.regions;
    },
    regionData(state) {
      return state.regions.results.data;
    },
    regionprovider(state) {
      return state.regionprovider;
    },
    regionproviders(state) {
      return state.regionproviders;
    },
    regionproviderData(state) {
      return state.regionproviders.results.data;
    },
    resource(state) {
      return state.resource;
    },
    resources(state) {
      return state.resources;
    },
    resourceData(state) {
      return state.resources.results.data;
    },
    server(state) {
      return state.server;
    },
    servers(state) {
      return state.servers;
    },
    serverData(state) {
      return state.servers.results.data;
    },
    showSubMenuToggleButton(state) {
      return state.showSubMenuToggleButton;
    },
    showMiniPhone(state, context) {
      return !state.drawers["phoneDialpadID_viewDrawer"] &&
        context.showPhone &&
        ((state.phone.direction === "inbound" &&
          (!c.dependencyMissing(state.phone.callerIdName) ||
            !c.dependencyMissing(state.phone.callerIdNumber))) ||
          (state.phone.direction === "outbound" &&
            !c.dependencyMissing(state.phone.callerDestination)))
        ? true
        : false;
    },
    showDashboard(state, context) {
      return !state.isElectron &&
        context.permitted(
          ["admin:*", "billing:*", "admin:find", "billing:find"],
          true
        )
        ? true
        : false;
    },
    showPhone(state) {
      return state.phone.isConnectingOutboundCall ||
        (!c.arrayEmpty(state.phone.session) &&
          !c.arrayEmpty(state.phone.session.a) &&
          (state.phone.session.a.isConnectingInboundCall ||
            state.phone.session.a.isAnswered ||
            state.phone.session.a.isInboundRinging ||
            state.phone.session.a.isOutboundRinging))
        ? true
        : false;
    },
    speakVoice(state) {
      return state.speakVoice;
    },
    style(state) {
      return state.style;
    },
    conversation(state) {
      return state.conversation;
    },
    conversations(state) {
      return state.conversations;
    },
    conversationData(state) {
      return c.sort(
        state.conversations.results.data,
        "lastConversationMessageDate",
        1
      );
    },
    conversationmessage(state) {
      return state.conversationmessage;
    },
    conversationmessages(state) {
      return state.conversationmessages;
    },
    conversationmessageData(state) {
      return state.conversationmessages.results.data;
    },
    serviceWorker(state) {
      return state.serviceWorker;
    },
    sprint(state) {
      return state.sprint;
    },
    sprints(state) {
      return state.sprints;
    },
    sprintData(state) {
      return state.sprints.results.data;
    },
    sprintPatchId(state) {
      return state.sprintPatchId;
    },
    sounds(state) {
      return state.sounds;
    },
    state(state) {
      return state.state;
    },
    task(state) {
      return state.task;
    },
    tasks(state) {
      return state.tasks;
    },
    taskData(state) {
      return state.tasks.results.data;
    },
    taskPatchId(state) {
      return state.taskPatchId;
    },
    tempData(state) {
      return state.tempData;
    },
    tenantName(state) {
      return !c.arrayEmpty(state.tenant) &&
        !c.arrayEmpty(state.tenant.customer) &&
        !c.dependencyMissing(state.tenant.customer.name)
        ? state.tenant.customer.name
        : "John Doe";
    },
    isAdmin(state) {
      return typeof state.tenant.user === "object" &&
        typeof state.tenant.user.permissions === "object" &&
        state.tenant.user.permissions.includes("admin:*")
        ? true
        : false;
    },
    isSuperAdmin(state) {
      return typeof state.profile.user === "object" &&
        typeof state.profile.user.permissions === "object" &&
        state.profile.user.permissions.includes("superadmin")
        ? true
        : false;
    },

    site(state) {
      return state.site;
    },
    sites(state) {
      return state.sites;
    },
    siteData(state) {
      return state.sites.results.data;
    },
    sitemenu(state) {
      return state.sitemenu;
    },
    sitemenus(state) {
      return state.sitemenus;
    },
    sitemenuData(state) {
      return state.sitemenus.results.data;
    },
    sitemenuDataByKey(state) {
      if (
        c.arrayEmpty(state.info.site) ||
        c.arrayEmpty(state.info.site.sitemenus)
      )
        return {};

      const data = {};

      for (const menu of state.info.site.sitemenus)
        if (!c.arrayEmpty(menu) && !c.dependencyMissing(menu.key))
          data[menu.key] = menu;

      return data;
    },
    sitemenugroup(state) {
      return state.sitemenugroup;
    },
    sitemenugroups(state) {
      return state.sitemenugroups;
    },
    sitemenugroupData(state) {
      return state.sitemenugroups.results.data;
    },
    sitemenupage(state) {
      return state.sitemenupage;
    },
    sitemenupages(state) {
      return state.sitemenupages;
    },
    sitemenupageData(state) {
      return state.sitemenupages.results.data;
    },
    page(state) {
      return state.page;
    },
    pages(state) {
      return state.pages;
    },
    pageData(state) {
      return state.pages.results.data;
    },

    tenant(state) {
      return state.tenant;
    },
    tenantId(state) {
      return typeof state.tenant.user === "object" &&
        typeof state.tenant.user.customerId === "string"
        ? state.tenant.user.customerId
        : "";
    },
    template(state) {
      return state.template;
    },
    templates(state) {
      return state.templates;
    },
    templateData(state) {
      return state.templates.results.data;
    },
    timeschedule(state) {
      return state.timeschedule;
    },
    timeschedules(state) {
      return state.timeschedules;
    },
    timescheduleData(state) {
      return state.timeschedules.results.data;
    },
    user(state) {
      return state.user;
    },
    users(state) {
      return state.users;
    },
    userData(state) {
      return state.users.results.data;
    },
    userFeatures(state, context) {
      return c.arrayEmpty(context.currentUser) ||
        c.arrayEmpty(context.currentUser.features)
        ? false
        : context.currentUser.features;
    },
    userProfile(state) {
      return !c.arrayEmpty(state.profile) && !c.arrayEmpty(state.profile.user)
        ? state.profile.user
        : {};
    },
    customerProfile(state) {
      return !c.arrayEmpty(state.profile) &&
        !c.arrayEmpty(state.profile.customer)
        ? state.profile.customer
        : {};
    },
    userId(state) {
      return typeof state.tenant.user === "object" &&
        typeof state.tenant.user._id === "string"
        ? state.tenant.user._id
        : "";
    },
    currentUser(state) {
      return !c.arrayEmpty(state.tenant) && !c.arrayEmpty(state.tenant.user)
        ? state.tenant.user
        : {};
    },
    currentCustomer(state) {
      return !c.arrayEmpty(state.tenant) && !c.arrayEmpty(state.tenant.customer)
        ? state.tenant.customer
        : {};
    },
    userCommunication(state, context) {
      return !c.arrayEmpty(context.currentUser) &&
        !c.arrayEmpty(context.currentUser.communication)
        ? context.currentUser.communication
        : c.cloneObject(defaultObjects.user.communication);
    },
    displayName(state) {
      return !c.arrayEmpty(state.tenant.user) &&
        !c.dependencyMissing(state.tenant.user.first)
        ? state.tenant.user.first + " " + state.tenant.user.last
        : "";
    },
    usertoken(state) {
      return state.usertoken;
    },
    usertokens(state) {
      return state.usertokens;
    },
    usertokenData(state) {
      return state.usertokens.results.data;
    },
    userPinnedMenusByService(state, context) {
      if (c.arrayEmpty(context.listgroupData)) return;

      const groups = {};

      for (const i in context.listgroupData)
        if (
          !c.arrayEmpty(context.listgroupData[i]) &&
          c.isValidObjectId(context.listgroupData[i]._id) &&
          !c.dependencyMissing(context.listgroupData[i].name) &&
          c.toLower(context.listgroupData[i].name) === "pinned"
        )
          groups[context.listgroupData[i].service] =
            context.listgroupData[i]._id;

      return groups;
    },
    violation(state) {
      return state.violation;
    },
    violations(state) {
      return state.violations;
    },
    violationData(state) {
      return state.violations.results.data;
    },
    virtualextension(state) {
      return state.virtualextension;
    },
    virtualextensions(state) {
      return state.virtualextensions;
    },
    virtualextensionData(state) {
      return state.virtualextensions.results.data;
    },
    voicemail(state) {
      return state.voicemail;
    },
    voicemailData(state) {
      return state.voicemail.results.data;
    },
    windowTitle(state) {
      return state.windowTitle;
    },
    winLocHash(state) {
      return state.winLocHash;
    },
    winLocSearch(state) {
      return state.winLocSearch;
    },
  },
  mutations: {
    deleteObjectAttribute(state, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.key) ||
        c.dependencyMissing(data.attribute) ||
        !c.isObject(state[data.key])
      )
        return c.error(c.errorMessages.dependencyMissing);

      delete state[data.key][data.attribute];
    },
    setData(state, data) {
      if (c.arrayEmpty(data) || c.dependencyMissing(data.service)) return;

      if (
        c.arrayEmpty(state[data.service]) ||
        c.arrayEmpty(state[data.service].results)
      )
        state[data.service] = c.cloneObject(defaultResults);

      if (c.dependencyMissing(data.mergeType)) {
        if (c.arrayEmpty(data.results))
          state[data.service] = c.cloneObject(defaultResults);

        state[data.service].results.data = [];

        if (!c.arrayEmpty(data.results.data)) {
          if (!c.arrayEmpty(data.sortData))
            data.results.data = c.sort(
              data.results.data,
              data.sortData.key,
              data.sortData.order === "desc" ? 1 : 0
            );

          state[data.service].results = data.results;
        }
      } else {
        if (c.arrayEmpty(state[data.service].results.data))
          state[data.service].results.data = [];

        let items;

        if (c.toLower(data.mergeType) === "append")
          items = [...state[data.service].results.data, ...data.results.data];
        else
          items = [...data.results.data, ...state[data.service].results.data];

        if (!c.arrayEmpty(data.sortData))
          items = c.sort(
            items,
            data.sortData.key,
            data.sortData.order === "desc" ? 1 : 0
          );

        state[data.service].results.data = [...items];
      }
    },
    setObject(state, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing((data.key = c.trimString(data.key)))
      )
        return;

      if (c.arrayEmpty(data.object))
        state[data.key] = c.arrayEmpty(defaultObjects[data.key])
          ? {}
          : JSON.parse(JSON.stringify(defaultObjects[data.key]));
      else state[data.key] = data.object;
    },
    setStateValue(state, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing((data.key = c.trimString(data.key)))
      )
        return;

      state[data.key] = data.value;
    },
    setCartAttribute(state, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data._id) ||
        c.dependencyMissing((data.attribute = c.trimString(data.attribute)))
      )
        return;

      if (data.attribute === "quantity" && !c.isPositiveNum(data.value))
        delete state.cart.products[data._id];
      else {
        if (c.arrayEmpty(state.cart.products[data._id])) {
          for (const i in this.getters.productData)
            if (this.getters.productData[i]._id === data._id) {
              state.cart.products[data._id] = this.getters.productData[i];
              break;
            }
        }

        if (c.arrayEmpty(state.cart.products[data._id])) return;

        state.cart.products[data._id][data.attribute] = data.value;
      }

      state.cart.total = 0;

      if (!c.arrayEmpty(state.cart.products))
        for (const i in state.cart.products)
          if (
            c.isPositiveNum(state.cart.products[i].quantity) &&
            c.isPositiveNum(state.cart.products[i].amount)
          ) {
            state.cart.products[i].total =
              state.cart.products[i].quantity * state.cart.products[i].amount;

            state.cart.total += state.cart.products[i].total;
          }
    },
    setCartNumbers(state, data) {
      if (c.arrayEmpty(data)) return;

      state.cartNumbers.products = [];
      state.cartNumbers.total = 0;
      state.cartNumbers.setup = 0;

      if (
        c.arrayEmpty(data._ids) ||
        c.dependencyMissing((data.service = c.trimString(data.service)))
      )
        return;

      let product;

      for (let i = 0; i < data._ids.length; i++)
        if (
          !c.dependencyMissing(data._ids[i]) &&
          !c.arrayEmpty(
            (product = this.getters.getItem({
              service: data.service,
              _id: data._ids[i],
            }))
          )
        ) {
          product.total = 0;

          if (!c.isPositiveNum(product.monthlyFee)) product.monthlyFee = 0;
          else product.total += c.getFloat(product.monthlyFee);

          if (!c.isPositiveNum(product.setup)) product.setup = 0;
          else product.total += c.getFloat(product.setup);

          state.cartNumbers.products.push(
            c.mergeObjects(product, { quantity: 1 })
          );

          if (c.isPositiveNum(product.monthlyFee))
            state.cartNumbers.total += c.getFloat(product.monthlyFee);

          if (c.isPositiveNum(product.setup))
            state.cartNumbers.total += c.getFloat(product.setup);
        }
    },
    addCartGeneral(state, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.name) ||
        c.dependencyMissing(data._id)
      )
        return;

      const product = data;

      if (!c.isPositiveNum(product.quantity)) product.quantity = 1;

      if (c.isPositiveNum(product.monthlyFee))
        state.cartGeneral.total += c.getFloat(product.monthlyFee);
      else product.monthlyFee = 0;

      if (c.isPositiveNum(product.setup))
        state.cartGeneral.total += c.getFloat(product.setup);
      else product.setup = 0;

      state.cartGeneral.products[data._id] = product;
    },
    removeCartGeneral(state, data) {
      if (c.arrayEmpty(data) || c.dependencyMissing(data._id)) return;

      delete state.cartGeneral.products[data._id];
    },
    pushObject(state, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.key) ||
        data.value === undefined ||
        c.arrayEmpty(state[data.key]) ||
        c.arrayEmpty(state[data.key].results) ||
        typeof state[data.key].results.data !== "object"
      )
        return;

      if (!c.arrayEmpty(state[data.key].results.data))
        for (let i = 0; i < state[data.key].results.data.length; i++)
          if (
            c.trimString(state[data.key].results.data[i]._id) ===
            c.trimString(data.value._id)
          ) {
            state[data.key].results.data[i] = data.value;
            state[data.key].results.data = [...state[data.key].results.data];

            return;
          }

      state[data.key].results.data = [
        ...state[data.key].results.data,
        data.value,
      ];
    },
    removeArrayObject(state, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.key) ||
        c.arrayEmpty(state[data.key]) ||
        c.arrayEmpty(state[data.key].results) ||
        typeof state[data.key].results.data !== "object" ||
        c.dependencyMissing(data._id)
      )
        return;

      for (const i in state[data.key].results.data)
        if (state[data.key].results.data[i]._id === data._id) {
          state[data.key].results.data.splice(i, 1);
          state[data.key].results.data = [...state[data.key].results.data];
        }
    },
    patchArrayObject(state, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.key) ||
        c.arrayEmpty(state[data.key]) ||
        c.arrayEmpty(state[data.key].results) ||
        c.arrayEmpty(state[data.key].results.data) ||
        c.arrayEmpty(data.value) ||
        c.dependencyMissing(data.value._id)
      )
        return;

      for (const i in state[data.key].results.data)
        if (state[data.key].results.data[i]._id === data.value._id) {
          state[data.key].results.data[i] = data.value;

          state[data.key].results.data = [...state[data.key].results.data];

          return;
        }

      state[data.key].results.data = [
        ...state[data.key].results.data,
        data.value,
      ];
    },
    patchServiceObjectAttribute(state, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.service) ||
        c.dependencyMissing(data.attribute) ||
        c.dependencyMissing(data._id) ||
        c.arrayEmpty(state[data.service]) ||
        c.arrayEmpty(state[data.service].results) ||
        typeof state[data.service].results.data !== "object"
      )
        return;

      for (const i in state[data.service].results.data)
        if (state[data.service].results.data[i]._id === data._id)
          state[data.service].results.data[i][data.attribute] = data.value;
    },
    resetCart(state, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing((data.type = c.toLower(data.type)))
      )
        return;

      let key;

      switch (data.type) {
        case "orders":
          key = "cart";
          break;
        case "numbers":
          key = "cartNumbers";
          break;
        case "general":
          key = "cartGeneral";
          break;
        default:
          return;
      }

      state[key] = JSON.parse(
        JSON.stringify(cartDefaults({ type: data.type }))
      );
    },
    setState(state, data) {
      if (!data || !data.type || !data.service) return;

      data.type = data.type.trim();

      switch (data.type) {
        case "Get":
        case "Find":
        case "FindOne":
        case "Patch":
        case "Post":
        case "Remove":
          break;
        default:
          return;
      }

      data.service = data.service.toLowerCase();
      data.service = data.service.trim();
      data.service = data.service[0].toUpperCase() + data.service.substr(1);

      const value = data.value === true ? true : false;

      state.state["is" + data.type + "Pending"] = value;
      state.state["is" + data.service + data.type + "Pending"] = value;
    },
    setTenantCustomerAttribute(state, data) {
      if (c.arrayEmpty(data) || c.dependencyMissing(data.attribute))
        return c.error(c.errorMessages.dependencyMissing);

      state.tenant.customer[data.attribute] = data.value;
    },
    pushValue(state, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing((data.key = c.trimString(data.key)))
      )
        return;

      state[data.key].push(data.value);
    },
    setValue(state, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing((data.key = c.trimString(data.key)))
      )
        return;

      state[data.key] = data.value;
    },
    pushObjectAttribute(state, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.key) ||
        c.dependencyMissing(data.attribute) ||
        !c.isObject(state[data.key])
      )
        return c.error(c.errorMessages.dependencyMissing);

      if (!c.isObject(state[data.key][data.attribute]))
        state[data.key][data.attribute] = [];

      state[data.key][data.attribute].push(data.value);
    },
    patchArrayObjectAttribute(state, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.array) ||
        c.dependencyMissing(data.key) ||
        c.dependencyMissing(data.attribute) ||
        c.dependencyMissing(data._id) ||
        c.arrayEmpty(state[data.array]) ||
        c.arrayEmpty(state[data.array][data.key])
      )
        return;

      for (const i in state[data.array][data.key])
        if (state[data.array][data.key][i]._id === data._id)
          state[data.array][data.key][i][data.attribute] = data.value;
    },
    spliceObjectAttribute(state, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.key) ||
        c.dependencyMissing(data.attribute) ||
        c.arrayEmpty(state[data.key]) ||
        c.arrayEmpty(state[data.key][data.attribute])
      )
        return c.error(c.errorMessages.dependencyMissing);

      if (c.arrayEmpty(data.add))
        state[data.key][data.attribute].splice(data.start, data.count);
      else
        state[data.key][data.attribute].splice(
          data.start,
          data.count,
          data.add
        );
    },
    setObjectAttribute(state, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.key) ||
        c.dependencyMissing(data.attribute) ||
        !c.isObject(state[data.key])
      )
        return c.error(c.errorMessages.dependencyMissing);

      state[data.key][data.attribute] = data.value;
    },
  },
  actions: {
    connected() {
      if (
        c.arrayEmpty(this) ||
        c.arrayEmpty(this.feathersClient) ||
        c.arrayEmpty(this.feathersClient.io)
      )
        return false;

      return this.feathersClient.io.connected;
    },
    alertPatchSuccess() {
      this.dispatch("alert", {
        message: "Saved successfully",
      });
    },
    alert(context, data) {
      if (c.arrayEmpty(data) || c.dependencyMissing(data.message)) return;

      const customClass = {
        icon: "me-5 fs-9",
      };

      let icon;

      switch (c.toLower(data.type)) {
        case "error":
        case "info":
        case "warning":
        case "question":
          icon = c.toLower(data.type);
          break;
        default:
          icon = "success";
          break;
      }

      if (context.getters.isElectron) customClass.container = "pt-15";

      if (
        !c.arrayEmpty(context.getters.userCommunication) &&
        !c.arrayEmpty(context.getters.userCommunication.web) &&
        context.getters.userCommunication.web.sound
      ) {
        this.dispatch("playSound", { name: icon + "Alert" });
        speak(data.message);
      }

      Swal.fire({
        html: '<span class="fs-6 fw-semibold">' + data.message + "</span>",
        width: "225px",
        position: c.dependencyMissing(data.position)
          ? "top-end"
          : data.position,
        showConfirmButton: false,
        timerProgressBar: true,
        icon: icon,
        timer: !c.isPositiveNum(data.timer) ? 3000 : data.timer,
        buttonsStyling: false,
        toast: true,
        customClass: !c.arrayEmpty(customClass) ? customClass : undefined,
      });
    },
    alertPrompt(context, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.message) ||
        c.dependencyMissing(data.type)
      )
        return;

      let icon, buttonText, buttonColor;

      if (typeof data.message === "object") data.message = data.message.message;

      switch (c.toLower(data.type)) {
        case "error":
          icon = "error";
          buttonText = "Ok";
          buttonColor = "danger";
          break;
        case "confirm":
          icon = "question";
          buttonText = "Confirm";
          buttonColor = "primary";
          break;
        case "info":
          icon = "info";
          buttonColor = "inflo";
          break;
        case "warning":
          icon = "warning";
          buttonColor = "warning";
          break;
        default:
          return;
      }

      if (
        !c.arrayEmpty(context.getters.userCommunication) &&
        !c.arrayEmpty(context.getters.userCommunication.web) &&
        context.getters.userCommunication.web.sound
      ) {
        this.dispatch("playSound", { name: icon + "Alert" });
        speak(data.message);
      }

      Swal.fire({
        text: data.message,
        icon: icon,
        buttonsStyling: false,
        timer: !c.isPositiveNum(data.timer) ? 3000 : data.timer,
        confirmButtonText: buttonText,
        customClass: {
          confirmButton: "btn fw-bold btn-light-" + buttonColor,
        },
      });
    },
    closeDrawer(context, data) {
      if (!data || !data.key) return;

      context.commit("setObjectAttribute", {
        key: "drawers",
        attribute: data.key,
        value: undefined,
      });
    },
    closeAccountManagement(context) {
      return new Promise((resolve) => {
        context.dispatch("setTenantByAuth", {
          object: context.getters.profile,
        });

        resolve(true);
      });
    },
    setServiceData(context, data) {
      if (!data || !data.service) return;

      context.commit("setData", {
        service: data.service,
        results: !c.arrayEmpty(data.results) ? data.results : {},
      });
    },
    pushObject(context, data) {
      if (!data || !data.key) return;

      context.commit("pushObject", {
        key: data.key,
        value: data.value,
      });
    },
    removeArrayObject(context, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.service) ||
        c.dependencyMissing(data._id)
      )
        return;

      context.commit("removeArrayObject", {
        key: data.service,
        _id: data._id,
      });
    },
    findData(context, data) {
      return new Promise((resolve, reject) => {
        if (c.arrayEmpty(data) || c.dependencyMissing(data.service))
          reject({ message: c.errorMessages.dependencyMissing });

        context.commit("setState", {
          type: "Find",
          service: data.service,
          value: true,
        });

        const limit = data.limit || defaults.limit;
        const skip = data.skip || 0;
        const sort = !c.arrayEmpty(data.sort) ? data.sort : {};

        const query = {
          $limit: limit,
          $skip: skip,
          $sort: sort,
          $timezone: c.moment().tz.guess(),
        };

        let cache = false;

        if (
          data.search &&
          (typeof data.search === "string" || typeof data.search === "number")
        )
          query["$search"] = data.search.trim();

        if (data.populate) query["$populate"] = data.populate;

        let accessToken;
        if (
          !c.dependencyMissing(
            (accessToken = this.getters.impersonationAccessToken(data))
          )
        )
          query.accessToken = accessToken;

        if (!c.arrayEmpty(data.filter))
          for (const k in data.filter) query[k] = data.filter[k];

        if (
          !data.noCache &&
          !data.noSetData &&
          useDataCache &&
          !c.arrayEmpty(context.getters[data.service]) &&
          !c.arrayEmpty(context.getters[data.service].results) &&
          !c.arrayEmpty(context.getters[data.service].results.data)
        ) {
          cache = true;
          resolve(context.getters[data.service].results);
        }

        this.feathersClient
          .service(data.service)
          .find({
            query: query,
          })
          .then((results) => {
            if (!results) results = {};

            if (!results.data) results.data = [];

            const cleanData = [];

            if (!c.arrayEmpty(results.data) && data.reverseData)
              results.data = results.data.reverse();

            for (const i in results.data) {
              if (!c.isFunction(data.formatter)) {
                cleanData.push(results.data[i]);
              } else {
                const formattedData = data.formatter(results.data[i]);

                if (!c.arrayEmpty(formattedData)) cleanData.push(formattedData);
              }
            }

            results.data = cleanData;

            if (!data.noSetData) {
              context.commit("setData", {
                service: data.storeObject || data.service,
                results: results ? results : {},
                mergeType: data.mergeType,
                sortData: data.sortData,
              });

              if (data.realtime === true) {
                context.dispatch("listen", {
                  service: data.service,
                  on: "created",
                  callback: async function (event) {
                    if (typeof data.formatter === "function")
                      event = await data.formatter(event);

                    if (c.arrayEmpty(event)) return;

                    if (
                      c.isFunction(data.isRealtime) &&
                      data.isRealtime(event) !== true
                    )
                      return;

                    if (c.isFunction(data.onCreated)) data.onCreated(event);

                    context.commit("pushObject", {
                      key: data.storeObject || data.service,
                      value: event,
                    });
                  },
                });

                context.dispatch("listen", {
                  service: data.service,
                  on: "removed",
                  callback: function (event) {
                    if (
                      c.isFunction(data.isRealtime) &&
                      data.isRealtime(event) !== true
                    )
                      return;

                    if (c.isFunction(data.onRemoved)) data.onRemoved(event);

                    context.commit("removeArrayObject", {
                      key: data.storeObject || data.service,
                      _id: event._id,
                    });
                  },
                });

                context.dispatch("listen", {
                  service: data.service,
                  on: "patched",
                  callback: function (event) {
                    if (typeof data.formatter === "function")
                      event = data.formatter(event);

                    if (c.arrayEmpty(event)) return;

                    if (
                      c.isFunction(data.isRealtime) &&
                      data.isRealtime(event) !== true
                    )
                      return;

                    if (c.isFunction(data.onPatched)) data.onPatched(event);

                    context.commit("patchArrayObject", {
                      key: data.storeObject || data.service,
                      value: event,
                    });
                  },
                });
              }
            }

            context.commit("setState", {
              type: "Find",
              service: data.service,
              value: false,
            });

            if (!cache) resolve(results);
            else if (c.isFunction(data.onDataUpdate))
              data.onDataUpdate(results);
          })
          .catch((e) => {
            context.commit("setState", {
              type: "Find",
              service: data.service,
              value: false,
            });

            reject(e);
          });
      });
    },
    patchServiceObjectAttribute(context, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.service) ||
        c.dependencyMissing(data.attribute) ||
        c.dependencyMissing(data._id)
      )
        return;

      context.commit("patchServiceObjectAttribute", {
        service: data.service,
        attribute: data.attribute,
        _id: data._id,
        value: data.value,
      });
    },
    findOne(context, data) {
      return new Promise((resolve, reject) => {
        if (c.arrayEmpty(data) || c.dependencyMissing(data.service))
          reject({ message: c.errorMessages.dependencyMissing });

        context.commit("setState", {
          type: "FindOne",
          service: data.service,
          value: true,
        });

        data.limit = 1;
        data.noSetData = 1;

        this.dispatch("findData", data)
          .then((response) => {
            context.commit("setState", {
              type: "FindOne",
              service: data.service,
              value: false,
            });

            resolve(response);
          })
          .catch((e) => {
            context.commit("setState", {
              type: "FindOne",
              service: data.service,
              value: false,
            });

            reject(e);
          });
      });
    },
    deleteObject(context, data) {
      return new Promise((resolve, reject) => {
        if (
          c.arrayEmpty(data) ||
          c.dependencyMissing(data.service) ||
          c.dependencyMissing(data.id)
        )
          reject({ message: c.errorMessages.dependencyMissing });

        context.commit("setState", {
          type: "Remove",
          service: data.service,
          value: true,
        });

        let accessToken;
        const params = { query: data.query || {} };

        if (
          !c.dependencyMissing(
            (accessToken = this.getters.impersonationAccessToken(data))
          )
        )
          params.query.accessToken = accessToken;

        this.feathersClient
          .service(data.service)
          .remove(data.id, params)
          .then((response) => {
            context.commit("setState", {
              type: "Remove",
              service: data.service,
              value: false,
            });

            resolve(response);
          })
          .catch((e) => {
            context.commit("setState", {
              type: "Remove",
              service: data.service,
              value: false,
            });

            reject(e);
          });
      });
    },
    deleteObjects(context, data) {
      return new Promise((resolve, reject) => {
        if (
          c.arrayEmpty(data) ||
          c.dependencyMissing(data.service) ||
          c.arrayEmpty(data.ids)
        )
          reject({ message: c.errorMessages.dependencyMissing });

        const functions = [];
        for (const i in data.ids)
          (function (id) {
            functions.push(function (callback) {
              context
                .dispatch("deleteObject", {
                  service: data.service,
                  id: id,
                })
                .then(() => {
                  callback(null);
                })
                .catch((e) => {
                  console.error("APIModule->deleteObjects", e);
                  callback(e);
                });
            });
          })(data.ids[i]);

        const done = (error, results) => {
          if (!c.arrayEmpty(results))
            for (let i = 0; i < results.length; i++)
              if (
                !c.arrayEmpty(results[i].error) &&
                !c.dependencyMissing(results[i].error.message)
              )
                return reject(results[i].error.message);

          if (!c.dependencyMissing(error) && typeof error == "string")
            return reject(error);

          resolve();
        };

        parallel(reflectAll(functions), function (error, results) {
          done(error, results);
        });
      });
    },
    deleteObjectAttribute(context, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.key) ||
        c.dependencyMissing(data.attribute)
      )
        return c.error(c.errorMessages.dependencyMissing);

      context.commit("deleteObjectAttribute", {
        key: data.key,
        attribute: data.attribute,
      });
    },
    getAttachments(context, data) {
      return new Promise((resolve, reject) => {
        if (
          c.arrayEmpty(data) ||
          c.dependencyMissing(data.service) ||
          c.dependencyMissing(data.id) ||
          c.dependencyMissing(data.fieldId)
        )
          reject({ message: c.errorMessages.dependencyMissing });

        context
          .dispatch("findData", {
            service: data.service,
            noSetData: true,
            filter: {
              object: data.id,
              fieldId: data.fieldId,
            },
            sort: {
              name: 1,
            },
          })
          .then((r) => {
            let attachments = [];

            if (!c.arrayEmpty(r) && !c.arrayEmpty(r.data)) attachments = r.data;

            resolve(attachments);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    resetCart(context, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing((data.type = c.toLower(data.type)))
      )
        return {};

      context.commit("resetCart", {
        type: data.type,
      });
    },
    setMenuConfig(context, data) {
      return new Promise((resolve, reject) => {
        if (
          c.arrayEmpty(data) ||
          c.arrayEmpty(context.getters.info) ||
          c.arrayEmpty(context.getters.info.site) ||
          c.arrayEmpty(context.getters.info.site.sitemenus)
        )
          return reject();

        const MenuConfig = {};

        for (const menu of context.getters.info.site.sitemenus) {
          const menuObjects = [];

          if (!c.arrayEmpty(menu.sitemenugroups))
            for (const group of menu.sitemenugroups)
              if (!c.arrayEmpty(group) && !c.arrayEmpty(group.sitemenupages)) {
                const menuObject = {
                  heading: group.name,
                  pages: [],
                };

                for (const sitemenupage of group.sitemenupages)
                  if (!c.arrayEmpty(sitemenupage)) {
                    if (
                      !sitemenupage.isMenu &&
                      !c.arrayEmpty(sitemenupage.page)
                    ) {
                      menuObject.pages.push({
                        heading: c.trimString(sitemenupage.page.name),
                        routeName: c.toLower(sitemenupage.page.routeName),
                        fontIcon: c.toLower(sitemenupage.page.icon),
                        permissions: sitemenupage.page.permissions,
                      });

                      if (!c.arrayEmpty(sitemenupage.page.permissions));
                      menuObject.permissions = c.mergeArrays(
                        menuObject.permissions,
                        sitemenupage.page.permissions
                      );
                    } else if (
                      sitemenupage.isMenu &&
                      !c.arrayEmpty(sitemenupage.pages)
                    ) {
                      const menuObjectMenu = {
                        sectionTitle: sitemenupage.name,
                        fontIcon: sitemenupage.icon,
                        sub: [],
                        permissions: [],
                      };

                      for (const page of sitemenupage.pages) {
                        if (
                          !c.arrayEmpty(page) &&
                          !c.dependencyMissing(page.name) &&
                          !c.dependencyMissing(page.routeName)
                        ) {
                          menuObjectMenu.sub.push({
                            heading: c.trimString(page.name),
                            routeName: c.toLower(page.routeName),
                            fontIcon: c.toLower(page.icon),
                            permissions: page.permissions,
                          });

                          if (!c.arrayEmpty(page.permissions));
                          menuObjectMenu.permissions = c.mergeArrays(
                            menuObjectMenu.permissions,
                            page.permissions
                          );
                        }
                      }

                      menuObject.pages.push(menuObjectMenu);
                    }
                  }

                menuObjects.push(menuObject);
              }

          MenuConfig[c.toLower(menu.name)] = menuObjects;
        }

        const config = { ...MenuConfig };

        for (const item in config)
          for (const subItem in config[item]) {
            config[item][subItem].permitted = permitted(
              data.permissions,
              config[item][subItem].permissions
            );

            if (!c.arrayEmpty(MenuConfig[item][subItem].pages))
              for (const pageItem in MenuConfig[item][subItem].pages) {
                MenuConfig[item][subItem].pages[pageItem].permitted = permitted(
                  data.permissions,
                  MenuConfig[item][subItem].pages[pageItem].permissions
                );

                if (
                  !c.arrayEmpty(MenuConfig[item][subItem].pages[pageItem].sub)
                )
                  for (const subPageItem in MenuConfig[item][subItem].pages[
                    pageItem
                  ].sub)
                    MenuConfig[item][subItem].pages[pageItem].sub[
                      subPageItem
                    ].permitted = permitted(
                      data.permissions,
                      MenuConfig[item][subItem].pages[pageItem].sub[subPageItem]
                        .permissions
                    );
              }
          }

        context.commit("setObject", {
          key: "menuConfig",
          object: MenuConfig,
        });

        resolve();
      });
    },
    getObject(context, data) {
      return new Promise((resolve, reject) => {
        if (
          c.arrayEmpty(data) ||
          c.dependencyMissing(data.service) ||
          c.dependencyMissing(data.id)
        )
          reject({ message: c.errorMessages.dependencyMissing });

        context.commit("setState", {
          type: "Get",
          service: data.service,
          value: true,
        });

        const query = data.query || {};

        query.$timezone = c.moment().tz.guess();

        if (!c.dependencyMissing(data.populate) || !c.arrayEmpty(data.populate))
          query["$populate"] = data.populate;

        let accessToken;
        if (
          !c.dependencyMissing(
            (accessToken = this.getters.impersonationAccessToken(data))
          )
        )
          query.accessToken = accessToken;

        this.feathersClient
          .service(data.service)
          .get(data.id, { query: query })
          .then((results) => {
            if (c.isFunction(data.formatter)) results = data.formatter(results);

            if (!data.noSetData)
              context.commit("setObject", {
                key: !data.objectName ? data.service : data.objectName,
                object: results ? results : {},
              });

            context.commit("setState", {
              type: "Get",
              service: data.service,
              value: false,
            });

            resolve(results);
          })
          .catch((e) => {
            context.commit("setState", {
              type: "Get",
              service: data.service,
              value: false,
            });

            reject(e);
          });
      });
    },
    listen(context, data) {
      return new Promise((resolve, reject) => {
        if (
          c.arrayEmpty(data) ||
          c.dependencyMissing(data.service) ||
          c.dependencyMissing(data.on) ||
          !c.isFunction(data.callback)
        )
          return reject({ message: c.errorMessages.dependencyMissing });

        if (
          !this.feathersClient.service(data.service).listeners(data.on).length
        )
          this.feathersClient.service(data.service).on(data.on, (event) => {
            data.callback(event);
          });

        resolve();
      });
    },
    removeListener(context, data) {
      return new Promise((resolve, reject) => {
        if (c.arrayEmpty(data) || c.dependencyMissing(data.service))
          return reject({ message: c.errorMessages.dependencyMissing });

        if (this.feathersClient.service(data.service).listeners(data.on).length)
          this.feathersClient.service(data.service).removeAllListeners(data.on);

        resolve();
      });
    },
    removeAllListeners(context, data) {
      return new Promise((resolve, reject) => {
        if (c.arrayEmpty(data) || c.dependencyMissing(data.service))
          return reject({ message: c.errorMessages.dependencyMissing });

        if (
          this.feathersClient.service(data.service).listeners("created").length
        )
          this.feathersClient
            .service(data.service)
            .removeAllListeners("created");

        if (
          this.feathersClient.service(data.service).listeners("removed").length
        )
          this.feathersClient
            .service(data.service)
            .removeAllListeners("removed");

        if (
          this.feathersClient.service(data.service).listeners("patched").length
        )
          this.feathersClient
            .service(data.service)
            .removeAllListeners("patched");

        resolve();
      });
    },
    setSiteInfo(context) {
      return new Promise((resolve, reject) => {
        context
          .dispatch("findData", {
            service: "info",
            noSetData: 1,
          })
          .then((r) => {
            if (!c.arrayEmpty(r)) {
              delete r.data;
              context.dispatch("setInfo", {
                object: r,
              });

              resolve();
            }
          })
          .catch((e) => {
            c.showError(
              "Unable to get site information.  Please try reloading page."
            );
            reject(e.message);
          });
      });
    },
    playSound(context, data) {
      if (c.arrayEmpty(data) || c.dependencyMissing(data.name)) return;

      try {
        if (
          context.getters.sounds[c.trimString(data.name)] instanceof
          HTMLAudioElement
        )
          context.getters.sounds[c.trimString(data.name)].play();
      } catch (e) {
        console.error("Failed playing sound", e);
      }
    },
    processPostlogin(context) {
      try {
        if (!c.arrayEmpty(sounds))
          for (const i in sounds)
            if (!c.dependencyMissing(i) && !c.dependencyMissing(sounds[i]))
              context.commit("setObjectAttribute", {
                key: "sounds",
                attribute: i,
                value: new Audio(soundPath + c.trimString(sounds[i])),
              });

        const setVoice = (voices) => {
          for (const i in voices)
            if (
              (voices[i].localService &&
                !c.dependencyMissing(context.getters.currentUser.webVoice) &&
                c.toLower(context.getters.currentUser.webVoice) ===
                  c.toLower(voices[i].name)) ||
              (c.dependencyMissing(context.getters.currentUser.webVoice) &&
                voices[i].name === "Microsoft Zira - English (United States)")
            ) {
              context.commit("setValue", {
                key: "speakVoice",
                value: voices[i],
              });
              break;
            }
        };

        if (window.speechSynthesis) {
          const voices = window.speechSynthesis.getVoices();

          if (!c.arrayEmpty(voices)) setVoice(voices);
          else
            window.speechSynthesis.addEventListener("voiceschanged", () => {
              setVoice(window.speechSynthesis.getVoices());
            });
        }

        context.dispatch("listen", {
          service: "pubsub",
          on: "created",
          callback: onActivity,
        });
      } catch (e) {
        console.error("Failed loading sounds", e);
      }
    },
    logout(context) {
      return new Promise((resolve) => {
        const resetUser = () => {
          if (
            !c.arrayEmpty(context.getters.phone) &&
            !c.arrayEmpty(context.getters.phone.client)
          ) {
            if (c.isFunction(context.getters.phone.client.shutdown))
              context.getters.phone.client.shutdown();

            if (c.isFunction(context.getters.phone.client.onBeforeEvent))
              window.removeEventListener(
                "beforeunload",
                context.getters.phone.client.onBeforeEvent
              );
          }

          stopPresence();

          patchPresence("offline");

          const objects = ["user", "profile", "currentChatRoom"];
          const services = ["chatrooms", "conversations", "callqueues"];

          for (const i in objects)
            context.commit("setState", {
              key: objects[i],
              object: {},
            });

          for (const i in services)
            context.dispatch("resetServiceData", {
              service: services[i],
            });

          context.dispatch("removeTenant");

          resolve();
        };

        this.feathersClient.authentication
          .removeAccessToken()
          .then(() => {
            resetUser();
          })
          .catch((e) => {
            resetUser(e);
          });
      });
    },
    openDrawer(context, data) {
      if (!data || !data.key) return;

      const open = () => {
        context.commit("setObjectAttribute", {
          key: "drawers",
          attribute: data.key,
          //value: data.value || data.key,
          value: data.value || true,
        });
      };

      if (context.getters.drawers[data.key]) {
        context.dispatch("closeDrawer", {
          key: data.key,
        });
        setTimeout(function () {
          open();
        }, 50);
      } else open();
    },
    patchData(context, data) {
      return new Promise((resolve, reject) => {
        if (
          c.arrayEmpty(data) ||
          c.arrayEmpty(data.data) ||
          c.dependencyMissing(data.service) ||
          (c.dependencyMissing(data.id) && data.service !== "me")
        )
          reject({ message: c.errorMessages.dependencyMissing });

        context.commit("setState", {
          type: "Patch",
          service: data.service,
          value: true,
        });

        let accessToken;

        const params = { query: { $timezone: c.moment().tz.guess() } };

        if (
          !c.dependencyMissing(
            (accessToken = this.getters.impersonationAccessToken(data))
          )
        )
          params.query.accessToken = accessToken;

        this.feathersClient
          .service(data.service)
          .patch(data.id, data.data, params)
          .then((response) => {
            context.commit("setState", {
              type: "Patch",
              service: data.service,
              value: false,
            });

            resolve(response);
          })
          .catch((e) => {
            context.commit("setState", {
              type: "Patch",
              service: data.service,
              value: false,
            });

            reject(e);
          });
      });
    },
    postObject(context, data) {
      return new Promise((resolve, reject) => {
        if (
          c.arrayEmpty(data) ||
          c.dependencyMissing(data.service) ||
          c.arrayEmpty(data.object)
        )
          reject({ message: c.errorMessages.dependencyMissing });

        context.commit("setState", {
          type: "Post",
          service: data.service,
          value: true,
        });

        let accessToken;
        const params = { query: { $timezone: c.moment().tz.guess() } };

        if (
          !c.dependencyMissing(
            (accessToken = this.getters.impersonationAccessToken(data))
          )
        )
          params.query.accessToken = accessToken;

        this.feathersClient
          .service(data.service)
          .create(data.object, params)
          .then((response) => {
            context.commit("setState", {
              type: "Post",
              service: data.service,
              value: false,
            });

            resolve(response);
          })
          .catch((e) => {
            context.commit("setState", {
              type: "Post",
              service: data.service,
              value: false,
            });

            reject(e);
          });
      });
    },
    upload(context, data) {
      return new Promise((resolve, reject) => {
        if (c.arrayEmpty(data) || c.arrayEmpty(data.object))
          reject({ message: c.errorMessages.dependencyMissing });

        const complete = (done = false) => {
          context.commit("setState", {
            type: "Post",
            service: "files",
            value: !done,
          });
        };

        complete(false);

        const formData = new FormData();

        formData.append("name", data.object.name + "." + data.object.extension);
        formData.append("fieldId", "file");
        formData.append("service", data.object.service);
        formData.append("type", data.object.type);
        formData.append("file", data.object.buffer, data.object.name);

        const options = {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
            Authorization: this.getters.accessToken,
          },
        };

        let accessToken;

        if (
          !c.dependencyMissing(
            (accessToken = this.getters.impersonationAccessToken(data))
          )
        )
          options.headers.Authorization.accessToken = accessToken;

        fetch(apiUrl + "/files", options)
          .then((r) => {
            if (!r.ok) throw new Error(`HTTP error: ${r.status}`);

            return r.json();
          })
          .then((r) => {
            complete(true);
            resolve(r);
          })
          .catch((e) => {
            complete(true);
            reject(e);
          });
      });
    },
    setGlobal(context, data) {
      if (!data.key || !data.value)
        throw new Error("Failed setting vuex globals");

      this[data.key] = data.value;
    },
    setObject(context, data) {
      if (!data || !data.key) return;

      context.commit("setObject", {
        key: data.key,
        object: data.object,
      });
    },
    setStateValue(context, data) {
      if (!data || !data.key) return;

      context.commit("setStateValue", {
        key: data.key,
        value: data.value,
      });
    },
    pushValue(context, data) {
      if (!data || !data.key) return;

      context.commit("pushValue", {
        key: data.key,
        value: data.value,
      });
    },
    setValue(context, data) {
      if (!data || !data.key) return;

      context.commit("setValue", {
        key: data.key,
        value: data.value,
      });
    },
    setCartAttribute(context, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.attribute) ||
        c.dependencyMissing(data._id)
      )
        return;

      context.commit("setCartAttribute", {
        _id: data._id,
        value: data.value,
        attribute: data.attribute,
      });
    },
    addCartGeneral(context, data) {
      if (c.arrayEmpty(data)) return;

      context.commit("addCartGeneral", data);
    },
    removeCartGeneral(context, data) {
      if (c.arrayEmpty(data) || c.dependencyMissing(data._id)) return;

      context.commit("removeCartGeneral", data);
    },
    setCartNumbers(context, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing((data.service = c.toLower(data.service)))
      )
        return;

      context.commit("setCartNumbers", {
        service: data.service,
        _ids: data._ids,
      });
    },
    setInfo(context, data) {
      if (c.arrayEmpty(data) || c.arrayEmpty(data.object)) return;

      context.commit("setObject", {
        key: "info",
        object: data.object,
      });

      localStorage.setItem("info", JSON.stringify(data.object));
    },
    setTenantByAuth(context, data) {
      if (c.arrayEmpty(data) || c.arrayEmpty(data.object)) return;

      context.commit("setObject", {
        key: "tenant",
        object: data.object,
      });

      localStorage.setItem("tenant", JSON.stringify(data.object));

      context.dispatch("setMenuConfig", {
        permissions: data.object.user.permissions,
      });
    },
    setTenantCustomerAttribute(context, data) {
      if (c.arrayEmpty(data) || c.dependencyMissing(data.attribute)) return;

      context.commit("setTenantCustomerAttribute", {
        attribute: data.attribute,
        value: data.value,
      });

      context.dispatch("setTenantByAuth", {
        object: context.getters.tenant,
      });
    },
    removeTenant(context) {
      context.commit("setObject", {
        key: "tenant",
        object: {},
      });

      localStorage.removeItem("tenant");

      context.dispatch("setMenuConfig", { permissions: [] });
    },
    patchArrayObject(context, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.key) ||
        c.arrayEmpty(data.value)
      )
        return c.error(c.errorMessages.dependencyMissing);

      context.commit("patchArrayObject", {
        key: data.key,
        value: data.value,
      });
    },
    pushObjectAttribute(context, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.key) ||
        c.dependencyMissing(data.attribute)
      )
        return c.error(c.errorMessages.dependencyMissing);

      context.commit("pushObjectAttribute", {
        key: data.key,
        attribute: data.attribute,
        value: data.value,
      });
    },
    patchArrayObjectAttribute(context, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.array) ||
        c.dependencyMissing(data.key) ||
        c.dependencyMissing(data._id) ||
        c.dependencyMissing(data.attribute)
      )
        return c.error(c.errorMessages.dependencyMissing);

      context.commit("patchArrayObjectAttribute", {
        array: data.array,
        key: data.key,
        _id: data._id,
        attribute: data.attribute,
        value: data.value,
      });
    },
    spliceObjectAttribute(context, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.key) ||
        c.dependencyMissing(data.attribute)
      )
        return c.error(c.errorMessages.dependencyMissing);

      context.commit("spliceObjectAttribute", {
        key: data.key,
        attribute: data.attribute,
        start: data.start,
        count: data.count,
        add: data.add,
      });
    },
    startPresenceWatcher(context) {
      if (context.getters.presenceWatcher)
        clearInterval(context.getters.presenceWatcher);

      const presenceWatcher = setInterval(() => {
        if (!c.arrayEmpty(context.getters.presenceData))
          for (const i in context.getters.presenceData)
            if (
              !c.arrayEmpty(context.getters.presenceData[i]) &&
              c.isValidObjectId(
                context.getters.presenceData[i].createdByUserId
              ) &&
              c.trimString(context.getters.presenceData[i].createdByUserId) !==
                c.trimString(context.getters.userId)
            )
              context.commit("patchArrayObject", {
                key: "presence",
                value: context.getters.presenceData[i],
              });
      }, 60000);

      context.commit("setValue", {
        key: "presenceWatcher",
        value: presenceWatcher,
      });
    },
    stopPresenceWatcher(context) {
      if (context.getters.presenceWatcher)
        clearInterval(context.getters.presenceWatcher);
    },
    stopPresenceIdleWatcher(context) {
      if (context.getters.presenceIdleWatcher)
        clearInterval(context.getters.presenceIdleWatcher);
    },
    setObjectAttribute(context, data) {
      if (
        c.arrayEmpty(data) ||
        c.dependencyMissing(data.key) ||
        c.dependencyMissing(data.attribute)
      )
        return c.error(c.errorMessages.dependencyMissing);

      context.commit("setObjectAttribute", {
        key: data.key,
        attribute: data.attribute,
        value: data.value,
      });
    },
    verifyAuth(context, next) {
      return new Promise((resolve) => {
        const accessToken = window.localStorage.getItem("feathers-jwt");
        const location = c.cloneObject(window.location);

        if (!accessToken)
          next({
            replace: true,
            name: "sign-in",
            /* query: !c.dependencyMissing(location.search)
              ? c.parseQueryString(location.search)
              : undefined,*/
            hash: !c.dependencyMissing(location.hash)
              ? location.hash
              : undefined,
          });
        else next();

        resolve();
      });
    },
    resetServiceData(context, data) {
      if (!data || !data.service) return;

      context.dispatch("setServiceData", {
        service: data.service,
      });
    },
    setCartPaymentMethod(context, data) {
      if (c.arrayEmpty(data) || c.dependencyMissing(data.name)) return;

      // reset and timeout required to allow time to reload renderer
      this.dispatch("setObjectAttribute", {
        key: data.name,
        attribute: "paymentMethod",
        value: {},
      });

      if (
        c.arrayEmpty(data) ||
        c.arrayEmpty(context.getters.paymentmethodData) ||
        !c.isValidObjectId(data._id)
      )
        return;

      for (const i in this.getters.paymentmethodData)
        if (this.getters.paymentmethodData[i]._id === data._id) {
          setTimeout(function () {
            context.dispatch("setObjectAttribute", {
              key: data.name,
              attribute: "paymentMethod",
              value: context.getters.paymentmethodData[i],
            });
          }, 50);
          break;
        }
    },
    updateButton(context, data) {
      if (!data || !data.button) return;

      if (data.type === "enable") {
        data.button.removeAttribute("data-kt-indicator");
        data.button.disabled = false;
      } else {
        data.button.setAttribute("data-kt-indicator", "on");
        data.button.disabled = true;
      }
    },
  },
};

import c from "@circuitid/common";
import * as Yup from "yup";

export const name = Yup.string().trim().required().min(1).max(45).label("Name");
export const value = Yup.string()
  .trim()
  .required()
  .min(1)
  .max(255)
  .label("Value");
export const description = Yup.string().trim().max(45).label("Description");
export const direction = Yup.string().trim().required().label("Direcetion");
export const extension = Yup.number()
  .required()
  .min(1)
  .max(99999999999)
  .label("Extension");
export const host = Yup.string().trim().required().min(4).max(45).label("Host");
export const mac = Yup.string().trim().required().min(5).max(45).label("Mac");
export const brandName = Yup.string()
  .trim()
  .required()
  .min(1)
  .max(45)
  .label("Brand Name");
export const manufacture = Yup.string().trim().required().label("Manufacture");
export const param = Yup.string().trim().required().label("Parameter");
export const accountType = Yup.string().required().label("Account Type");
export const channel = Yup.string().required().label("Channel");
export const numberType = Yup.string().required().label("Number Type");
export const identity = Yup.string().required().label("Identity");
export const phoneinboundrule = Yup.string()
  .trim()
  .required()
  .label("Inbound Rule");
export const port = Yup.number()
  .integer()
  .positive()
  .required()
  .min(c.port.min)
  .max(c.port.max)
  .label("Port");
export const status = Yup.string().trim().required().label("Status");
export const type = Yup.string().trim().required().label("Type");
export const user = Yup.string().trim().required().label("User");
export const users = Yup.array().required().label("Users");
export const destinationType = Yup.string()
  .trim()
  .required()
  .label("Destination Type");
export const destination = Yup.string().trim().required().label("Destination");
export const date = Yup.string().trim().required().label("Date");
export const dateTimeRange = Yup.array().required().label("Date Range");
export const dateTimeRanges = Yup.array().required().label("Date Range");
export const digit = Yup.number()
  .required()
  .min(0)
  .max(9999999999)
  .label("Digit");
export const accountNumber = Yup.number().required().label("Account Number");
export const expirationMonth = Yup.number()
  .required()
  .min(1)
  .max(12)
  .label("Expiration Month");
export const expirationYear = Yup.number()
  .required()
  .min(new Date().getFullYear())
  .max(new Date().getFullYear() + 10)
  .label("Expiration Year");
export const code = Yup.string()
  .required()
  .min(5)
  .max(6)
  .matches(/^\d+$/, "Code must be a 5 or 6-digit number")
  .label("Code");
export const csc = Yup.number().required().min(1).max(9999).label("CVV");
export const commitmentInterval = Yup.number()
  .required()
  .min(1)
  .max(365)
  .label("Commitment Interval");
export const commitmentIntervalType = Yup.string()
  .trim()
  .required()
  .label("Commitment Interval Type");

export const secret = Yup.string().trim().required().label("Secret");

export const accessToken = Yup.string().trim().required().label("Access Token");

export const billInterval = Yup.number()
  .required()
  .min(1)
  .max(365)
  .label("Bill Interval");
export const billIntervalType = Yup.string()
  .trim()
  .required()
  .label("Bill Interval Type");
export const amount = Yup.number().label("Amount");
export const refundAmount = Yup.number().label("Refund Amount");
export const strategy = Yup.string().trim().required().label("Strategy");
export const groups = Yup.array().required().label("Groups");
export const voice = Yup.string().trim().required().label("Voice");
export const text = Yup.string().trim().required().label("Text");
export const key = Yup.string().trim().required().label("Key");
export const country = Yup.string().trim().required().label("Country");
export const domain = Yup.string()
  .trim()
  .required()
  .label("Domain")
  .matches(
    /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
    "Must be a valid domain name."
  );
export const houseNumber = Yup.string().trim().required().label("House Number");
export const streetName = Yup.string().trim().required().label("Street Name");
export const state = Yup.string().trim().required().label("State");
export const city = Yup.string().trim().required().label("City");
export const streetSuffix = Yup.string()
  .trim()
  .required()
  .label("Street Suffix");
export const zip = Yup.string().trim().required().label("Zip");
export const callerId = Yup.array().required().label("Caller ID");
export const attachments = Yup.array().required().label("Attachments");
export const callForwardingDestination = Yup.number()
  .required()
  .label("Call Forwarding Destination");
export const ein = Yup.string().trim().min(7).max(20).required().label("EIN");
export const entityType = Yup.string().trim().required().label("Entity Type");
export const useCase = Yup.string().trim().required().label("Use Case");
export const vertical = Yup.string().trim().required().label("Vertical");
export const first = Yup.string().trim().required().label("First Name");
export const last = Yup.string().trim().required().label("Last Name");
export const companyName = Yup.string().trim().required().label("Company Name");
export const mobilePhone = Yup.string()
  .trim()
  .min(11)
  .max(20)
  .required()
  .label("Mobile Phone");
export const email = Yup.string().trim().email().required().label("Email");
export const conversationText = Yup.string().trim().max(1600).label("Message");
export const numbers = Yup.string().trim().max(2000).label("Numbers");
export const typeOfService = Yup.string().trim().label("Type of Service");
export const authorizedPerson = Yup.string().trim().label("Authorized Person");
export const phoneNumber = Yup.string()
  .trim()
  .min(11)
  .max(20)
  .required()
  .label("Phone Number");
export const number = Yup.string().trim().required().label("Phone Number");
export const services = Yup.array().required().label("Services");
export const events = Yup.array().required().label("Events");
export const integrationType = Yup.string()
  .trim()
  .required()
  .label("Integration Type");
export const webhookUrl = Yup.string().url().trim().label("Webhook URL");
export const visibility = Yup.string().trim().required().label("Visibility");
export const timezone = Yup.string().trim().required().label("Timezone");
export const object = Yup.string().trim().required().label("Resource");
export const order = Yup.string().trim().required().label("Order");
export const product = Yup.string().required().label("Product");
export const content = Yup.string().required().label("Product");
export const passcode = Yup.string()
  .min(6)
  .max(20)
  .required()
  .label("Passcode");
export const dialInPin = Yup.number()
  .required()
  .test(
    "len",
    "Can only contain numbers and must be exactly 10 digits long",
    (val) => val && val.toString().length === 10 && /^\d+$/.test(val)
  )
  .label("Dial-in Pin");
export const streetNumber = Yup.string()
  .trim()
  .required()
  .label("Street Number");

export const agreeToTerms = Yup.boolean()
  .required()
  .oneOf([true], "Please agree to the terms and conditions.")
  .label("Terms of Service");

/*eslint no-useless-escape: "off"*/
export const password = Yup.string()
  .trim()
  .required()
  .label("Password")
  .matches(
    /(?=[A-Za-z0-9@#$%^~*&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^~*&+!=])(?=.{8,}).*$/,
    "Must contain atleast 8 characters, one uppercase, one lowercase, one number and one special character (@&$~#%^*!).  Cannot contain other special any other special character."
  );
export const passwordConfirmation = Yup.string()
  .trim()
  .required()
  .label("Password Confirmation")
  .oneOf([Yup.ref("password"), null], "Passwords must match");

export function validation(fields) {
  if (c.arrayEmpty(fields)) return {};

  const validators = [];
  fields.forEach((field) => {
    if (c.dependencyMissing((field = c.trimString(field)))) return;

    switch (c.toLower(field)) {
      case "accesstoken":
        validators[field] = accessToken;
        break;
      case "accounttype":
        validators[field] = accountType;
        break;
      case "agreetoterms":
        validators[field] = agreeToTerms;
        break;
      case "authorizedperson":
        validators[field] = authorizedPerson;
        break;
      case "attachments":
        validators[field] = attachments;
        break;
      case "amount":
        validators[field] = amount;
        break;
      case "accountnumber":
        validators[field] = accountNumber;
        break;
      case "brandname":
        validators[field] = brandName;
        break;
      case "csc":
        validators[field] = csc;
        break;
      case "callerid":
        validators[field] = callerId;
        break;
      case "callforwardingdestination":
        validators[field] = callForwardingDestination;
        break;
      case "channel":
        validators[field] = channel;
        break;
      case "code":
        validators[field] = code;
        break;
      case "content":
        validators[field] = content;
        break;
      case "destination":
        validators[field] = destination;
        break;
      case "digit":
        validators[field] = digit;
        break;
      case "date":
        validators[field] = dateTimeRange;
        break;
      case "domain":
        validators[field] = domain;
        break;
      case "datetimerange":
        validators[field] = dateTimeRange;
        break;
      case "datetimeranges":
        validators[field] = dateTimeRanges;
        break;
      case "destinationtype":
        validators[field] = destinationType;
        break;
      case "description":
        validators[field] = description;
        break;
      case "dialinpin":
        validators[field] = dialInPin;
        break;
      case "direction":
        validators[field] = direction;
        break;
      case "ein":
        validators[field] = ein;
        break;
      case "email":
        validators[field] = email;
        break;
      case "entitytype":
        validators[field] = entityType;
        break;
      case "events":
        validators[field] = events;
        break;
      case "first":
        validators[field] = first;
        break;
      case "last":
        validators[field] = last;
        break;
      case "companyname":
        validators[field] = companyName;
        break;
      case "mobilephone":
        validators[field] = mobilePhone;
        break;
      case "expirationmonth":
        validators[field] = expirationMonth;
        break;
      case "expirationyear":
        validators[field] = expirationYear;
        break;
      case "extension":
        validators[field] = extension;
        break;
      case "groups":
        validators[field] = groups;
        break;
      case "host":
        validators[field] = host;
        break;
      case "identity":
        validators[field] = identity;
        break;
      case "commitmentinterval":
        validators[field] = commitmentInterval;
        break;
      case "commitmentintervaltype":
        validators[field] = commitmentIntervalType;
        break;
      case "billInterval":
        validators[field] = billInterval;
        break;
      case "billintervaltype":
        validators[field] = billIntervalType;
        break;
      case "integrationtype":
        validators[field] = integrationType;
        break;
      case "key":
        validators[field] = key;
        break;
      case "mac":
        validators[field] = mac;
        break;
      case "manufacture":
        validators[field] = manufacture;
        break;
      case "name":
        validators[field] = name;
        break;
      case "number":
        validators[field] = number;
        break;
      case "numbertype":
        validators[field] = numberType;
        break;
      case "numbers":
        validators[field] = numbers;
        break;
      case "phoneinboundrule":
        validators[field] = phoneinboundrule;
        break;
      case "param":
        validators[field] = param;
        break;
      case "password":
        validators[field] = password;
        break;
      case "passwordconfirmation":
        validators[field] = passwordConfirmation;
        break;
      case "product":
        validators[field] = product;
        break;
      case "callerdestination":
      case "accountphonenumber":
      case "phonenumber":
        validators[field] = phoneNumber;
        break;
      case "passcode":
        validators[field] = passcode;
        break;
      case "port":
        validators[field] = port;
        break;
      case "status":
        validators[field] = status;
        break;
      case "strategy":
        validators[field] = strategy;
        break;
      case "type":
        validators[field] = type;
        break;
      case "user":
        validators[field] = user;
        break;
      case "users":
        validators[field] = users;
        break;
      case "conversationtext":
        validators[field] = conversationText;
        break;
      case "streetnumber":
        validators[field] = streetNumber;
        break;
      case "text":
        validators[field] = text;
        break;
      case "voice":
        validators[field] = voice;
        break;
      case "country":
        validators[field] = country;
        break;
      case "housenumber":
        validators[field] = houseNumber;
        break;
      case "secret":
        validators[field] = secret;
        break;
      case "services":
        validators[field] = services;
        break;
      case "streetname":
        validators[field] = streetName;
        break;
      case "streetsuffix":
        validators[field] = streetSuffix;
        break;
      case "state":
        validators[field] = state;
        break;
      case "object":
        validators[field] = object;
        break;
      case "order":
        validators[field] = order;
        break;
      case "typeofservice":
        validators[field] = typeOfService;
        break;
      case "city":
        validators[field] = city;
        break;
      case "vertical":
        validators[field] = vertical;
        break;
      case "timezone":
        validators[field] = timezone;
        break;
      case "usecase":
        validators[field] = useCase;
        break;
      case "webhookurl":
        validators[field] = webhookUrl;
        break;
      case "value":
        validators[field] = value;
        break;
      case "visibility":
        validators[field] = visibility;
        break;
      case "zip":
      case "zipcode":
        validators[field] = zip;
        break;
    }
  });

  return Yup.object().shape(validators);
}

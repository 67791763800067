<template>
  <div
    class="d-flex align-items-center"
    id="kt_header_user_menu_toggle"
    v-if="
      !$common.arrayEmpty($store.getters.profile) &&
      !$common.arrayEmpty($store.getters.profile.user)
    "
  >
    <div
      class="cursor-pointer symbol symbol-30px"
      data-kt-menu-trigger="click"
      data-kt-menu-overflow="true"
      data-kt-menu-placement="top-start"
      data-kt-menu-flip="top-end"
      data-bs-toggle="tooltip"
      data-bs-placement="right"
      data-bs-dismiss="click"
      :title="$store.getters.profile.user.displayName + ' avatar'"
    >
      <img
        :src="$store.getters.profile.user.avatar"
        :alt="$store.getters.profile.user.displayName + ' avatar'"
        v-if="$store.getters.profile.user.avatar"
      />
      <Avatar
        :name="$store.getters.profile.user.displayName"
        :size="35"
        v-else
      />
      <span
        :class="[
          'symbol-badge start-100 top-100 border-4 h-10px w-10px mt-n1 bg-' +
            $store.getters.presenceColor,
          { 'ms-n2': !$store.getters.profile.user.avatar },
        ]"
      />
    </div>
    <KTUserMenu></KTUserMenu>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import Avatar from "@/components/avatar/Avatar.vue";

export default defineComponent({
  name: "ProfileMenu",
  components: {
    Avatar,
    KTUserMenu,
  },
});
</script>

import { plugins } from "@/core/helpers/config";
import c from "@circuitid/common";

export default {
  install(app, options) {
    try {
      const loadPlugins = async () => {
        for (let i = 0; i < plugins.length; i++)
          if (
            !c.arrayEmpty(plugins[i]) &&
            plugins[i].enabled &&
            !c.dependencyMissing(
              (plugins[i].import = c.toLower(plugins[i].import))
            )
          ) {
            const module = await import(
              "../../../node_modules/@circuitid/" + plugins[i].import
            );

            app.use(
              module.default,
              c.mergeObjects({ plugin: plugins[i] }, options)
            );
          }
      };

      loadPlugins();
    } catch (e) {
      throw new Error("Failed loading plugins: " + e.message);
    }
  },
};

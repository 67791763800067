import c from "@circuitid/common";
import store from "@/store";
import { router, route } from "@/core/helpers/functions.js";

export const faxAccountFormatter = (d: any) => {
  if (c.arrayEmpty(d)) return d;

  d.link = "/faxaccounts/" + d._id + "/all";
  d.show = true;
  d.items = [
    {
      name: d.name,
      _id: d._id,
      badge: "secondary",
    },
  ];

  d.rightContent = "";

  return d;
};

export const loadFirstAccount = (force = false) => {
  if (!force && !route.name.includes("fax-accounts-")) return;

  if (c.arrayEmpty(store.getters.faxaccountData)) {
    router.push({ path: "/faxaccounts/welcome/dashboard" });
    return;
  }

  const id = router.currentRoute.value.params.id;
  const index = c.inArray("_id", id, store.getters.faxaccountData);

  if (
    c.isValidObjectId(id) &&
    typeof index === "number" &&
    !c.arrayEmpty(store.getters.faxaccountData[index])
  )
    return;

  for (const i in store.getters.faxaccountData)
    if (
      !c.arrayEmpty(store.getters.faxaccountData[i]) &&
      c.isValidObjectId(store.getters.faxaccountData[i]._id)
    ) {
      router.push({
        path: "/faxaccounts/" + store.getters.faxaccountData[i]._id + "/all",
      });
      return;
    }
};

// required for feathers
import io from "socket.io-client";
import { feathers } from "@feathersjs/client";
import { apiUrl } from "@/core/helpers/config";
import { pinia } from "../pinia";
import { createPiniaClient } from "feathers-pinia";
import c from "@circuitid/common";
import { messageFormatter as chatMessageFormatter } from "@/components/chat/chatRoomFunctions.js";
import { messageFormatter as conversationMessageFormatter } from "@/components/conversations/conversationFunctions.js";
import { checkMessage } from "@/core/helpers/functions.js";

const socket = io(apiUrl, {
  transports: ["websocket"],
  forceNew: true,
});

const feathersClient = feathers();

feathersClient.configure(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  feathers.socketio(socket, {
    timeout: 90000,
  }),
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
feathersClient.configure(feathers.authentication());

export { pinia, feathersClient };

export const api = createPiniaClient(feathersClient, {
  pinia: pinia,
  ssr: true,
  storage: window.localStorage,
  // below are configurable per service in the `services` object.
  idField: "_id",
  syncWithStorage: true,
  whitelist: [
    "$search",
    "$limit",
    "$skip",
    "$sort",
    "$select",
    "$or",
    "$and",
    "$populate", // populate causes results not to be returned from local catch,however, works from api
  ],
  // paramsForServer: ["$customServerParam"],
  skipGetIfExists: true,
  handleEvents: undefined, // HandleEvents<AnyData>
  debounceEventsTime: 20,
  debounceEventsGuarantee: false,
  customSiftOperators: {}, // see sift docs
  services: {
    chatmessages: {
      setupInstance: (d) => {
        return chatMessageFormatter(d);
      },
      handleEvents: {
        created: (d) => {
          if (c.arrayEmpty(d) || !c.isValidObjectId(d.chatroom)) return;

          checkMessage({
            createdByUserId: d.senderId,
            _id: d.chatroom,
            service: "chatrooms",
          });

          d.commit();

          return d;
        },
        patched: (d) => {
          if (c.arrayEmpty(d) || !c.isValidObjectId(d.chatroom)) return;

          d.commit();

          return d;
        },
        removed: (d) => {
          if (c.arrayEmpty(d) || !c.isValidObjectId(d.chatroom)) return;

          d.commit();

          return d;
        },
      },
    },
    conversationmessages: {
      setupInstance: (d) => {
        return conversationMessageFormatter(d);
      },
      handleEvents: {
        created: (d) => {
          if (c.arrayEmpty(d) || !c.isValidObjectId(d.conversation)) return;

          checkMessage({
            createdByUserId: d.senderId,
            _id: d._conversation,
            service: "conversations",
          });

          d.commit();

          return d;
        },
        patched: (d) => {
          if (c.arrayEmpty(d) || !c.isValidObjectId(d.conversation)) return;

          d.commit();

          return d;
        },
        removed: (d) => {
          if (c.arrayEmpty(d) || !c.isValidObjectId(d.conversation)) return;

          d.commit();

          return d;
        },
      },
    },
    recent: {},
  },
});

export function useFeathers() {
  return { api };
}

export function useService(service) {
  if (c.dependencyMissing(service))
    throw Error(
      "useService: " + c.errorMessages.dependencyMissing + " service",
    );

  return api.service(service);
}
